import React, { CSSProperties } from 'react';
import useDateFormatter from '../helpers/useDateFormatter.tsx';
import { ViewField } from '../_form/FormElements.tsx';

interface FmViewDateFieldProps {
  title?: string;
  value: string;

  style?: CSSProperties;

  color?: string;
}
const FmViewDateField = (props: FmViewDateFieldProps) => {
  const { title, value, style, color } = props;

  const formateDate = useDateFormatter();

  const dateFieldStyles: CSSProperties = {
    color: color || 'black',
    ...style,
  };

  return (
    <span style={dateFieldStyles}>
      {<ViewField label={title} title={String(formateDate(value))} />}
    </span>
  );
};

export default FmViewDateField;
