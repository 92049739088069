import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  salesQuotation: null,
  loading: false,
  error: null,
};

const salesQuotationSlice = createSlice({
  name: 'salesQuotation',
  initialState,
  reducers: {
    updateQuotationStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateQuotationSuccess(state, action) {
      state.loading = false;
      state.salesQuotation = action.payload;
    },
    updateQuotationFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updateQuotationStart,
  updateQuotationSuccess,
  updateQuotationFailure,
} = salesQuotationSlice.actions;

export default salesQuotationSlice.reducer;
