import React from 'react';
import { Box, alpha } from '@mui/material';

const MRTExpandTableStyle = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        '& .MuiTableCell-root, & .MuiFormLabel-root, & .MuiInputBase-root, & .MuiTypography-root':
          {
            fontSize: '11px',
          },

        '& .MuiInputBase-root.MuiFilledInput-root': {
          background: (theme) => `${theme.palette.background.white} !important`,
          borderColor: (theme) => alpha(theme.palette.text.main, 0.3),
        },

        '& .MuiTableCell-head': {
          bgcolor: 'background.light',
        },
        '& .MuiTablePagination-root': {
          padding: '6px',
        },
        '& .MuiTableCell-root': {
          padding: '2px 5px',
        },
        '& .MuiCollapse-horizontal': {
          '& .MuiInputBase-root': {
            padding: '0px 10px',
            '& .MuiOutlinedInput-input': {
              padding: '8px 4px',
              minWidth: '200px',
            },
          },
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
        },
        '& .MuiTableCell-root.MuiTableCell-head': {
          fontWeight: '600',
        },

        '& .MuiTableCell-root .MuiIconButton-root': {
          padding: '6px',
          fontSize: '1rem',
        },
        '& .MuiTableContainer-root + .MuiBox-root': {
          minHeight: '3rem',
        },
        '& .MuiPaper-elevation': {
          boxShadow: 'inherit',
          border: 'none',
          borderRadius: '0px',
        },
        '& .MuiPaper-root>.MuiBox-root:first-child': {
          minHeight: '49px',
          borderBottom: '1px solid',
          borderColor: 'border.main',
          '& .MuiIconButton-root': {
            padding: '4px',
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'border.main',
          },
        },
        '& .MuiTableCell-alignLeft .MuiBox-root': {
          gap: 0,
        },
        '& .MuiTableBody-root .MuiTableRow-root:hover td:after': {
          bgcolor: 'background.light',
        },

        '& .MuiPaper-root>.MuiBox-root:last-child': {
          '& span + .MuiBox-root': {
            right: '50%',
            transform: 'translateX(50%)',
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default MRTExpandTableStyle;
