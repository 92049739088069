import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import Crud_Service from '../../../apis/CrudService.tsx';
import { toast } from 'react-toastify';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import CommonView from '../../../components/helpers/CommonView.tsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import Nav from '../../../utils/Nav.tsx';
import dayjs from 'dayjs';

const Asateel = ({ locationId }) => {
  const crud = new Crud_Service();
  const { id } = useParams();
  const [rowState, setRowState] = useState({
    newRowId: null,
    isCreatingRow: false,
  });
  const { setValue } = useForm();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [viewdetails, setViewdetails] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);

  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setSearchKeyword,
    searchKeyword,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
  } = useTableLogic('vehicleasateels');

  const columns = [
    { header: 'Vehicle Asateel Code', accessorKey: 'vehicleAsateelCode' },
    { header: 'Vehicle Name', accessorKey: 'vehicleName' },
    { header: 'Vehicle Category Name', accessorKey: 'vehicleCategoryName' },
    { header: 'Vehicle Brand Name', accessorKey: 'vehicleBrandName' },
    { header: 'Vehicle Plate Type Name', accessorKey: 'vehiclePlateTypeName' },
    { header: 'Vehicle Plate No', accessorKey: 'vehiclePlateNo' },
    { header: 'Vehicle Type Name', accessorKey: 'vehicleTypeName' },
    { header: 'Type Name', accessorKey: 'typeName' },
    { header: 'Department Name', accessorKey: 'departmentName' },
    { header: 'Concern Person', accessorKey: 'concernPerson' },
    { header: 'Date of Installation', accessorKey: 'dateOfInstallation' },
    { header: 'Date of Expiry', accessorKey: 'dateOfExpiry' },
  ];

  return (
    <>
      <DashboardLayout
        title='Month Update'
        hasSubmenu
        menu={Nav[10].child[7].children}
      >
        <GlassCard>
          <FmMRTDataTable
            columns={columns}
            enableRowSelection={true}
            rows={rows}
            isCreatingRow={rowState?.isCreatingRow}
            rowCount={tableRecordCounts}
            editingRow={editingRowId}
            pagination={pagination}
            setSearchKeyword={setSearchKeyword}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            setPagination={setPagination}
            setSorting={setSorting}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default Asateel;
