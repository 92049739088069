import React from 'react';
import { Box, Avatar, alpha } from '@mui/material';

const TableAvatar = ({ name, img, desc }) => {
  const initial = name ? name.charAt(0).toUpperCase() : '';

  return (
    <Box className='flex gap-2 flex-row items-center'>
      <Avatar
        alt={name}
        src={img}
        sx={{
          width: 22,
          height: 22,
          fontSize: 11,
          textTransform: 'uppercase',
          bgcolor: 'secondary.main',
          color: 'text.white',
          fontWeight: 400,
        }}
      >
        {initial}
      </Avatar>
      <Box>
        <p style={{ fontWeight: 500 }}>{name}</p>
        <p className='opacity-70' style={{ fontSize: '10px' }}>
          {desc}
        </p>
      </Box>
    </Box>
  );
};

export default TableAvatar;
