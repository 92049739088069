import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../apis/AuthService.ts';
import { fetchMenu } from './menuSlice.ts';
import { RootState } from '../store/store';
export const loginRequest = createAsyncThunk(
  'auth/login',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await AuthService.login(payload);
      if (response) {
        const { token, currentUser } = response;
        await dispatch(fetchMenu());
        return { token, user: currentUser };
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const impersonateRequest = createAsyncThunk(
  'auth/impersonateRequest',
  async ({ values, authState, navigate }: any, { rejectWithValue }) => {
    try {
      const response = await AuthService.impersonate(values, authState);
      if (response) {
        navigate('/dashboard');
        return response;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const userLogout = createAsyncThunk(
  'auth/logout',
  async (
    { authState, navigate }: { authState: RootState['auth']; navigate: any },
    { rejectWithValue }
  ) => {
    try {
      await AuthService.logout(authState);
      navigate('/');
      return { token: null, user: null };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
const loginSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(loginRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(impersonateRequest.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.loading = false;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.token = null;
        state.user = null;
        state.loading = false;
      });
  },
});

export const selectAuth = (state: RootState) => state.auth;
export default loginSlice.reducer;
