import dayjs from 'dayjs';
import * as Yup from 'yup';

export const supplierValidationSchema = Yup.object().shape({
  officialSupplierName: Yup.string().required(
    'Official Supplier Name is required'
  ),
  address: Yup.string().required('Address is required'),
  headOfficeAddress: Yup.string().required('Head Office Address is required'),
  taxNumber: Yup.string().required('Tax Number is required'),
  taxPercentage: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Tax Percentage is required')
    .min(0, 'Tax Percentage cannot be less than 0')
    .max(100, 'Tax Percentage cannot exceed 100'),

  tradeLicenseNumber: Yup.string().required('Trade License Number is required'),
  creditLimit: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Credit Limit is required')
    .min(0, 'Credit Limit cannot be negative'),
  supplierTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier Type is required')
    .positive('Supplier Type must be a positive number')
    .integer('Supplier Type must be an integer'),

  regionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Region is required')
    .positive('Region must be a positive number')
    .integer('Region must be an integer'),

  countryId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Country is required')
    .positive('Country must be a positive number')
    .integer('Country must be an integer'),
});

export const wpsSchema = Yup.object().shape({
  basicSalaryPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Basic Salary Percentage is required')
    .min(0, 'Basic Salary Percentage cannot be less than 0')
    .max(100, 'Basic Salary Percentage cannot exceed 100'),
  housingAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Housing Allowance Percentage is required')
    .min(0, 'Housing Allowance Percentage cannot be less than 0')
    .max(100, 'Housing Allowance Percentage cannot exceed 100'),
  additionalAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Additional Allowance Percentage is required')
    .min(0, 'Additional Allowance Percentage cannot be less than 0')
    .max(100, 'Additional Allowance Percentage cannot exceed 100'),
  incentivePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Incentives Percentage is required')
    .min(0, 'Incentives Percentage cannot be less than 0')
    .max(100, 'Incentives Percentage cannot exceed 100'),
  otherAllowancePercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Other Allowance Percentage is required')
    .min(0, 'Other Allowance Percentage cannot be less than 0')
    .max(100, 'Other Allowance Percentage cannot exceed 100'),
});

export const supplierItemSchema = Yup.object().shape({
  supplierId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier is required')
    .positive('Supplier must be a positive number')
    .integer('Supplier must be an integer'),

  itemId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item is required')
    .positive('Item must be a positive number')
    .integer('Item must be an integer'),

  description: Yup.string().required('Description is required'),
  notes: Yup.string().nullable(),

  sku: Yup.string().required('SKU is required'),

  unitPrice: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Unit Price is required')
    .min(0, 'Unit Price cannot be negative'),

  quantity: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Quantity is required')
    .positive('Quantity must be greater than 0')
    .integer('Quantity must be a whole number'),

  taxCodeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tax Code is required')
    .positive('Tax Code must be a positive number')
    .integer('Tax Code must be an integer'),

  discountPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Discount Percent is required')
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),

  totalPrice: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Total Price is required')
    .min(0, 'Total Price cannot be negative'),
});

export const EstimationMEPSchema = Yup.object().shape({
  projectId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Project is required')
    .positive('Project must be a positive number')
    .integer('Project must be an integer'),

  clientId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Client is required')
    .positive('Client must be a positive number')
    .integer('Client must be an integer'),

  tenderId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tender is required')
    .positive('Tender must be a positive number')
    .integer('Tender must be an integer'),

  buildingId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Building is required')
    .positive('Building must be a positive number')
    .integer('Building must be an integer'),

  dateOfEnquiry: Yup.date().required('Date of Enquiry is required'),
  contractPeriod: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract Period is required')
    .positive('Contract Period must be a positive number')
    .integer('Contract Period must be an integer'),

  siteVisitDoneBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Site Visit Done By is required')
    .positive('Site Visit Done By must be a positive number')
    .integer('Site Visit Done By must be an integer'),

  existContract: Yup.string().required('Exist Contract is required'),

  preparedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Prepared By is required')
    .positive('Prepared By must be a positive number')
    .integer('Prepared By must be an integer'),

  discountPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
  otherPercent: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Other Percent is required')
    .min(0, 'Other Percent cannot be negative')
    .max(100, 'Other Percent cannot exceed 100'),
});
export const leaveRequestSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  leaveTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Leave Type is required'),
  fromDate: Yup.date().required('From Date is required'),
  toDate: Yup.date().required('To Date is required'),
  reason: Yup.string().required('Reason is required'),
  leaveRequestStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Leave Request Status is required'),
  leaveDay: Yup.number().required('Leave Day is required'),
  noOfDays: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('No of Days is required')
    .min(1),
  remarks: Yup.string().required('Remarks is required'),
  lastAnnualReturnLeaveDate: Yup.date().required('`'),
  currentDate: Yup.date().required('Current Date is required'),
  sponsership: Yup.string().required('Sponsership is required'),
  totalLeave: Yup.number().required('Total Leave is required'),
  usedLeave: Yup.number().required('Used Leave is required'),
  balanceLeave: Yup.number().required('Balance Leave is required'),
  dateOfTravel: Yup.date().required('Date of Travel is required'),
  homeCountryContactNumber: Yup.string()
    .required('Home Country Contact Number is required')
    .min(8, 'Home Country Contact Number must be at least 8 characters')
    .max(12, 'Home Country Contact Number must be at least 12 characters'),
  uaeNumber: Yup.string().required('UAE Contact Number is required'),
});

export const leaveJoinSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  currentDate: Yup.date().required('Current Date is required'),
  dateOfArrival: Yup.date().required('Date of Arrival is required'),
  dateOfResume: Yup.date().required('Date of Resume is required'),
  additionalDays: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Additional Days is required')
    .positive('Additional Days must be a positive number'),
  delayDays: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Delay Days is required')
    .min(1)
    .positive('Delay Days must be a positive number'),
  remarks: Yup.string().required('Remarks is required'),
});

export const passportRequestSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  appliedOn: Yup.date().required('Applied On is required'),
  passportRequestTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Passport Request Type is required'),
  issuedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Issued By is required'),

  remarks: Yup.string().required('Remarks is required'),
});

export const employeeCancellationSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  cancellationTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cancellation Type is required'),
  cancellationDate: Yup.date().required('Cancellation Date is required'),
  reason: Yup.string().required('Reason is required'),
  lastDate: Yup.date().required('Last Date is required'),
  departmentClearanceDocumentPath: Yup.string().required(
    'Document Path is required'
  ),
  contractBreach: Yup.string().required('Contract Breach is required'),
  exitday: Yup.date().required('Exit Day is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const certificateSchema = Yup.object().shape({
  employeeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Employee is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  certificationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Certification is required'),
  certificateTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Certificate Type is required'),
  professionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Profession is required'),
  projectId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Project is required'),
  contractId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
  startDate: Yup.date().required('Start Date is required'),
  expiryDate: Yup.date().required('Expiry Date is required'),
  lastRenewDate: Yup.date().required('Last Renew Date is required'),
  passCancelledDate: Yup.date().required('Pass Cancelled Date is required'),
  remarks: Yup.string().required('Remarks is required'),
  loaInformation: Yup.string().required('LOA Information is required'),
  cicpaNumber: Yup.string().required('CICPA Number is required'),
});

export const jobCertificationSchema = Yup.object().shape({
  requisitionTitle: Yup.string().required('Requisition Title is required'),
  requisitionDescription: Yup.string().required(
    'Requisition Description is required'
  ),
  jobRequisitionManualCode: Yup.string().nullable(),
  requisitionReason: Yup.string().nullable(),

  createJobRequisitionDetails: Yup.array()
    .of(
      Yup.object().shape({
        experienceLevel: Yup.string()
          .required('Experience Level is required'),
        closedDate: Yup.date().required('Closed Date is required'),
        noOfPosition: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('No of Position is required'),
        fromBudget: Yup.number().transform((value, originalValue) =>
          originalValue === '' ? null : value
        ),
        toBudget: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('To Budget is required'),
        requestedBy: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Requested By is required'),
        skill: Yup.string().required('Skill is required'),
        jobRequisitionStatusId: Yup.number()
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .required('Job Requisition Status is required'),
      })
    )
    .required('Job Requisition Details is required'),
});

export const eventsSchema = Yup.object().shape({
  eventName: Yup.string().required('Event Name is required'),
  eventTopic: Yup.string().required('Event Topic is required'),
  eventDescription: Yup.string().required('Event Description is required'),
  eventDate: Yup.date().required('Event Date is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  organizedBy: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Organized By is required'),
  attendeessCount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Attendees Count is required'),
  eventStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Event Status is required'),
  approvalStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Approval Status is required'),
});

export const grnSchema = Yup.object().shape({
  //TODO
  // materialRequisitionId: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Material Requisition is required'),
  // localPurchaseOrderId: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Local Purchase Order is required'),
  supplierId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier is required'),
  deliveryChallanNumber: Yup.string().required(
    'Delivery Challan Number is required'
  ),
  deliveryDate: Yup.date().required('Delivery Date is required'),
  grnDate: Yup.date().required('GRN Date is required'),
  fileId: Yup.number().transform((value, originalValue) =>
    originalValue === '' ? null : value
  ),
  goodsReceivedNoteType: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Goods Received Note Type is required'),
  goodsReceivedNoteStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Goods Received Note Status is required'),
  paymentStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Payment Status is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const purchaseFormSchema = Yup.object().shape({
  manualLPOCode: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Manual LPO Code must be alphanumeric')
    .required('Manual LPO Code is required'),
  lpoDate: Yup.date().required('LPO Date is required'),
  vendorAccount: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Vendor Account must be alphanumeric')
    .required('Vendor Account is required'),
  currencyId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Currency is required'),
  exchangeRate: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Exchange Rate is required'),
  companyId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Company is required'),
  projectId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Project is required'),
  contractId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
  divisionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Division is required'),
  costCenterId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cost Center is required'),
  jurisdiction: Yup.string().required('Jurisdiction is required'),
  inCharge: Yup.string().required('In Charge is required'),
  requestor: Yup.string().required('Requestor is required'),
  deliveryLocation: Yup.string().required('Delivery Location is required'),
  supplierSPOCId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Supplier SPOC is required'),
  // materialRequisitionId: Yup.number()
  //   .transform((value, originalValue) => (originalValue === '' ? null : value))
  //   .required('Material Requisition is required'),

  deliveryTerms: Yup.string().required('Delivery Terms is required'),

  paymentTermId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Payment Term is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const itemsSchema = Yup.object().shape({
  itemShortCode: Yup.string().required('Item Short Code is required'),
  itemName: Yup.string().required('Item Name is required'),
  description: Yup.string().required('Description is required'),
  technicalSpecifications: Yup.string().required(
    'Technical Specifications is required'
  ),
  model: Yup.string().required('Model is required'),
  unitOfMeasurementId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Unit of Measurement is required'),
  taxCodeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Tax Code is required'),
  itemTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item Type is required'),
  costCenterId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Cost Center is required'),
  valuationMethodId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Valuation Method is required'),
  itemGroupId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item Group is required'),
  brandId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Brand is required'),

  groupTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Group Type is required'),
});

export const CompanyInsurancePolicySchema = Yup.object().shape({
  companyInsurancePolicyId: Yup.number()
    .positive('Company Insurance Policy must be a positive number')
    .required('Company Insurance Policy is required'),
  companyInsurancePolicyEndUserId: Yup.number()
    .positive('End User must be a positive number')
    .required('End User is required'),
  companyInsurancePolicyTypeOfProductId: Yup.number()
    .positive('Type of Product must be a positive number')
    .required('Type of Product is required'),
  companyInsurancePolicyTypeId: Yup.number()
    .positive('Policy Type must be a positive number')
    .required('Policy Type is required'),
  companyInsurancePolicyInsurerId: Yup.number()
    .positive('Insurer must be a positive number')
    .required('Insurer is required'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must be at least 5 characters long'),
  policyDetail: Yup.string().required('Policy Detail is required'),
  insuredSum: Yup.number()
    .typeError('Insured Sum must be a number')
    .positive('Insured Sum must be a positive number')
    .required('Insured Sum is required'),
  remarks: Yup.string().required('Remarks is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  dateOfPriorNotification: Yup.date().required(
    'Date of Prior Notification is required'
  ),
});

export const CompanyInsurancePolicyComparisonSchema = Yup.object().shape({
  companyInsurancePolicyId: Yup.number()
    .positive('Company Insurance Policy must be a positive number')
    .required('Company Insurance Policy is required'),
  companyInsurancePolicyEndUserId: Yup.number()
    .positive('End User must be a positive number')
    .required('End User is required'),
  companyInsurancePolicyTypeOfProductId: Yup.number()
    .positive('Type of Product must be a positive number')
    .required('Type of Product is required'),
  companyInsurancePolicyTypeId: Yup.number()
    .positive('Policy Type must be a positive number')
    .required('Policy Type is required'),
  companyInsurancePolicyInsurerId: Yup.number()
    .positive('Insurer must be a positive number')
    .required('Insurer is required'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must be at least 5 characters long'),
  policyDetail: Yup.string().required('Policy Detail is required'),
  insuredSum: Yup.number()
    .typeError('Insured Sum must be a number')
    .positive('Insured Sum must be a positive number')
    .required('Insured Sum is required'),
  remarks: Yup.string().required('Remarks is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  dateOfPriorNotification: Yup.date().required(
    'Date of Prior Notification is required'
  ),
});
export const MIPPremiunCalculatorSchema = Yup.object().shape({
  companyMedicalInsuranceAgeBandId: Yup.number()
    .positive('Age Band must be a positive number')
    .required('Age Band is required'),

  companyMedicalInsuranceDependencyId: Yup.number()
    .positive('Dependency must be a positive number')
    .required('Dependency is required'),

  medicalInsurancePolicyCategories: Yup.array().of(
    Yup.object().shape({
      gradeId: Yup.number()
        .positive('Grade must be a positive number')
        .required('Grade is required'),
    })
  ),
});

export const ProjectMainCostSchema = Yup.object().shape({
  projectDivisionId: Yup.number()
    .positive('Project Division must be a positive number')
    .required('Project Division is required'),
  projectTypeId: Yup.number()
    .positive('Project Type must be a positive number')
    .required('Project Type is required'),
  billId: Yup.number()
    .positive('BOQ must be a positive number')
    .required('BOQ is required'),
  headId: Yup.number()
    .positive('Package must be a positive number')
    .required('Package is required'),
  subHeadId: Yup.number()
    .positive('SubHead must be a positive number')
    .required('SubHead is required'),
  lineItemId: Yup.number()
    .positive('Line Item must be a positive number')
    .required('Line Item is required'),
  requestedDate: Yup.date()
    .nullable()
    .transform((value) => (value === null ? undefined : value))
    .typeError('Requested Date must be a valid date')
    .required('Requested Date is required'),
  originalCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Original Cost must be at least 0')
    .required('Original Cost is required'),
  budgetCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget Cost must be at least 0')
    .required('Budget Cost Amount is required'),
  budgetCOD: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget COD must be at least 0')
    .required('Budget COD is required'),
  budgetCODPercent: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Budget COD Percent must be at least 0')
    .max(100, 'Budget COD Percent cannot be more than 100')
    .required('Budget COD Percent is required'),
  actualCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Actual Cost must be at least 0')
    .required('Actual Cost is required'),
});

export const AccommodationSchema = Yup.object().shape({
  locationId: Yup.number()
    .min(1, 'Location must be greater than 0')
    .required('Location is required'),
  premiseTypeId: Yup.number()
    .min(1, 'Type must be greater than 0')
    .required('Type is required'),
  premisePurposeId: Yup.number()
    .min(1, 'Purpose must be greater than 0')
    .required('Purpose is required'),
  duration: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Duration must be greater than 0')
    .required('Duration is required'),
  noOfRoom: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Number of rooms must be greater than 0')
    .required('Number of rooms is required'),
  noOfBedSpace: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Number of bed spaces must be greater than 0')
    .required('Number of bed spaces is required'),
  startDate: Yup.date().required('Start date is required').nullable(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'End date cannot be before start date')
    .required('End date is required')
    .nullable(),
  beneficiaryDetails: Yup.string().required('Beneficiary details are required'),
  contactInformation: Yup.string().required('Contact information is required'),
  contractNo: Yup.string().required('Contract number is required'),
  size: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Size must be greater than 0')
    .required('Size is required'),
  securityDeposit: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Security deposit cannot be negative')
    .required('Security deposit is required'),
  rentalCharges: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Rental charges cannot be negative')
    .required('Rental charges are required'),
  laundryCharges: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Laundry charges cannot be negative')
    .required('Laundry charges are required'),
  chargesMonth: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'Charges month must be greater than 0')
    .required('Charges month is required'),
  negotiation: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation must be greater than or equal to 0')
    .required('Negotiation is required'),
  remarks: Yup.string().nullable(),
});

export const BudgetCostTrackingValidationSchema = Yup.object().shape({
  billId: Yup.number()
    .positive('BOQ must be a positive number')
    .required('BOQ is required'),
  headId: Yup.number()
    .positive('Package must be a positive number')
    .required('Package is required'),
  subHeadId: Yup.number()
    .positive('SubHead must be a positive number')
    .required('SubHead is required'),
  lineItemId: Yup.number()
    .positive('Line Item must be a positive number')
    .required('Line Item is required'),
  materialAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Material Amount must be at least 0')
    .required('Material Amount is required'),
  manPowerAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Manpower Amount must be at least 0')
    .required('Manpower Amount is required'),
  machineryAmount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Machinery Amount must be at least 0')
    .required('Machinery Amount is required'),
});

export const ManpowerValidationSchema = Yup.object().shape({
  billId: Yup.number()
    .positive('BOQ must be a positive number')
    .required('BOQ is required'),
  headId: Yup.number()
    .positive('Package must be a positive number')
    .required('Package is required'),
  subHeadId: Yup.number()
    .positive('SubHead must be a positive number')
    .required('SubHead is required'),
  lineItemId: Yup.number()
    .positive('Line Item must be a positive number')
    .required('Line Item is required'),
  description: Yup.string().required('Description is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const PettyCashValidationSchema = Yup.object().shape({
  pettyCashCategoryId: Yup.number()
    .required('Petty Cash Category is required')
    .min(1, 'Petty Cash Category must be greater than 0'),

  expenseTypeId: Yup.number()
    .required('Expense Type is required')
    .min(1, 'Expense Type must be greater than 0'),

  refNumber: Yup.string().required('Reference Number is required'),
  pettyCashNumber: Yup.string().required('Petty Cash Number is required'),

  amount: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Amount is required')
    .min(1, 'Amount must be greater than 0'),
  bankId: Yup.number().required('Bank is required'),
  companyId: Yup.number().required('Company is required'),
  branchId: Yup.number().required('Branch is required'),
  remarks: Yup.string().required('Remarks are required'),
});

export const InjuriesValidationSchema = Yup.object().shape({
  employeeId: Yup.number()
    .required('Employee is required')
    .min(1, 'Employee must be greater than 0'),
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),
  positionId: Yup.number()
    .required('Position is required')
    .min(1, 'Position must be greater than 0'),
  description: Yup.string().required('description are required'),
});

export const CheckoutItemsValidationSchema = Yup.object().shape({
  checkInLocationId: Yup.number()
    .required('Check In Location is required')
    .min(1, 'Check In Location must be greater than 0'),
  description: Yup.string().required('description is required'),
  checkInRemarks: Yup.string().required('Check In Remarks are required'),
  checkOutRemarks: Yup.string().required('Check Out Remarks are required'),
  estimationCost: Yup.number()
    .typeError('This field should allow numbers only')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('EstimationCost is required')
    .min(1, 'EstimationCost must be greater than 0'),
});

export const CreateCheckOutSchemaSchema = Yup.object().shape({
  inChargeId: Yup.number()
    .min(1, 'InCharge must be greater than 0')
    .required('InCharge is required'),
  contractId: Yup.number()
    .min(1, 'Contract must be greater than 0')
    .required('Contract is required'),
  companyId: Yup.number()
    .min(1, 'Company must be greater than 0')
    .required('Company is required'),
  projectId: Yup.number()
    .min(1, 'Project must be greater than 0')
    .required('Project is required'),
  clientId: Yup.number()
    .min(1, 'Client must be greater than 0')
    .required('Client is required'),
  workOrderId: Yup.number()
    .min(1, 'Work Order must be greater than 0')
    .required('Work Order is required'),
});

export const materialRequestItemSchema = Yup.object().shape({
  //TODO:
  // materialRequisitionId: Yup.number().required(
  //   'Material Requisition is required'
  // ),
  quantity: Yup.number()
    .typeError('This field should allow numbers only')
    .required('Quantity is required')
    .positive('Quantity must be greater than 0'),
});

export const addprojectactivitiesSchema = Yup.object().shape({
  costCenterId: Yup.number()
    .required('Cost Center is required')
    .positive('Cost Center must be a positive number')
    .integer('Cost Center must be an integer'),

  activityDate: Yup.date().required('Activity Date is required').nullable(),

  billId: Yup.number()
    .required('BOQ is required')
    .positive('BOQ must be a positive number')
    .integer('BOQ must be an integer'),

  headId: Yup.number()
    .required('Package is required')
    .positive('Package must be a positive number')
    .integer('Package must be an integer'),

  subHeadId: Yup.number()
    .required('Sub Head is required')
    .positive('Sub Head must be a positive number')
    .integer('Sub Head must be an integer'),

  lineItemId: Yup.number()
    .required('Line Item is required')
    .positive('Line Item must be a positive number')
    .integer('Line Item must be an integer'),

  qtyCompleted: Yup.number()
    .required('Quantity Completed is required')
    .positive('Quantity Completed must be a positive number')
    .integer('Quantity Completed must be an integer'),

  unitOfMeasurementId: Yup.number()
    .required('Unit Of Measurement is required')
    .positive('Unit Of Measurement must be a positive number')
    .integer('Unit Of Measurement must be an integer'),

  projectDivisionStatusId: Yup.number()
    .required('Project Division Status is required')
    .positive(
      'Project Division Status Project Division Status  must be a positive number'
    )
    .integer(
      'Project Division Status Project Division Status  must be an integer'
    ),
  assigneeIds: Yup.array()
    .of(
      Yup.number()
        .positive('Assignee must be a positive number')
        .integer('Assignee must be an integer')
    )
    .required('Assignee IDs are required')
    .min(1, 'At least one Assignee is required'),
});
export const editprojectactivitiesSchema = Yup.object().shape({
  costCenterId: Yup.number()
    .required('Cost Center is required')
    .positive('Cost Center must be a positive number')
    .integer('Cost Center must be an integer'),

  activityDate: Yup.date().required('Activity Date is required').nullable(),

  billId: Yup.number()
    .required('BOQ is required')
    .positive('BOQ must be a positive number')
    .integer('BOQ must be an integer'),

  headId: Yup.number()
    .required('Package is required')
    .positive('Package must be a positive number')
    .integer('Package must be an integer'),

  subHeadId: Yup.number()
    .required('Sub Head is required')
    .positive('Sub Head must be a positive number')
    .integer('Sub Head must be an integer'),

  lineItemId: Yup.number()
    .required('Line Item is required')
    .positive('Line Item must be a positive number')
    .integer('Line Item must be an integer'),

  qtyCompleted: Yup.number()
    .required('Quantity Completed is required')
    .positive('Quantity Completed must be a positive number')
    .integer('Quantity Completed must be an integer'),

  projectDivisionStatusId: Yup.number()
    .required('Project Division Status is required')
    .positive(
      'Project Division Status Project Division Status  must be a positive number'
    )
    .integer(
      'Project Division Status Project Division Status  must be an integer'
    ),

  unitOfMeasurmentId: Yup.number()
    .required('Unit of Measurement is required')
    .positive('Unit of Measurement  must be a positive number')
    .integer('Unit of Measurement  must be an integer'),
});

export const snagReportvalidationSchema = Yup.object().shape({
  snagReportDetailName: Yup.string().required(
    'Snag Report Detail Name is required'
  ),
  // FEW FIELDS ARE NOT MANDATORY IN API
  // equipment: Yup.string().required('Equipment is required'),
  description: Yup.string().required('Description is required'),
  // comments: Yup.string().nullable(), // Optional field
  // blockName: Yup.string().required('Block Name is required'),
  // floorName: Yup.string().required('Floor Name is required'),
  // divisionName: Yup.string().required('Division Name is required'),
  // condition: Yup.string().required('Condition is required'),
  // areaName: Yup.string().required('Area Name is required'),
  // priorityId: Yup.number()
  //   .required('Priority is required')
  //   .min(1, 'Priority must be greater than 0'), // Adjust as necessary
  snagReportId: Yup.number()
    .required('Snag Report is required')
    .min(1, 'Snag Report must be greater than 0'), // Adjust as necessary
  // unitId: Yup.number()
  //   .required('Unit is required')
  //   .min(1, 'Unit must be greater than 0'), // Adjust as necessary
  // subUnitId: Yup.number()
  //   .required('subUnit is required')
  //   .min(1, 'subUnit must be greater than 0'),
  // snagTakenBy: Yup.number()
  //   .required('Snag Taken By is required')
  //   .min(1, 'Snag Taken By must be greater than 0'), // Adjust as necessary
  // approvedBy: Yup.number()
  //   .required('ApprovedBy is required')
  //   .min(1, 'ApprovedBy must be greater than 0'),
  // price: Yup.number()
  //   .required('Price is required')
  //   .min(0, 'Price must be a positive number'), // Adjust as necessary
  // negotiatedPrice: Yup.number()
  //   .nullable() // Optional field
  //   .min(0, 'Negotiated Price must be a positive number'), // Adjust as necessary
});

export const addCheckInvalidationSchema = Yup.object().shape({
  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be selected'), // Ensures it's not 0
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be selected'),
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be selected'),
  employeeId: Yup.number()
    .required('Employee is required')
    .min(1, 'Employee must be selected'),
  workOrderId: Yup.number()
    .required('Work order is required')
    .min(1, 'Work order must be selected'),
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be selected'),
});

export const inspectionreportitems = Yup.object().shape({
  description: Yup.string()
    .required('Description is required')
    .min(3, 'Description must be at least 3 characters long'),
  verified: Yup.boolean()
    .required('Verified status is required')
    .oneOf([true, false], 'Verified must be a boolean value'),

  targetDateForRectification: Yup.date()
    .required('Target Date for Rectification is required')
    .typeError('Invalid date format'),
});

export const addprojectdivisionplan = Yup.object().shape({
  activityId: Yup.string()
    .required('Activity is required')
    .matches(
      /^[A-Za-z0-9_-]*$/,
      'Activity can only contain letters, numbers, underscores, and dashes'
    ),

  activityName: Yup.string()
    .required('Activity Name is required')
    .max(255, 'Activity Name cannot exceed 255 characters'),

  orgDuration: Yup.number()
    .typeError('Original Duration must be a number')
    .required('Original Duration is required')
    .min(1, 'Original Duration must be greater than 0'),

  startDate: Yup.date()
    .nullable()
    .required('Start Date is required') // required date, can be null
    .min(
      dayjs().subtract(1, 'day').toDate(),
      'Start Date cannot be in the past'
    ), // prevent past dates
  endDate: Yup.date()
    .nullable()
    .required('End Date is required') // required date, can be null
    .min(Yup.ref('startDate'), 'End Date must be after Start Date'),

  totalFloat: Yup.number()
    .typeError('Total Float must be a number')
    .required('Total Float is required')
    .min(0, 'Total Float must be 0 or more'),
});

export const TenantVehiclevalidationSchema = Yup.object().shape({
  vehicleBrandId: Yup.number()
    .required('Vehicle brand is required')
    .min(1, 'Vehicle brandID must be greater than 0'),

  vehicleColor: Yup.string().required('Vehicle color code is required'),

  registrationNumber: Yup.number()
    .typeError('Registration Number must be a number')
    .required('Registration Number is required')
    .min(0, 'Registration Number cannot be negative'),
});

export const ItemPropertyvalidationSchema = Yup.object().shape({
  propertyId: Yup.number()
    .required('Property is required')
    .min(1, 'Property must be greater than 0'),
  checkInLocationId: Yup.number()
    .required('Location is required')
    .min(1, 'Location must be greater than 0'),
});

export const mRSchema = Yup.object().shape({
  requestDate: Yup.date()
    .nullable()
    .transform((value) => (value === null ? undefined : value))
    .typeError('Request Date must be a valid date')
    .required('Request Date is required'),

  manualMRCode: Yup.string().required('Manual MR Code is required'),

  companyId: Yup.number()
    .typeError('Company must be a number')
    .required('Company is required'),

  projectId: Yup.number()
    .typeError('Project must be a number')
    .required('Project is required'),

  contractId: Yup.number()
    .typeError('Contract must be a number')
    .required('Contract is required'),

  divisionId: Yup.number()
    .typeError('Division must be a number')
    .required('Division is required'),

  inCharge: Yup.number()
    .typeError('In Charge must be a number')
    .required('In Charge is required'),

  requesterId: Yup.number()
    .typeError('Requester must be a number')
    .required('Requester is required'),

  locationId: Yup.number()
    .typeError('Location must be a number')
    .required('Location is required'),

  materialRequisitionTypeId: Yup.number()
    .typeError('Material Requisition Type must be a number')
    .required('Material Requisition Type is required'),

  regionId: Yup.number()
    .typeError('Region must be a number')
    .required('Region is required'),
  storeLocationId: Yup.number()
    .typeError('Store Location must be a number')
    .required('Store Location is required'),
  requestorEmailId: Yup.string()
    .email('Invalid email format')
    .required('Requestor Email is required'),

  supplierSPOCId: Yup.number()
    .typeError('Supplier SPOC must be a number')
    .required('Supplier SPOC is required'),

  requestedDeliveryDate: Yup.date()
    .nullable()
    .transform((value) => (value === null ? undefined : value))
    .typeError('Requested Delivery Date must be a valid date')
    .required('Requested Delivery Date is required'),

  unitId: Yup.number()
    .typeError('Unit must be a number')
    .required('Unit is required'),
});

export const AddIncidentReportSchema = Yup.object().shape({
  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),
  locationId: Yup.number()
    .required('Location is required')
    .min(1, 'Location must be greater than 0'),
  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be greater than 0'),
  divisionId: Yup.number()
    .required('Division is required')
    .min(1, 'Division must be greater than 0'),
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than 0'),
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0'),
  buildingId: Yup.number()
    .required('Building is required')
    .min(1, 'Building must be greater than 0'),
  area: Yup.string().required('Area is required'),
  verifiedBy: Yup.number()
    .required('Verified By is required')
    .min(1, 'Verified By must be greater than 0'),
  preparedBy: Yup.number()
    .required('Prepared By is required')
    .min(1, 'Prepared By must be greater than 0'),
  typeOfIncidentId: Yup.number()
    .required('Type of Incident is required')
    .min(1, 'Type of Incident must be greater than 0'),
  incidentDescription: Yup.string().required(
    'Incident Description is required'
  ),
  incidentImpact: Yup.string().required('Incident Impact is required'),
  briefDescription: Yup.string().required('Brief Description is required'),
  rootCauseAnalysis: Yup.string().required('Root Cause Analysis is required'),
  actionPlan: Yup.string().required('Action Plan is required'),
});
export const SalesOrdervalidationSchema = Yup.object().shape({
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than 0'),

  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0'),

  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),

  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be greater than 0'),

  salesOrderDistribustionDate: Yup.date()
    .required('Snag report date is required')
    .typeError('Invalid date format'),

  preparedBy: Yup.number()
    .required('Prepared by is required')
    .min(1, 'Prepared by must be greater than 0'),

  approvedBy: Yup.number()
    .required('Approved by is required')
    .min(1, 'Approved by must be greater than 0'),
  totalContractValue: Yup.number()
    .typeError('Total contract value must be a number')
    .required('Total contract value is required')
    .min(0, 'Total contract value cannot be negative'),

  totalBudgetValue: Yup.number()
    .typeError('Total budget value must be a number')
    .required('Total budget is required')
    .min(1, 'Total budget must be greater than 0'),
  budgetValue: Yup.number()
    .typeError('Budget value must be a number')
    .required('Budget value is required')
    .min(1, 'Budget value must be greater than 0')
    .max(100, 'Budget value cannot be more than 100'),
});

export const SalesOrderBudgetvalidationSchema = Yup.object().shape({
  divisionId: Yup.number()
    .required('Division is required')
    .min(1, 'Division must be greater than 0'),

  salesOrderId: Yup.number()
    .required('Sales Order is required')
    .min(1, 'Sales Order must be greater than 0'),

  totalContractValuePercentage: Yup.number()
    .typeError('Total contract value Percentage must be a number')
    .required('Total contract value Percentage is required')
    .min(0, 'Total contract value Percentage cannot be negative')
    .max(100, 'Total contract value Percentage cannot exceed 100%'),
  totalBudgetValuePercentage: Yup.number()
    .typeError('Total Budget value Percentage must be a number')
    .required('Total Budget value Percentage is required')
    .min(0, 'Total Budget value Percentage cannot be negative')
    .max(100, 'Total Budget value Percentage cannot exceed 100%'),

  totalBudgetValue: Yup.number()
    .typeError('Total budget value must be a number')
    .required('Total budget is required')
    .min(1, 'Total budget must be greater than 0'),
  totalContractValue: Yup.number()
    .typeError('Total contract value must be a number')
    .required('Total contract value is required')
    .min(1, 'Total contract value must be greater than 0'),
});
export const contractSchema = Yup.object().shape({
  contractName: Yup.string().required('Contract name is required'),
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than or equal to 1'),
  clientId: Yup.number()
    .required('Client is required')
    .min(1, 'Client must be greater than or equal to 1'),
  tenderId: Yup.number().required('Tender is required'),
  contractStartDate: Yup.date()
    .required('Contract start date is required')
    .typeError('Contract start date must be a valid date'),
  contractEndDate: Yup.date()
    .required('Contract end date is required')
    .typeError('Contract end date must be a valid date')
    .min(
      Yup.ref('contractStartDate'),
      'Contract end date must be after the start date'
    ),
  contactPerson: Yup.string().required('Contact person is required'),
  contactNumber: Yup.string().required('Contact number is required'),
  contactEmailId: Yup.string()
    .required('Contact email is required')
    .email('Contact email must be a valid email address'),
  contractCost: Yup.number()
    .typeError('Contract cost must be a number')
    .required('Contract cost is required')
    .min(0, 'Contract cost must be greater than or equal to 0'),
  budget: Yup.number()
    .typeError('Budget must be a number')
    .required('Budget is required')
    .min(0, 'Budget must be greater than or equal to 0'),
  contractTerms: Yup.string().required('Contract terms are required'),
  perviousContractCode: Yup.string().required('Contract code is required'), // Allow previous contract code to be optional
  contractModeId: Yup.number()
    .required('Contract mode is required')
    .min(1, 'Contract mode must be greater than or equal to 1'),
  referenceNumber: Yup.string().required('Reference number is required'), // Allow reference number to be optional
  contractTypeId: Yup.number()
    .required('Contract type is required')
    .min(0, 'Contract type must be greater than or equal to 0'),
  contractScopeId: Yup.number()
    .required('Contract scope is required')
    .min(0, 'Contract scope must be greater than or equal to 0'),
  billingFrequencyId: Yup.number()
    .required('Billing frequency is required')
    .min(0, 'Billing frequency must be greater than or equal to 0'),
  thresholdValue: Yup.number()
    .typeError('Threshold value must be a number')
    .required('Threshold value is required')
    .min(0, 'Threshold value must be greater than or equal to 0'),
  currencyId: Yup.number()
    .required('Currency is required')
    .min(0, 'Currency must be greater than or equal to 0'),
  guaranteeId: Yup.number()
    .required('Guarantee is required')
    .min(1, 'Guarantee must be greater than or equal to 1'),
  guaranteeStartDate: Yup.date()
    .required('Guarantee start date is required')
    .typeError('Guarantee start date must be a valid date'),
  guaranteeEndDate: Yup.date()
    .required('Guarantee end date is required')
    .typeError('Guarantee end date must be a valid date')
    .min(
      Yup.ref('guaranteeStartDate'),
      'Guarantee end date must be after the start date'
    ),
  guaranteeAmount: Yup.number()
    .typeError('Guarantee amount must be a number')
    .required('Guarantee amount is required')
    .min(0, 'Guarantee amount must be greater than or equal to 0'),
  remarks: Yup.string().nullable(), // Allow remarks to be optional
});

export const salesquotationItemsSchema = Yup.object().shape({
  itemId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Item is required'),
  quantity: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Quantity is required'),
  contractId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contract is required'),
  rate: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Rate is required'),

  discount: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Discount is required'),
  vat: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('VAT is required'),
});

export const TenderInspectionvalidationSchema = Yup.object().shape({
  tenderId: Yup.number()
    .required('Tender is required')
    .positive('Tender must be greater than 0'),
  propertyName: Yup.string().required('Property Name is required'),
  visitedDate: Yup.string().required('Visited Date is required'),
  clientId: Yup.number()
    .required('Client is required')
    .positive('Client must be greater than 0'),
  existServiceProvider: Yup.string().required(
    'Existing Service Provider is required'
  ),
  locationId: Yup.number()
    .required('Location is required')
    .positive('Location must be greater than 0'),
  noOfFloor: Yup.number()
    .required('Number of Floors is required')
    .positive('Number of Floors must be greater than 0')
    .integer('Number of Floors must be an integer'),
  flatPerFloor: Yup.string().required('Flats per Floor is required'),
  emiratedId: Yup.number()
    .required('Emirate is required')
    .positive('Emirate must be greater than 0'),
  buildingId: Yup.number()
    .required('Building is required')
    .positive('Building must be greater than 0'),
  remarks: Yup.string().required('Remarks is required'),
});
export const contractCustomerSchema = Yup.object().shape({
  contractCustomerName: Yup.string().required(
    'Contract customer name is required'
  ),
  groupTypeId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Group type is required'),
  clientId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Client is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  customerContactNumber: Yup.string().required(
    'Customer contact number is required'
  ),
  address: Yup.string().required('Address is required'),
});

export const contractScheduleSchema = Yup.object().shape({
  contractId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0'),
  billingFrequencyId: Yup.number()
    .required('Billing frequency is required')
    .min(1, 'Billing frequency must be greater than 0'),
  customerContactNumber: Yup.string().required(
    'Customer contact number is required'
  ),
  amountToBeInvoiced: Yup.number().required(
    'Customer contact number is required'
  ),
  invoicedAmount: Yup.number().required('Customer contact number is required'),
  invoiceRef: Yup.string().required('Customer contact number is required'),
  paymentAmount: Yup.number().required('Customer contact number is required'),
  paymentRef: Yup.string().required('Customer contact number is required'),
  deductionAmount: Yup.number().required('Customer contact number is required'),
  remarks: Yup.string().required('Address is required'),
});

export const ProjectTeamsSchema = Yup.object().shape({
  employeeId: Yup.number()
    .required('Contract is required')
    .min(1, 'Contract must be greater than 0'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  remarks: Yup.string().required('Address is required'),
});
export const requestForQuoteSchemaTableEdit = Yup.object().shape({
  requestForQuoteNumber: Yup.string().required(
    'Request for Quote Number is required'
  ),

  requestForQuoteStartDate: Yup.date()
    .required('Start Date is required')
    .typeError('Start Date must be a valid date'),

  requestForQuoteEndingDate: Yup.date()
    .required('Ending Date is required')
    .typeError('Ending Date must be a valid date')
    .min(
      Yup.ref('requestForQuoteStartDate'),
      'Ending Date must be after Start Date'
    ),

  companyId: Yup.number()
    .required('Company is required')
    .min(1, 'Company must be greater than 0'),

  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than 0'),

  regionId: Yup.number()
    .required('Region is required')
    .min(1, 'Region must be greater than 0'),

  divisionId: Yup.number()
    .required('Division is required')
    .min(1, 'Division must be greater than 0'),
});
export const employeeEducationSchema = Yup.object().shape({
  qualifications: Yup.array().of(
    Yup.object().shape({
      qualificationName: Yup.string().required('Qualification is required'),
      completedYear: Yup.number()
        .required('Passed Out year is required')
        .positive('Year must be positive')
        .integer('Year must be an integer'),

      fileId: Yup.string().nullable(), // Assuming fileId is optional
    })
  ),
});
export const manpowerSchema = Yup.object().shape({
  designationId: Yup.number()
    .required('Designation is required')
    .typeError('Designation must be a number')
    .min(1, 'Designation must be greater than 0'),

  companyId: Yup.number()
    .required('Company is required')
    .typeError('Company must be a number')
    .min(1, 'Company must be greater than 0'),
  regionId: Yup.number()
    .required('Region is required')
    .typeError('Region must be a number')
    .min(1, 'Region must be greater than 0'),
  grossSalary: Yup.number()
    .required('Gross Salary is required')
    .typeError('Gross Salary must be a number')
    .min(0, 'Gross Salary must be at least 0'),
  basicSalary: Yup.number()
    .required('Basic Salary is required')
    .typeError('Basic Salary must be a number')
    .min(0, 'Basic Salary must be at least 0'),
  housingAllowance: Yup.number()
    .required('Housing Allowance is required')
    .typeError('Housing Allowance must be a number')
    .min(0, 'Housing Allowance must be at least 0'),
  transportAllowance: Yup.number()
    .required('Transport Allowance is required')
    .typeError('Transport Allowance must be a number')
    .min(0, 'Transport Allowance must be at least 0'),
  foodAllowance: Yup.number()
    .required('Food Allowance is required')
    .typeError('Food Allowance must be a number')
    .min(0, 'Food Allowance must be at least 0'),
  otherAllowance: Yup.number()
    .required('Other Allowance is required')
    .typeError('Other Allowance must be a number')
    .min(0, 'Other Allowance must be at least 0'),
  telephoneAllowance: Yup.number()
    .required('Telephone Allowance is required')
    .typeError('Telephone Allowance must be a number')
    .min(0, 'Telephone Allowance must be at least 0'),
  additionalAllowance: Yup.number()
    .required('Additional Allowance is required')
    .typeError('Additional Allowance must be a number')
    .min(0, 'Additional Allowance must be at least 0'),
  incentiveAllowance: Yup.number()
    .required('Incentive Allowance is required')
    .typeError('Incentive Allowance must be a number')
    .min(0, 'Incentive Allowance must be at least 0'),
  deductions: Yup.number()
    .required('Deductions is required')
    .typeError('Deductions must be a number')
    .min(0, 'Deductions must be at least 0'),
  overHeadPercent: Yup.number()
    .required('Overhead Percent is required')
    .typeError('Overhead Percent must be a number')
    .min(0, 'Value must be at least 0')
    .max(100, 'Value cannot exceed 100'),
  ctcPerMonth: Yup.number()
    .required('CTC Per Month is required')
    .min(0, 'CTC Per Month must be at least 0')
    .positive('CTC Per Month must be positive'),
});

export const CustomerSatisfactionSchema = Yup.object().shape({
  areaId: Yup.number().required('Area is required'),
  subAreaId: Yup.number().required('Sub Area is required'),
  mark: Yup.string().required('Mark is required'),
  remarks: Yup.string().required('Remarks is required'),
});
export const CustomerSatisfactionItemSchema = Yup.object().shape({
  projectId: Yup.number().required('Project is required'),
  locationId: Yup.number().required('Location is required'),
  buildingId: Yup.number().required('Building is required'),
  surveyTaker: Yup.number().required('Survey Taker is required'),
  designationId: Yup.number().required('Designation is required'),
  firstAddress: Yup.string().required('First Address is required'),
  secondAddress: Yup.string().required('Second Address is required'),
  date: Yup.string().required('Date is required'),
  remarks: Yup.string().required('Remarks is required'),
});
export const CustomersSchema = Yup.object().shape({
  customerName: Yup.string().required('Customer Name is required'),
});

export const AreasSchema = Yup.object().shape({
  areaName: Yup.string().required('Area Name is required'),
});
export const SubAreaSchema = Yup.object().shape({
  subAreaName: Yup.string().required('Sub Area Name is required'),
  areaId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Area is required'),
});

export const contractLocationSchema = Yup.object().shape({
  regionId: Yup.number().required('Region is required'),

  emirateId: Yup.number().required('Emirate is required'),

  zoneId: Yup.number().required('Zone is required'),

  locationId: Yup.number().required('Location is required'),
  buildingId: Yup.number().required('Building is required'),
});

export const BlockValidationSchema = Yup.object().shape({
  blockName: Yup.string().required('Block Name is required'),
});
export const BlockEditValidationSchema = Yup.object().shape({
  blockName: Yup.string().required('Block Name is required'),
});
export const RoomValidationSchema = Yup.object().shape({
  roomName: Yup.string().required('Room Name is required'),
  roomNo: Yup.number()
    .required('Room No is required')
    .typeError('Room No must be a number'),
  totalCapacity: Yup.number()
    .required('Total Capacity is required')
    .typeError('Total Capacity must be a number'),
});
export const RoomEditValidationSchema = Yup.object().shape({
  roomName: Yup.string().required('Room Name is required'),
  roomNo: Yup.number()
    .required('Room No is required')
    .typeError('Room No must be a number'),
  totalCapacity: Yup.number()
    .required('Total Capacity is required')
    .typeError('Total Capacity must be a number'),
});

export const monthsupdateschema = Yup.object().shape({
  divisionId: Yup.number()
    .min(1, 'Division must be greater than 0')
    .required('Division is required'),
  vehicleMethod: Yup.boolean().required('Vehicle method is required'),
  month: Yup.number()
    .min(1, 'Month must be between 1 and 12')
    .max(12, 'Month must be between 1 and 12')
    .required('Month is required'),
  year: Yup.number()
    .min(1900, 'Year must be valid')
    .max(new Date().getFullYear(), 'Year cannot be in the future')
    .required('Year is required'),
  locationId: Yup.number()
    .min(1, 'Location must be greater than 0')
    .required('Location is required'),
  projectId: Yup.number()
    .min(1, 'Project must be greater than 0')
    .required('Project is required'),
  siteId: Yup.number()
    .min(1, 'Site must be greater than 0')
    .required('Site is required'),
  currentKilometer: Yup.number()
    .min(0, 'Current kilometer must be greater than or equal to 0')
    .required('Current kilometer is required'),
  pickUpTime: Yup.string().required('Pick-up time is required'),
  dropoffTime: Yup.string().required('Dropoff time is required'),
  remarks: Yup.string()
    .max(500, 'Remarks must be 500 characters or less')
    .required('Remarks are required'),
  transporterName: Yup.string()
    .max(255, 'Transporter name must be 255 characters or less')
    .required('Transporter name is required'),
  contractStartingPeriod: Yup.date()
    .required('Contract starting period is required')
    .max(
      Yup.ref('contractEndingPeriod'),
      'Start date must be before the end date'
    ),
  contractEndingPeriod: Yup.date()
    .required('Contract ending period is required')
    .min(
      Yup.ref('contractStartingPeriod'),
      'End date must be after the start date'
    ),
  rentAmount: Yup.number()
    .min(0, 'Rent amount must be greater than or equal to 0')
    .required('Rent amount is required'),
  contactPerson: Yup.number()
    .min(1, 'Contact person must be greater than 0')
    .required('Contact person is required'),
});
export const VehicleAdvertisementSchema = Yup.object().shape({
  vechicleId: Yup.number().required('Vehicle is required'),
  remarks: Yup.string().required('Remarks is required'),
  permitNumber: Yup.string().required('Premit Number is required'),
  issueDate: Yup.date().required('Issue Date is required'),
  expiryDate: Yup.date().required('Expiry Date is required'),
});

export const addVehicleMastervalidationSchema = Yup.object().shape({
  vehicleCategoryId: Yup.string().required('vehicle category is required'),
  vehicleName: Yup.number().required('vehicle name is required'),
  vehicleBrandId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be selected'),
  vehiclePlateNo: Yup.string().required('vehicle plate no is required'),
  chasisNo: Yup.string().required('Chasis no is required'),
  engineNo: Yup.number().required('Engine is required'),
  vehicleStatusId: Yup.string().required('vehicle Status is required'),
});
export const AdminVehiclevalidationSchema = Yup.object().shape({
  vehicleTypeName: Yup.string().required('Vehicle Type is required'),
});
export const DriverMastervalidationSchema = Yup.object().shape({
  driverName: Yup.string().required('Driver Name is required'),
  licenseNumber: Yup.string().required('License Number is required'),
  loginUserId: Yup.string().required('Prepared By is required'),
});
export const TrafficSourcevalidationSchema = Yup.object().shape({
  trafficSourceName: Yup.string().required('Traffic Source is required'),
  emirateId: Yup.string().required('Emirate is required'),
});
export const VehicleBrandvalidationSchema = Yup.object().shape({
  vehicleBrandName: Yup.string().required('Vehicle Brand is required'),
});
export const VehicleCategoryvalidationSchema = Yup.object().shape({
  vehicleCategoryName: Yup.string().required('Vehicle Category is required'),
});
export const VehicleInsurancevalidationSchema = Yup.object().shape({
  vehicleInsuranceName: Yup.string().required('Insurance Name is required'),
  policyNumber: Yup.string().required('Policy Number is required'),
  premiumAmount: Yup.number().required('Premium Amount is required')
    .typeError('Premium Amount must be a number'),
  startDate: Yup.string().required('Policy Number is required'),
  endDate: Yup.string().required('End Date is required'),
  policyType: Yup.string().required('Policy Type is required'),
});

export const VehiclevalidationSchema = Yup.object().shape({
  vehicleName: Yup.string().required('Vehicle Name is required'),
  vehicleCategoryId: Yup.string().required('Vehicle Category is required'),
  vehicleTypeId: Yup.string().required('Vehicle Type is required'),
  vehiclePlateTypeId: Yup.string().required('Plate Type is required'),
  vehicleRegistered: Yup.string().required('Vehicle Registered is required'),
  presentlyLocatedId: Yup.string().required('Location is required'),
  vehicleBrandId: Yup.string().required('Vehicle Brand is required'),
  vehicleStatusId: Yup.string().required('Vehicle Status is required'),
  vehiclePlateNo: Yup.string().required('Vehicle Plate is required'),
  chasisNo: Yup.string().required('Chasis Number is required'),
  engineNo: Yup.string().required('Engine Number is required'),
  registeredName: Yup.string().required('Registered Name is required'),
  trafficCodeNo: Yup.string().required('Traffic Code is required'),
  registeredDate: Yup.string().required('Registered Date is required'),
  registeredExpiryDate: Yup.string().required(
    'Registered Expiry Date is required'
  ),
  insuranceExpiryDate: Yup.string().required(
    'Insurance Expiry Date is required'
  ),
  vehicleModel: Yup.string().required('Vehicle Model is required'),
});
export const VehicleStatusvalidationSchema = Yup.object().shape({
  vehicleStatusName: Yup.string().required('Vehicle Status is required'),
});
export const VehicleSuppliervalidationSchema = Yup.object().shape({
  officialSupplierName: Yup.string().required('Supplier Name is required'),
  address: Yup.string().required('Address is required'),
  headOfficeAddress: Yup.string().required('Head Office Address is required'),
  taxNumber: Yup.string().required('taxNumber is required'),
  mobileNumber: Yup.string().required('Mobile Number is required'),
  emailId: Yup.string().required('Email is required'),
  taxPercentage: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Tax Percentage is required')
    .min(0, 'Tax Percentage cannot be less than 0')
    .max(100, 'Tax Percentage cannot exceed 100'),
  tradeLicenseNumber: Yup.string().required('Trade License is required'),
  creditLimit: Yup.string().required('Credit Limit is required'),
  supplierTypeId: Yup.string().required('Supplier Type is required'),
  regionId: Yup.string().required('Region is required'),
  countryId: Yup.string().required('Country Name is required'),
});
export const vehicelsSchema = Yup.object().shape({
  vehicleId: Yup.string().required('Vehicle is required'),
  trafficCodeNo: Yup.string().required('Traffic Code No is required'),
  insuranceId: Yup.string().required('Insurance is required'),
  driverId: Yup.string().required('Driver is required'),
  divisionId: Yup.string().required('Division is required'),
  advertisement: Yup.boolean().required('Advertisement is required'),
});

export const HiredVechiclesSchema = Yup.object().shape({
  vehicleRegNo: Yup.string()
    .required('Vehicle registration number is required')
    .matches(
      /^[A-Za-z0-9-\s]+$/,
      'Vehicle registration number must be alphanumeric, may include hyphens, and can contain spaces'
    ),

  vehiclePlateNo: Yup.string()
    .required('Vehicle plate number is required')
    .matches(
      /^[A-Za-z0-9-\s]+$/,
      'Vehicle plate number must be alphanumeric, may include hyphens, and can contain spaces'
    ),

  vehicleTypeId: Yup.number()
    .required('Vehicle type is required')
    .positive('Vehicle type must be greater than zero')
    .integer('Vehicle type must be an integer'),

  siteId: Yup.number()
    .required('Site is required')
    .positive('Site must be greater than zero')
    .integer('Site must be an integer'),

  locationId: Yup.number()
    .required('Location is required')
    .positive('Location must be greater than zero')
    .integer('Location must be an integer'),

  divisionId: Yup.number()
    .required('Division is required')
    .positive('Division must be greater than zero')
    .integer('Division must be an integer'),

  pickUpTime: Yup.string().required('Pick-up time is required'),

  dropUpTime: Yup.string().required('Drop-off time is required'),

  currentKilometer: Yup.number()
    .required('Current kilometer is required')
    .typeError('Current kilometer must be a number')
    .positive('Current kilometer must be greater than zero')
    .integer('Current kilometer must be an integer'),

  supplierId: Yup.number()
    .required('Supplier is required')
    .positive('Supplier must be greater than zero')
    .integer('Supplier must be an integer'),

  stationId: Yup.number()
    .required('Station is required')
    .positive('Station must be greater than zero')
    .integer('Station must be an integer'),

  driverId: Yup.number()
    .required('Driver is required')
    .positive('Driver must be greater than zero')
    .integer('Driver must be an integer'),

  projectId: Yup.number()
    .required('Project is required')
    .positive('Project must be greater than zero')
    .integer('Project must be an integer'),

  contractId: Yup.number()
    .required('Contract is required')
    .positive('Contract must be greater than zero')
    .integer('Contract must be an integer'),

  rentAmount: Yup.number()
    .required('Rent amount is required')
    .typeError('Rent amount must be a number')
    .positive('Rent amount must be greater than zero'),

  contactPersonId: Yup.number()
    .required('Contact person is required')
    .positive('Contact person must be greater than zero')
    .integer('Contact person must be an integer'),

  remarks: Yup.string().optional(),
});
export const supplierDocumentsSchema = Yup.object().shape({
  supplierDocumentName: Yup.string().required('Document name is required'),
  validityEndDate: Yup.date().required('End date is required'),
  validityStartDate: Yup.date().required('Start date is required'),
  // fileUploadId: Yup.number().required('File is required'),
});

export const uniformsSchama = Yup.object().shape({
  requestDate: Yup.date()
    .required('Request date is required')
    .typeError('Invalid date format'),
  projectId: Yup.number()
    .required('Project is required')
    .min(1, 'Project must be greater than 0')
    .typeError('Project must be a number'),
  uniformSizeId: Yup.number()
    .required('Uniform Size is required')
    .min(1, 'Uniform Size must be greater than 0')
    .typeError('Uniform Size must be a number'),
  uniformTypeId: Yup.number()
    .required('Uniform Type is required')
    .min(1, 'Uniform Type must be greater than 0')
    .typeError('Uniform Type must be a number'),
  uniformCategoryId: Yup.number()
    .required('Uniform Category is required')
    .min(1, 'Uniform Category must be greater than 0')
    .typeError('Uniform Category must be a number'),
  quantity: Yup.number()
    .required('Quantity is required')
    .typeError('Quantity must be a number'),
});

export const uniformRequsitionReturnSchema = Yup.object().shape({
  requistionDate: Yup.date()
    .required('Requisition date is required')
    .typeError('Invalid date format'),
  employeeId: Yup.number()
    .positive('Employee must be greater than 0')
    .required('Employee is required'),
  uniformAndAccessoriesRequisitionId: Yup.number()
    .positive('Requisition must be greater than 0')
    .required('Requisition is required'),
  qrCode: Yup.string(),
  remarks: Yup.string().required('Remarks is required'),
});

export const CampValidationSchema = Yup.object().shape({
  campName: Yup.string().required('Camp Name is required'),
  accommodationId: Yup.number().required('Accommodation is required'),
  employeeId: Yup.number().required('Employee is required'),
  blockId: Yup.number().required('Block is required'),
  roomId: Yup.number().required('Room is required'),
  occupied: Yup.number()
    .required('Occupied is required')
    .typeError('Occupied must be a number'),
  // TODO
  // employeeRoomStatusId: Yup.number().required('Employee Room Status is required'),
});

export const PurchaseVoucherSchema = Yup.object().shape({
  purchaseVoucherName: Yup.string().required(
    'Purchase Voucher Name is required'
  ),
  purchaseVoucherCode: Yup.string().required(
    'Purchase Voucher Code is required'
  ),
  purchaseVoucherDate: Yup.date().required('Purchase Voucher Date is required'),
  // vendorId: Yup.number().required('Vendor is required'),
  currencyId: Yup.number().required('Currency is required'),
  locationId: Yup.number().required('Location is required'),
  supplyLocationId: Yup.number().required('Supply Location is required'),
  companyId: Yup.number().required('Company is required'),
  inCharge: Yup.number().required('In Charge is required'),
  requestorId: Yup.number().required('Requestor is required'),
  salesOrderId: Yup.number().required('Sales Order is required'),
  paymentTermId: Yup.number().required('Payment Term is required'),
  paymentTypeId: Yup.number().required('Payment Type is required'),
  divisionId: Yup.number().required('Division is required'),
  approvedBy: Yup.number().required('Approved By is required'),
  paymentDueDate: Yup.date().required('Payment Due Date is required'),
  invoiceDate: Yup.date().required('Invoice Date is required'),
  invoiceNo: Yup.string().required('Invoice No is required'),
  billStatus: Yup.string().required('Bill Status is required'),
  exchangeRate: Yup.number().required('Exchange Rate is required'),
});

export const PurchaseVoucherItemSchema = Yup.object().shape({
  purchaseVoucherItemName: Yup.string().required(
    'Purchase Voucher Item Name is required'
  ),
  purchaseVoucherId: Yup.number().required('Purchase Voucher is required'),
  projectId: Yup.number().required('Project is required'),
  quantity: Yup.number().required('Quantity is required'),
  // contractId: Yup.number().required('Contract is required'),
  // workOrderId: Yup.number().required('Work Order is required'),
  // salesOrderId: Yup.number().required('Sales Order is required'),
  // inCharge: Yup.number().required('In Charge is required'),
  // divisionId: Yup.number().required('Division is required'),
  // locationId: Yup.number().required('Location is required'),
  // costCenterId: Yup.number().required('Cost Center is required'),
  // itemId: Yup.number().required('Item is required'),
  // description: Yup.string().required('Description is required'),
  // taxCodeId: Yup.number().required('Tax Code is required'),
  // purchaseAccount: Yup.string().required('Purchase Account is required'),
  // rate: Yup.number().required('Rate is required'),
  // gross: Yup.number().required('Gross is required'),
  // discount: Yup.number().required('Discount is required'),
  // discountPercentage: Yup.number()
  //   .required('Discount Percentage is required')
  //   .max(100, 'Discount Percentage cannot exceed 100'),
  // remarks: Yup.string().required('Remarks is required'),
  // make: Yup.string().required('Make is required'),
  // model: Yup.string().required('Model is required'),
  // technicalSpecs: Yup.string().required('Technical Specs is required'),
  // areaOfUsage: Yup.string().required('Area Of Usage is required'),
});
export const CustomersatisfactionReportValidationSchema = Yup.object().shape({
  customerName: Yup.string().required('Customer Name is required'),
  customerDesignation: Yup.string().required(
    'Customer Designation is required'
  ),
  contactNumber: Yup.string().required('Contact Number is required'),
  jobOrderId: Yup.number().required('Job Order is required'),
  locationId: Yup.number().required('Location is required'),
  customerSatisfactionQuestionTypeId: Yup.number().required(
    'Customer Satisfaction Question is required'
  ),
  question: Yup.string().required('Question is required'),
  performanceRanking: Yup.number()
    .required('Performance Ranking is required')
    .typeError('Performance Ranking must be a number'),
  priorityRanking: Yup.number()
    .required('Priority Ranking is required')
    .typeError('Priority Ranking must be a number'),
  remarks: Yup.string().required('Remarks is required'),
});
export const ReceiptKeyValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .typeError('Quantity must be a number')
    .required('Quantity Number is required')
    .min(0, 'Quantity Number cannot be negative'),

  codeNumber: Yup.string().required('Code Number is required'),

});

export const SalesRetentionItemvalidationSchema = Yup.object().shape({
  companyId: Yup.number().required('Company is required'),
  itemId: Yup.number().required('Item is required'),
  description: Yup.string().required('Description is required'),
  taxCodeId: Yup.number().required('Tax is required'),
  unitOfMeasurementId: Yup.number().required('UOM is required'),
  quantity: Yup.number()
    .required('Quantity is required')
    .typeError('Quantity must be a number'),
  rate: Yup.number().required('Rate is required'),
  gross: Yup.number()
    .required('Gross is required')
    .typeError('Gross must be a number'),
  discount: Yup.number()
    .required('Discount is required')
    .typeError('Discount must be a number'),
  discountPercent: Yup.number()
    .required('Discount percent is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
  vat: Yup.number()
    .required('Vat is required')
    .typeError('Vat must be a number'),
  amountWithTax: Yup.number()
    .required('Amount with tax is required')
    .typeError('Amount with tax must be a number'),
  remarks: Yup.string().required('Remarks is required'),
});

export const editSalesQuotationSchema = Yup.object().shape({
  salesQuotationName: Yup.string().required('Sales Quotation Name is required'),
  quotationDate: Yup.date().required('Quotation Date is required'),
  workOrderId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Work Order is required'),
  regionId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Region is required'),
  locationId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Location is required'),
  paymentTerms: Yup.string().required('Payment Terms is required'),
  contactPersonId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Contact Person is required'),
  deliveryTerms: Yup.string().required('Delivery Terms is required'),
  subject: Yup.string().required('Subject is required'),
  notes: Yup.string().required('Notes is required'),
  attn: Yup.string().required('Attn is required'),
  validity: Yup.string().required('Validity is required'),
  quotationStatusId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Quotation Status is required'),
});

export const AddSalesRetentionSehema = Yup.object().shape({
  salesRetentionDate: Yup.date().required('Sales Retention Date is required'),
  salesRetentionCode: Yup.string()
    .required('Sales Retention Code is required')
    .max(100, 'Sales Retention Code must be 100 characters or less'),
  customerId: Yup.number()
    .required('Customer is required')
    .positive('Customer must be a positive number')
    .integer('Customer must be an integer'),
  companyId: Yup.number()
    .required('Company is required')
    .positive('Company must be a positive number')
    .integer('Company must be an integer'),
  dueDate: Yup.date().required('Due Date is required'),
  currencyId: Yup.number()
    .required('Currency is required')
    .positive('Currency must be a positive number')
    .integer('Currency must be an integer'),
  exchangeRate: Yup.number()
    .required('Exchange Rate is required')
    .positive('Exchange Rate must be a positive number'),
  projectId: Yup.number()
    .required('Project is required')
    .positive('Project must be a positive number')
    .integer('Project must be an integer'),
  contractId: Yup.number()
    .required('Contract is required')
    .positive('Contract must be a positive number')
    .integer('Contract must be an integer'),
  salesOrderId: Yup.number()
    .required('Sales Order is required')
    .positive('Sales Order must be a positive number')
    .integer('Sales Order must be an integer'),
  salesOrderDate: Yup.date().required('Sales Order Date is required'),
  workOrderId: Yup.number()
    .required('Work Order is required')
    .positive('Work Order must be a positive number')
    .integer('Work Order must be an integer'),
  workOrderDate: Yup.date().required('Work Order Date is required'),
  locationId: Yup.number()
    .required('Location is required')
    .positive('Location must be a positive number')
    .integer('Location must be an integer'),
  emirateId: Yup.number()
    .required('Emirate is required')
    .positive('Emirate must be a positive number')
    .integer('Emirate must be an integer'),
  paymentTerms: Yup.string()
    .required('Payment Terms are required')
    .max(200, 'Payment Terms must be 200 characters or less'),
  requestorId: Yup.number()
    .required('Requestor is required')
    .positive('Requestor must be a positive number')
    .integer('Requestor must be an integer'),
  paymentTypeId: Yup.number()
    .required('Payment Type is required')
    .positive('Payment Type must be a positive number')
    .integer('Payment Type must be an integer'),
  inCharge: Yup.number()
    .required('In Charge is required')
    .positive('In Charge must be a positive number')
    .integer('In Charge must be an integer'),
  salesQuotationId: Yup.number()
    .required('Sales Quotation is required')
    .positive('Sales Quotation must be a positive number')
    .integer('Sales Quotation must be an integer'),
  remarks: Yup.string()
    .required('Remarks are required')
    .max(500, 'Remarks must be 500 characters or less'),
});
export const CBPettyCashValidationSchema = Yup.object().shape({
  bankId: Yup.number().required('Bank is required'),
  companyId: Yup.number().required('Company is required'),
  branchId: Yup.number().required('Branch is required'),
});

export const AMCTableValidationSchema = Yup.object().shape({
  overHeadPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Overhead Percent cannot be negative')
    .max(100, 'Overhead Percent cannot exceed 100'),
  contingencyPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Contingency Percent cannot be negative')
    .max(100, 'Contingency Percent cannot exceed 100'),
  markUpPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Markup Percent cannot be negative')
    .max(100, 'Markup Percent cannot exceed 100'),
  discountPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
  negotiationPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation Percent cannot be negative')
    .max(100, 'Negotiation Percent cannot exceed 100'),
  description: Yup.string().required('Description is required'),
});

export const MEPTableValidationSchema = Yup.object().shape({
  overHeadPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Overhead Percent cannot be negative')
    .max(100, 'Overhead Percent cannot exceed 100'),
  contingencyPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Contingency Percent cannot be negative')
    .max(100, 'Contingency Percent cannot exceed 100'),
  markUpPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Markup Percent cannot be negative')
    .max(100, 'Markup Percent cannot exceed 100'),
  discountPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Discount Percent cannot be negative')
    .max(100, 'Discount Percent cannot exceed 100'),
  negotiationPercent: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Negotiation Percent cannot be negative')
    .max(100, 'Negotiation Percent cannot exceed 100'),
  bill: Yup.string().required('Bill is required'),
  head: Yup.string().required('Head is required'),
  subHead: Yup.string().required('Sub Head is required'),
  lineItem: Yup.string().required('Line Item is required'),
});

export const AddUniformRequistionsSchema = Yup.object().shape({
  projectId: Yup.number()
    .required('Project is required')
    .positive('Project must be a positive number')
    .integer('Project must be an integer'),

  contractId: Yup.number()
    .required('Contract is required')
    .positive('Contract must be a positive number')
    .integer('Contract must be an integer'),

  endDate: Yup.date()
    .required('End Date is required')
    .typeError('End Date must be a valid date'),

  requestedBy: Yup.number()
    .required('Requested By is required')
    .positive('Requested By must be a positive number')
    .integer('Requested By must be an integer'),

  approvedBy: Yup.number()
    .required('Approved By is required')
    .positive('Approved By must be a positive number')
    .integer('Approved By must be an integer'),

  employeeId: Yup.number()
    .required('Employee is required')
    .positive('Employee must be a positive number')
    .integer('Employee must be an integer'),

  uniformSizeId: Yup.number()
    .required('Uniform Size is required')
    .positive('Uniform Size must be a positive number')
    .integer('Uniform Size must be an integer'),

  uniformTypeId: Yup.number()
    .required('Uniform Type is required')
    .positive('Uniform Type must be a positive number')
    .integer('Uniform Type must be an integer'),

  qrCode: Yup.string()
    .required('QR Code is required')
    .trim('QR Code cannot include leading or trailing spaces'),

  quantity: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    ) // Transform empty strings to undefined
    .required('Quantity is required')
    .positive('Quantity must be a positive number')
    .integer('Quantity must be an integer'),
});
export const enquiryValidationSchema = Yup.object().shape({
  tenderName: Yup.string().required('Tender Name is required'),
  clientId: Yup.number()
    .positive('Client must be greater than 0')
    .required('Client is required'),
  tenderProjectTypeId: Yup.number()
    .positive('Tender Project Type must be greater than 0')
    .required('Tender Project Type is required'),
  subContractRequired: Yup.mixed()
    .oneOf([true, false], 'subContractRequired is required')
    .required('subContractRequired is required'),
  startDate: Yup.date().required('Start Date is required').nullable(),
  expressOfInterest: Yup.mixed()
    .oneOf([true, false], 'Bid Bond field is required')
    .required('Express of Interest field is required'),
  buyTenderDocument: Yup.mixed()
    .oneOf([true, false], 'Buy Tender Document field is required')
    .required('Buy Tender Document field is required'),
  tenderNumber: Yup.string().required('Tender Number is required'),
  tenderStatusId: Yup.number()
    .positive('Tender Status must be greater than 0')
    .required('Tender Status is required'),
  tenderEnquiryTypeId: Yup.number()
    .positive('Tender Enquiry Type must be greater than 0')
    .required('Tender Enquiry Type is required'),
  tenderFormatId: Yup.number()
    .positive('Tender Format must be greater than 0')
    .required('Tender Format is required'),
  requestForEstimate: Yup.string().required('Request For Estimate is required'),
  companyId: Yup.number().required('Company is required'),
});
export const AddInspectionReportSchema = Yup.object({
  companyId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  clientId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  projectId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  contractId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  locationId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  buildingId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),

  monthId: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  signatureImage: Yup.number()
    .nullable()
    .required('This field is required')
    .positive('Value must be positive')
    .integer('Value must be an integer'),
  referenceNumber: Yup.string().required('This field is required'),
  occupancy: Yup.string().required('This field is required'),
  inspectedArea: Yup.string().required('This field is required'),
});
