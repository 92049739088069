import React, { useState } from 'react';
import FmMRTDataTable from '../../../components/FmMRTDataTable.tsx';

const ListOwnVehicles = (props) => {
  const {
    isLoading,
    pagination,
    rows,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
  } = props;
  const [editingRowId] = useState(null);

  const columns = [
    {
      accessorKey: 'ownVehicleCode',
      header: 'Own Vehicle Code',
      width: 200,
    },
    { accessorKey: 'vehicleName', header: 'Vehicle Name', width: 200 },
    {
      accessorKey: 'vehicleBrandName',
      header: 'Vehicle Brand',
      width: 200,
    },
    {
      accessorKey: 'vehicleCategoryName',
      header: 'Vehicle Category',
      width: 200,
    },
    { accessorKey: 'vehicleTypeName', header: 'Vehicle Type', width: 200 },
    {
      accessorKey: 'vehiclePlateTypeName',
      header: 'Plate Type',
      width: 200,
    },
    { accessorKey: 'vehiclePlateNo', header: 'Plate Number', width: 200 },
    {
      accessorKey: 'vehicleRegisteredName',
      header: 'Registered Name',
      width: 200,
    },
    {
      accessorKey: 'presentlyLocatedName',
      header: 'Present Location',
      width: 200,
    },
    { accessorKey: 'vehicleModel', header: 'Vehicle Model', width: 200 },
    {
      accessorKey: 'registeredDate',
      header: 'Registered Date',
      width: 200,
    },
    {
      accessorKey: 'registeredExpiryDate',
      header: 'Registered Expiry Date',
      width: 200,
    },
    {
      accessorKey: 'insuranceExpiryDate',
      header: 'Insurance Expiry Date',
      width: 200,
    },
    { accessorKey: 'trafficCodeNo', header: 'Traffic Code No', width: 200 },
    { accessorKey: 'insuranceName', header: 'Insurance Name', width: 200 },
    { accessorKey: 'driverName', header: 'Driver Name', width: 200 },
    {
      accessorKey: 'driverContactNumber',
      header: 'Driver Contact Number',
      width: 200,
    },
    { accessorKey: 'divisionName', header: 'Division Name', width: 200 },
    { accessorKey: 'permitNumber', header: 'Permit Number', width: 200 },
  ];

  return (
    <FmMRTDataTable
      rows={rows}
      columns={columns}
      enableRowSelection={true}
      rowCount={tableRecordCounts}
      editingRow={editingRowId}
      pagination={pagination}
      sorting={[]}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      setPagination={setPagination}
      setSorting={setSorting}
      setColumnFilters={setColumnFilters}
      setGlobalFilter={setGlobalFilter}
      isLoading={isLoading}
      setSearchKeyword={setSearchKeyword}
      searchKeyword={searchKeyword}
    />
  );
};

export default ListOwnVehicles;
