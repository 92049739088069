import React, { useEffect, useState } from 'react';
import FmTextField from '../../../../../components/_mui/FmTextField.tsx';
import TableActionButtons from '../../../../../components/_form/TableActionButtons.tsx';
import FmMRTDataTable from '../../../../../components/FmMRTDataTable.tsx';
import { useForm } from 'react-hook-form';
import useTableLogic from '../../../../../components/helpers/MRTUseTableLogic.tsx';
import { useParams } from 'react-router-dom';
import Nav from '../../../../../utils/Nav.tsx';
import DashboardLayout from '../../../../../components/DashboardLayout.tsx';
import Crud_Service from '../../../../../apis/CrudService.tsx';
import { toast } from 'react-toastify';
import FmSearchableSelect from '../../../../../components/_mui/FmSearchableSelect.tsx';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import Icons from '../../../../../utils/Icon.tsx';
import AddPVItems from './AddPVItems.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { PurchaseVoucherItemSchema } from '../../../../../utils/CommonvalidationSchemas.tsx';
import GlassCard from '../../../../../components/small/GlassCard.tsx';
const BillingValues = [
  {
    name: 'Quantity',
    value: 1,
  },

  {
    name: 'Gross',
    value: '-',
  },

  {
    name: 'Discount',
    value: 0,
  },

  {
    name: 'Discount %',
    value: 0,
  },

  {
    name: 'VAT',
    value: 5,
  },

  {
    name: 'Taxable',
    value: 1000,
  },

  {
    name: 'VAT Advance',
    value: 0,
  },

  {
    name: 'Net',
    value: 1050,
  },
];
const ViewPurchaseVoucherList = () => {
  const { control, setValue, handleSubmit, getValues } = useForm({
    resolver: yupResolver(PurchaseVoucherItemSchema),
    mode: 'onChange',
  });
  const id = useParams().id;

  const {
    rows,
    pagination,
    sorting,
    fetchData,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`purchasevoucherItem?purchaseVoucherId=${id}`);

  useEffect(() => {
    const len = rows?.length;
    const gross = rows?.reduce((total, row) => total + row?.gross, 0);
    const discount = rows?.reduce((total, row) => total + row?.discount, 0);
    const discountPercent = rows?.reduce(
      (total, row) => total + row?.discountPercentage,
      0
    );
    const net = rows?.reduce((total, row) => total + row?.net, 0);
    BillingValues[0].value = len;
    BillingValues[1].value = gross;
    BillingValues[2].value = discount;
    BillingValues[3].value = discountPercent;
    BillingValues[7].value = net;
  }, [rows]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();

  const [loader, setLoader] = useState(false);
  const [, setViewdetails] = useState(false);
  const handleEdit = async (row) => {
    setEditingRowId(row?.purchaseVoucherItemId);
    setEditingRowData({ ...row });

    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleEditItem = async (values) => {
    const data = {
      ...values,
      status: 2,
      vendorId: values.supplierId,
    };

    await crud.update(
      'purchasevoucheritem',
      editingRowData?.purchaseVoucherItemId,
      data,
      (_err, res) => {
        if (res?.status === 200) {
          toast.success('Purchase Voucher Item Updated Successfully');
          fetchData();
        } else {
          toast.error('Something went wrong');
        }
      }
    );
  };
  const handleDeleteVoucher = async (view) => {
    await crud.remove(
      'purchasevoucheritem',
      view?.purchaseVoucherItemId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success('Purchase Voucher Item Deleted Successfully');
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };

  const ActionData = [
    {
      name: 'View',
      icon: Icons.view,
      onClick: (_props) => {
        setViewdetails(_props.purchaseVoucherItemId);
      },
    },
    {
      name: 'Edit',
      icon: Icons.edit,
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: Icons.delete,
      onClick: (props) => handleDeleteVoucher(props),
      danger: true,
    },
  ];

  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.purchaseVoucherItemId ? (
            <>
              <Button onClick={handleSubmit(handleEditItem)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },

    {
      accessorKey: 'areaOfUsage',
      header: 'Area Of Usage',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='areaOfUsage' control={control} />
        ) : (
          row?.original?.areaOfUsage || ''
        );
      },
    },
    {
      accessorKey: 'currencyId',
      header: 'Currency',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='currencyId'
            control={control}
            apiUrl='currencies'
            label='Currency'
            valueField='currencyId'
            labelField={['currencyName']}
            showField={['currencyName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.currencyName || ''
        );
      },
    },
    {
      accessorKey: 'discountPercentage',
      header: 'Discount Percentage',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='discountPercentage' control={control} />
        ) : (
          row?.original?.discountPercentage || ''
        );
      },
    },
    {
      accessorKey: 'gross',
      header: 'Gross',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='gross' control={control} />
        ) : (
          row?.original?.gross || ''
        );
      },
    },
    {
      accessorKey: 'inCharge',
      header: 'In Charge',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='inCharge'
            control={control}
            apiUrl='employees'
            label='In Charge'
            valueField='employeeId'
            labelField={['employeeName']}
            showField={['employeeName']}
            pageSize={20}
          />
        ) : (
          row?.original?.inCharge || ''
        );
      },
    },
    {
      accessorKey: 'isTaxable',
      header: 'Is Taxable',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <RadioGroup
            sx={{ flexDirection: 'row' }}
            name='isTaxable'
            onChange={(e) => setValue('isTaxable', e.target.value === 'true')}
            value={getValues('isTaxable')}
          >
            <FormControlLabel value={true} control={<Radio />} label='Yes' />
            <FormControlLabel value={false} control={<Radio />} label='No' />
          </RadioGroup>
        ) : row?.original?.isTaxable === true ? (
          'Yes'
        ) : (
          'No'
        );
      },
    },
    {
      accesorKey: 'vat',
      header: 'VAT',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='vat'
            type='number'
            disabled={!getValues('isTaxable')}
            control={control}
          />
        ) : (
          row?.original?.vat || ''
        );
      },
    },
    {
      accessorKey: 'purchaseVoucherItemName',
      header: 'Item Name',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='purchaseVoucherItemName' control={control} />
        ) : (
          row?.original?.purchaseVoucherItemName || ''
        );
      },
    },
    {
      accessorKey: 'purchaseVoucherId',
      header: 'Purchase Voucher',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='purchaseVoucherId'
            control={control}
            apiUrl='purchaseVouchers'
            label='Purchase Voucher'
            valueField='purchaseVoucherId'
            labelField={['purchaseVoucherName']}
            showField={['purchaseVoucherName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.purchaseVoucherName || ''
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            label='Project'
            labelField={['projectName']}
            showField={['projectName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.projectName || ''
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField name='quantity' type='number' control={control} />
        ) : (
          row?.original?.quantity || ''
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            label='Contract'
            labelField={['contractName']}
            showField={['contractName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.contractName || ''
        );
      },
    },
    {
      accessorKey: 'workOrderId',
      header: 'Work Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='workOrderId'
            control={control}
            apiUrl='workorders'
            label='Work Order'
            labelField={['workOrderName']}
            showField={['workOrderName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.workOrderName || ''
        );
      },
    },
    {
      accessorKey: 'salesOrderId',
      header: 'Sales Order',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='salesOrderId'
            control={control}
            apiUrl='salesorders'
            label='Sales Order'
            labelField={['salesOrderCode']}
            showField={['salesOrderCode']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.salesOrderCode || ''
        );
      },
    },
    {
      accessorKey: 'inCharge',
      header: 'In Charge',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='inCharge'
            control={control}
            apiUrl='employees'
            label='In Charge'
            labelField={['employeeName']}
            showField={['employeeName']}
            valueField='employeeId'
            pageSize={20}
            required
          />
        ) : (
          row?.original?.inChargeUserName || ''
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            label='Division'
            labelField={['divisionName']}
            showField={['divisionName']}
            pageSize={20}
            valueField='divisionId'
            required
          />
        ) : (
          row?.original?.divisionName || ''
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            label='Location'
            labelField={['locationName']}
            valueField='locationId'
            showField={['locationName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.locationName || ''
        );
      },
    },
    {
      accessorKey: 'costCenterId',
      header: 'Cost Center',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='costCenterId'
            control={control}
            apiUrl='costcenters'
            label='Cost Center'
            labelField={['costCenterName']}
            valueField='costCenterId'
            showField={['costCenterName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.costCenterName || ''
        );
      },
    },
    {
      accessorKey: 'itemId',
      header: 'Item',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='itemId'
            control={control}
            apiUrl='item'
            label='Item'
            labelField={['itemName']}
            showField={['itemName']}
            valueField='itemId'
            pageSize={20}
            required
          />
        ) : (
          row?.original?.itemName || ''
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='description'
            control={control}
            label='Description'
            required
          />
        ) : (
          row?.original?.description || ''
        );
      },
    },
    {
      accessorKey: 'technicalSpecs',
      header: 'Technical Specs',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='technicalSpecs'
            control={control}
            label='Technical Specs'
            required
          />
        ) : (
          row?.original?.technicalSpecs || ''
        );
      },
    },
    {
      accessorKey: 'taxCodeId',
      header: 'Tax Code',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmSearchableSelect
            name='taxCodeId'
            control={control}
            label='Tax Code'
            apiUrl='taxcodes'
            valueField='taxCodeId'
            labelField={['taxCodeName']}
            showField={['taxCodeName']}
            pageSize={20}
            required
          />
        ) : (
          row?.original?.taxCodeName || ''
        );
      },
    },
    {
      accessorKey: 'purchaseAccount',
      header: 'Purchase Account',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='purchaseAccount'
            control={control}
            label='Purchase Account'
            required
          />
        ) : (
          row?.original?.purchaseAccount || ''
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ row }) => {
        const isEditing = editingRowId === row?.original?.purchaseVoucherItemId;
        return isEditing ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline
            required
          />
        ) : (
          row?.original?.remarks || ''
        );
      },
    },
  ];

  return (
    <>
      <DashboardLayout
        title={'Purchase Voucher Items'}
        hasSubmenu
        menu={Nav[7].child[2].children}
        parentMenu={Nav[7].child[2].name}
      >
        <AddPVItems fetchData={fetchData} setLoader={setLoader} />
        <FmMRTDataTable
          columns={columns}
          rows={rows || []}
          isLoading={loader}
          rowCount={tableRecordCounts}
          editingRow={editingRowId}
          pagination={pagination}
          sorting={sorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
        <GlassCard className='p-3'>
          <Stack
            direction='row'
            flexWrap='wrap'
            spacing={3}
            justifyContent='space-between'
            divider={<Divider orientation='vertical' flexItem />}
          >
            {BillingValues?.map((item, i) => {
              return (
                <Box key={i} className='flex justify-between items-center'>
                  <Box>{item.name}</Box>
                  <Box
                    className='font-bold ms-3'
                    sx={{ color: 'success.main' }}
                  >
                    {item.value}
                  </Box>
                </Box>
              );
            })}
          </Stack>
        </GlassCard>
      </DashboardLayout>
    </>
  );
};

export default ViewPurchaseVoucherList;
