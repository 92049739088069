import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.tsx';
import CommonLoader from '../../../components/page/CommonLoader.tsx';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import { toast } from 'react-toastify';
const EditCompanyCertificates = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [certificateList, setCertificatelist] = useState([]);
  const [locations, setLocations] = useState([]);
  const { editLoading, startEditLoading, stopEditLoading } = useLoader();
  const [editData, setEditData] = useState(null);
  const defaultValues= {
    certificationName: '',
    locationId: '',
    startDate: dayjs(),
    expiryDate: dayjs(),
    lastRenewDate: dayjs(),
    reminderDays: null,
    remarks: '',
    companyId: '',
    certificateStatusId: 2,
    fileId: '',
    status: editData?.status ||'',
    projectName: editData?.projectName ||'',
    contractId: editData?.contractId || null,
    contractName:editData?.contractName ||'',
    cicpaNumber: '',
  }
  const { setValue, handleSubmit, control,reset } = useForm({
    defaultValues: {
      ...defaultValues
    },
  });

  const [certTyes, setCertTypes] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getCertificates();
    getCertificateslist();
    getLocation();
    getCertificateTypes();
    getContracts();
    getProfessions();
    getProjects();
  }, []);

  const getCertificates = async () => {
    setLoading(true);
    await crud.getSingle(`companycertifications/${id}`, '', (err, res) => {
      if (res?.status === 200) {
        const data = res.data;
        Object.entries({
          ...data,
          startDate: data.startDate ? dayjs(data.startDate) : null,
          expiryDate: data.expiryDate ? dayjs(data.expiryDate) : null,
          lastRenewDate: data.lastRenewDate ? dayjs(data.lastRenewDate) : null,
          reminderDays: data.reminderDays ? dayjs(data.reminderDays) : null,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setEditData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Something Went Wrong!');
      }
    });
  };

  const getCertificateslist = async () => {
    await crud.getAll('certifications', '', (err, res) => {
      if (res?.status === 200) {
        setCertificatelist(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };

  const getLocation = async () => {
    await crud.getAll('locations', '', (err, res) => {
      if (res?.status === 200) {
        setLocations(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };
  const getCertificateTypes = async () => {
    await crud.getAll(
      'certificatetypes?pageSize=100&pageNumber=1',
      '',
      (err, res) => {
        if (res?.status === 200) {
          setCertTypes(res?.data?.data);
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };

  const getProfessions = async () => {
    await crud.getAll(
      'professions?pageSize=100&pageNumber=1',
      '',
      (err, res) => {
        if (res?.status === 200) {
          setProfessions(res?.data?.data);
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };
  const getContracts = async () => {
    await crud.getAll('contracts?pageSize=100&pageNumber=1', '', (err, res) => {
      if (res?.status === 200) {
        setContracts(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };

  const getProjects = async () => {
    await crud.getAll('projects?pageSize=100&pageNumber=1', '', (err, res) => {
      if (res?.status === 200) {
        setProjects(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };
  const handleEditEmployeeCertificates = async (values) => {
    startEditLoading();
    const CombinedData = {
      ...values,
      companyId: Number(values?.companyId),
      certificationName: Number(values?.certificationName),
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
      lastRenewDate: dayjs(values?.lastRenewDate).format('YYYY-MM-DD'),
      reminderDays: values?.reminderDays,
      locationId: Number(values?.locationId),
      remarks: values?.remarks,
      certificateStatusId: Number(values?.certificateStatusId),
      contractId: Number(values?.contractId),
      fileId: Number(values?.fileId),
      status: Number(values?.status),
    };

    await crud.update(
      `companycertifications/${id}`,
      '',
      CombinedData,
      (err, res) => {
        if (res?.status === 200) {
          navigate(-1);
          stopEditLoading();
        } else {
          toast.error('Something Went Wrong!');
          stopEditLoading();
        }
      }
    );
  };

  return (
    <DashboardLayout title='Edit Company Certificates'>
      <GlassCard className='p-4'>
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditEmployeeCertificates)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='companyId'
                    control={control}
                    options={locations}
                    label='Locations'
                    displayField='locationName'
                    optionFields={['locationName']}
                    valueKey='companyId'
                  />
                </Grid>{' '}
                <Grid item md={3}>
                  <FmTextField
                    name='certificationName'
                    control={control}
                    label='Certificate Name'
                  />
                </Grid>
                <Grid item md={3}>
                  <FmAutoComplete
                    name='locationId'
                    control={control}
                    options={locations}
                    label='Locations'
                    displayField='locationName'
                    optionFields={['locationName']}
                    valueKey='locationId'
                  />
                </Grid>{' '}
                <Grid item md={3}>
                  <FmAutoComplete
                    name='certificationName'
                    control={control}
                    options={certTyes}
                    label='Certificate Types'
                    displayField='certificateTypeName'
                    optionFields={['certificateTypeName']}
                    valueKey='certificationName'
                  />
                </Grid>
                <Grid item md={3}>
                  <FmSearchableSelect
                    name='contractId'
                    control={control}
                    apiUrl='contracts'
                    valueField='contractId'
                    headerField={['Code', 'Name']}
                    labelField={['contractCode', 'contractName']}
                    showField={['contractName']}
                    pageSize={20}
                    label={'Contracts'}
                    defaultValue={defaultValues}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name='startDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Start Date'
                        value={value}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: {
                            variant: 'filled',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name='expiryDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Expiry Date'
                        value={value}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: {
                            variant: 'filled',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name='lastRenewDate'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Last Renew Date'
                        value={value}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: {
                            variant: 'filled',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name='reminderDays'
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label='Pass Cancelled Date'
                        value={value}
                        format='DD/MM/YYYY'
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: {
                            variant: 'filled',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <FmTextField
                    name='remarks'
                    control={control}
                    label='Remarks'
                    multiline
                    rows={3}
                    maxRows={4}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='Loa Informations'
                    name='loaInformation'
                    control={control}
                  />
                </Grid>
                <Grid item md={3}>
                  <FmTextField
                    label='CICPA Number'
                    name='cicpaNumber'
                    pattern='Number'
                    control={control}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <ActionButtons
              onSubmit={handleSubmit(handleEditEmployeeCertificates)}
              onCancel={() => navigate(-1)}
              onReset={reset}
              submitLoading={editLoading}
              cancelLoading={false}
              submitText='Update'
            />
          </form>
        )}
      </GlassCard>
    </DashboardLayout>
  );
};

export default EditCompanyCertificates;
