import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
  menus: any[];
  loaded: boolean;
  error: string | null;
}

const initialState: MenuState = {
  menus: [],
  loaded: false,
  error: null,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu(state, action: PayloadAction<any[]>) {
      state.menus = action.payload;
    },
    setLoaded(state, action: PayloadAction<boolean>) {
      state.loaded = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    resetMenuState(state) {
      state.menus = [];
      state.loaded = false;
      state.error = null;
    },
  },
});

export const { setMenu, setLoaded, setError, resetMenuState } =
  menuSlice.actions;
export default menuSlice.reducer;
