import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icons from '../../utils/Icon.tsx';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Nav from '../../utils/Nav.tsx';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../../store/tabsSlice.ts';
import { useNavigate } from 'react-router-dom';

const HeaderAppDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [activeIndex, setActiveIndex] = React.useState(
    Number(localStorage.getItem('menu')) || 0
  ); // State to track the active menu index
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const typeMap = [
    'HRMS',
    'CRM',
    'ERP',
    'PR',
    'PreSales',
    'CAFM',
    'Procurement',
    'Financial',
    'Budgeting',
    'HSE',
    'Admin',
    'Project-Division',
    'FM-Division',
    'ELV',
    'QAQC',
    'Reports',
  ];

  const setActiveMenu = (tab) => {
    setActiveIndex(tab);
    localStorage.setItem('menu', tab);
    dispatch(setActiveTab(tab));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (index) => {
    setActiveMenu(index);
    navigate(`/${typeMap[index]}/dashboard`);
  };

  const MuiMenuItem = ({ name, icon, index, ...props }) => {
    return (
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          props.setActiveMenu(index);
        }}
        sx={{
          color: index === activeIndex ? 'primary.main' : 'text.main',
          fontSize: 'inherit',
          py: 0,
          fontWeight: 500,
          borderRadius: '0px',
          gap: '10px',
          px: 1,
          bgcolor: index === activeIndex ? 'background.mainLight' : 'inherit',
          '&:hover': {
            bgcolor: 'background.mainLight',
          },
        }}
        {...props}
      >
        {icon && (
          <Box
            sx={{
              fontSize: '20px',
              width: '25px',
              height: '25px',
              borderRadius: '5px',
              border: '1px solid',
              borderColor: 'primary.main',
              color: 'primary.main',
            }}
            className='flex justify-center items-center'
          >
            {icon}
          </Box>
        )}
        <Box>
          <Box className='uppercase font-bold'>{name}</Box>
        </Box>
      </MenuItem>
    );
  };

  return (
    <Box className='sm:hidden'>
      <Button
        variant='text'
        sx={{
          textAlign: 'left',
          py: '2px',
          px: '5px',
          lineHeight: 1.2,
          color: 'inherit',
          borderRadius: open ? '5px 5px 0px 0px' : '0px',
          fontWeight: 700,
          minWidth: '200px',
          justifyContent: 'space-between',
          border: '1px solid',
          borderColor: open ? 'border.main' : 'transparent',
          bgcolor: !open ? 'background.white' : 'background.mainLight',
          color: open && 'primary.main',
          transition: '0.5s all',
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            width: 30,
            height: 30,
            borderRadius: '5px',
          }}
          className='flex flex-row items-center justify-center me-1 text-xl'
        >
          {Icons.apps}
        </Box>
        <Box className='flex-1 flex items-center'>
          <Box className='uppercase'>{typeMap[activeIndex]}</Box>
          <Box className='ms-2 text-xl opacity-60'>
            {open ? Icons.arrowUp : Icons.arrow}
          </Box>
        </Box>
      </Button>

      <Menu
        id={'app-dropdown'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            width: '200px',
            borderRadius: open ? '0px 0px 5px 5px' : '0px',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            border: '1px solid',
            borderColor: 'border.main',
            borderTop: 'none',
          },
        }}
        MenuListProps={{
          sx: {
            padding: '0px',
            '& li': {
              borderRadius: '0px',
              py: 0.8,
              gap: '15px',
            },
          },
        }}
      >
        {Nav?.map(
          (menu, i) =>
            menu.name && (
              <MuiMenuItem
                index={i}
                setActiveMenu={handleMenuClick}
                key={i}
                icon={menu.icon}
                name={`${menu.name}`}
              />
            )
        )}
      </Menu>
    </Box>
  );
};

export default HeaderAppDropdown;
