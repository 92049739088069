import { useState, useEffect } from 'react';
import Crud_Service from '../../apis/CrudService.tsx';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../store/loaderSlice.ts';
import { toast } from 'react-toastify';

const useTableLogic = (endpoint, customIdKey = '', CustomId = '', dataVar = 'data') => {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sorting, setSorting] = useState([]);
  const [tableRecordCounts, setTableRecordCounts] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const isLoading = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();
  const crud = new Crud_Service();

  const toggleLoading = (loading) => {
    dispatch(setLoading(loading));
  };

  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination?.pageIndex,
    pagination?.pageSize,
    sorting,
    searchKeyword,
  ]);

  const fetchData = async () => {
    toggleLoading(true);

    const sortBy = sorting?.length ? sorting[0].id : '';
    const sortDirection = sorting?.length
      ? sorting[0].desc
        ? 'desc'
        : 'asc'
      : '';

    const res = await crud.getAll(
      endpoint,
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageIndex + 1,
        searchKeyword: searchKeyword,
        filters: columnFilters,
        globalFilter,
        sortby: sortBy,
        sortDirection: sortDirection,
        [customIdKey]: customIdKey ? CustomId : '',
      },
      (err, res) => {
        if (err) {
          toast.error(`Error fetching data from ${endpoint}:`, err);
          return;
        }
        if (res?.status === 200) {
          setTableRecordCounts(res?.data?.total);
          const transformedData = res?.data[dataVar]?.map((row, index) => ({
            ...row,
            id: index + 1,
          }));
          toggleLoading(false);
          setRows(transformedData);
        } else if (res?.status === 204) {
          setTableRecordCounts(0);
          setRows([]);
          toggleLoading(false);
        } else {
          setRows([]);
          toggleLoading(false);
          toast.error(`Error fetching data from ${endpoint}:`, err);
        }
      }
    );
  };

  return {
    rows,
    setRows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  };
};

export default useTableLogic;
