import { lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { SettingsProvider } from './utils/SettingsContext.tsx';
import runOneSignal from './utils/OneSignal.tsx';
import ExampleWithProviders from './pages/Presales/sample/ExampleDataGrid.tsx';
import SuppliersSpoc from './pages/Presales/Suppliers/SupplierSpoc.tsx';
import TenderEnquiryTypes from './pages/Presales/AllMasters/TenderEnquiryTypes.tsx';
import TenderProjectTypes from './pages/Presales/AllMasters/TenderProjectTypes.tsx';
import TenderFormats from './pages/Presales/AllMasters/TenderFormat.tsx';
import CountryMaster from './pages/Presales/AllMasters/Country.tsx';
import SupplierTypes from './pages/Presales/AllMasters/SupplierTypes.tsx';
import TenderFollowUpTypesMasters from './pages/Presales/AllMasters/TenderFollowUpTypes.tsx';
import TenderStatusMasters from './pages/Presales/AllMasters/TenderStatus.tsx';
import ContactModesMasters from './pages/Presales/AllMasters/ContactModes.tsx';
import CompanyPolicies from './pages/Pr/AllMasters/CompanyPolicies.tsx';
import CompanyPolicyTypes from './pages/Pr/AllMasters/CompanyPolicyTypes.tsx';
import EndUsers from './pages/Pr/AllMasters/EndUsers.tsx';
import TypeOfProducts from './pages/Pr/AllMasters/TypeOfProducts.tsx';
import MedicalInsuranceStatus from './pages/Pr/AllMasters/MedicalInsuranceStatus.tsx';
import MedicalInsuranceAgeBands from './pages/Pr/AllMasters/MedicalInsuranceAgeBands.tsx';
import MedicalInsurancePolicies from './pages/Pr/AllMasters/CompanyInsurancePolicies.tsx';
import SupplierItems from './pages/Procurement/Masters/SupplierItems.tsx';
import PremiseLocations from './pages/Pr/AllMasters/PremiseLocations.tsx';
import PolicyPremium from './pages/Pr/AllMasters/PolicyPremium.tsx';
import MedicalInsuranceSalaryBands from './pages/Pr/AllMasters/MedicalInsuranceSalaryBand.tsx';
import CompanyCertificate from './pages/Hrms/EmployeeMaster/CompanyCertificate.tsx';
import CreateCompanyCertificates from './pages/Hrms/EmployeeMaster/CreateCompanyCertificate.tsx';
import MedicalInsuranceDependencies from './pages/Pr/AllMasters/MedicalInsuranceDependencies.tsx';
import MedicalInsurancePremisePurpose from './pages/Pr/AllMasters/MedicalInsurancePremisePurpose.tsx';
import MedicalInsurancePremiseTypes from './pages/Pr/AllMasters/MedicalInsurancePremiseTypes.tsx';
import UniformSizes from './pages/Pr/AllMasters/UniformSizes.tsx';
import UniformTypes from './pages/Pr/AllMasters/UniformTypes.tsx';
import Insurer from './pages/Pr/AllMasters/Insurer.tsx';
import StoreLocations from './pages/Cafm/inventory/storeLocations/StoreLocations.tsx';
import EditCompanyCertificates from './pages/Hrms/EmployeeMaster/EditCompanyCertificate.tsx';
import Brand from './pages/Procurement/Masters/Brands.tsx';
import TaxCodeMasters from './pages/Procurement/Masters/TaxCode.tsx';
import UnitOfMeasurement from './pages/Procurement/Masters/UnitOfMeasurement.tsx';
import ItemTypesMasters from './pages/Procurement/Masters/ItemTypes.tsx';
import ValuationMethod from './pages/Procurement/Masters/ValuationMethod.tsx';
import ItemGroup from './pages/Procurement/Masters/ItemGroup.tsx';
import GroupTypes from './pages/Procurement/Masters/GroupTypes.tsx';
import MaterialRequisitionType from './pages/Procurement/Masters/MaterialRequisitionType.tsx';
import MaterialRequisitionStatus from './pages/Procurement/Masters/MaterialrequisitionStatus.tsx';
import PaymentTerms from './pages/Procurement/Masters/PaymentTerms.tsx';
import Currencies from './pages/Procurement/Masters/Currencies.tsx';
import LocalPurchaseOrderStatus from './pages/Procurement/Masters/LocalPurchaseOrderStatus.tsx';
import OwnVehicles from './pages/Admin/Vehicles/OwnVehicles.tsx';
import VehicleCategory from './pages/Admin/AllMasters/VehicleCategory.tsx';
import VehicleBrands from './pages/Admin/AllMasters/VehicleBrands.tsx';
import VehicleStatuses from './pages/Admin/AllMasters/VehicleSatuses.tsx';
import Drivers from './pages/Admin/AllMasters/Drivers.tsx';
import VehicleInsurance from './pages/Admin/AllMasters/VehicleInsurance.tsx';
import Vehicles from './pages/Admin/AllMasters/Vehicles.tsx';
import VehicleSuppliers from './pages/Admin/AllMasters/VehicleSuppliers.tsx';
import MonthUpdate from './pages/Admin/Vehicles/MonthUpdate.tsx';
import Asateel from './pages/Admin/Vehicles/Asateel.tsx';
import Advertisement from './pages/Admin/Vehicles/Advertisement.tsx';
import VehicleAdvertisement from './pages/Admin/AllMasters/VehicleAdvertisement.tsx';
import TrafficSource from './pages/Admin/AllMasters/TrafficSource.tsx';
import SupplierDocuments from './pages/Presales/Suppliers/SupplierDocuments.tsx';
import Uniforms from './pages/Admin/uniforms/uniforms.tsx';
import UniformReturn from './pages/Admin/uniformReturn/UniformReturn.tsx';
import Category from './pages/Procurement/Masters/Category.tsx';
import Customers from './pages/QAC/Masters/Customers.tsx';
import Areas from './pages/QAC/Masters/Areas.tsx';
import SubAreas from './pages/QAC/Masters/SubAreas.tsx';
import HiredVechicles from './pages/Admin/Vehicles/hiredVechicles/Hiredvechicles.tsx';
import SalesRetentionItem from './pages/Financial/AccountsPayable/SalesRetention/view/SalesRetentionItem.tsx';
import ViewPurchaseVoucherList from './pages/Financial/AccountsPayable/PurchaseVoucher/View/ViewPurchaseVoucherList.tsx';

const Login = lazy(() => import('./pages/Login/index.tsx'));
const ForgotPassword = lazy(() => import('./pages/Login/ForgotPassword.tsx'));
const ResetPassword = lazy(() => import('./pages/Login/ResetPassword.tsx'));

const Dashboard = lazy(() => import('./pages/Dashboard/index.tsx'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/index.tsx'));

const UnderConstruction = lazy(
  () => import('./pages/ErrorPage/UnderConstruction.tsx')
);
const EmployeeCreate = lazy(
  () => import('./pages/Hrms/EmployeeMaster/Create/Create.tsx')
);
const FinalSettlement = lazy(
  () => import('./pages/Hrms/FinalSettlements/FinalSettlement.tsx')
);
const LeaveTransaction = lazy(
  () => import('./pages/Hrms/EmployeeMaster/LeaveTransaction.tsx')
);
const LeaveJoin = lazy(
  () => import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoin.tsx')
);
const EmpAllMasters = lazy(() => import('./pages/Hrms/AllMasters/index.tsx'));
const DepartmentMasters = lazy(
  () => import('./pages/Hrms/AllMasters/DepartmentMasters.tsx')
);
const CreateTrainingAndEvents = lazy(
  () => import('./pages/Hrms/TrainingAndEvents/TrainingAndEventsCreate.tsx')
);
const AttendanceCalculations = lazy(
  () => import('./pages/Hrms/EmployeeMaster/AttendanceCalculations.tsx')
);
const ShiftTimeMaster = lazy(
  () => import('./pages/Hrms/ShiftTimeMaster/ShiftTime.tsx')
);
const AttendanceSummary = lazy(
  () => import('./pages/Hrms/EmployeeMaster/AttendanceSummary.tsx')
);
const ExpenseTypePr = lazy(
  () => import('./pages/Pr/AllMasters/ExpenseType.tsx')
);
const TrainingAndEvents = lazy(
  () => import('./pages/Hrms/TrainingAndEvents/TrainingAndEvents.tsx')
);
const Certificates = lazy(
  () => import('./pages/Pr/certificates/Certificates.tsx')
);
const DocumentManagement = lazy(
  () => import('./pages/Hrms/DocumentManagement/DocumentManagement.tsx')
);
const HRMSEmployeeMaster = lazy(
  () => import('./pages/Hrms/EmployeeMaster/HRMSEmployeeMaster.tsx')
);
const LeaveRequest = lazy(
  () => import('./pages/Hrms/EmployeeMaster/LeaveRequest/LeaveRequest.tsx')
);
const EmployementCancellation = lazy(
  () =>
    import('./pages/Hrms/EmployementCancellation/EmployementCancellation.tsx')
);
const CashAccountMgmt = lazy(
  () => import('./pages/Pr/CashAccountManagement/CashAccountMgmt.tsx')
);
const TransactionManagement = lazy(
  () => import('./pages/Pr/TransactionManagement/TransactionManagement.tsx')
);
const RegionMasters = lazy(
  () => import('./pages/Hrms/AllMasters/RegionMasters.tsx')
);
const EmployeeStudies = lazy(() => import('./pages/Hrms/EmployeeStudies.tsx'));
const DesignationMasters = lazy(
  () => import('./pages/Hrms/AllMasters/DesignationMasters.tsx')
);
const LocationMasters = lazy(
  () => import('./pages/Hrms/AllMasters/LocationMasters.tsx')
);
const NationalityMasters = lazy(
  () => import('./pages/Hrms/AllMasters/NationalityMasters.tsx')
);
const ContractTypeMasters = lazy(
  () => import('./pages/Hrms/AllMasters/ContractTypeMasters.tsx')
);
const BloodGroupMasters = lazy(
  () => import('./pages/Hrms/AllMasters/BloodGroupMasters.tsx')
);
const CertificateTypeMasters = lazy(
  () => import('./pages/Hrms/AllMasters/CertificateTypeMasters.tsx')
);
const CancellationTypeMasters = lazy(
  () => import('./pages/Hrms/AllMasters/CancellationTypeMasters.tsx')
);
const DocumentTypeMasters = lazy(
  () => import('./pages/Hrms/AllMasters/DocumentTypeMasters.tsx')
);
const TrainingTransaction = lazy(
  () => import('./pages/Hrms/TraningAndTranaction/TrainingTransaction.tsx')
);
const VehicleMaster = lazy(
  () => import('./pages/Pr/VehicleMaster/VehicleMaster.tsx')
);
const Enquiry = lazy(
  () => import('./pages/Presales/EnquiryMaster/Enquiry.tsx')
);
const Candidates = lazy(() => import('./pages/Pr/Candidates/Candidates.tsx'));
const JobRequisition = lazy(
  () => import('./pages/Hrms/JobRequisition/JobRequisition.tsx')
);
const JobRecruitments = lazy(
  () => import('./pages/Hrms/JobRecruitments/JobRecruitments.tsx')
);
const CreatePassportRequest = lazy(
  () => import('./pages/Hrms/PassportRequest/PassportRequestCreate.tsx')
);
const CreateTraningAndTransaction = lazy(
  () => import('./pages/Hrms/TraningAndTranaction/TraningTransactionCreate.tsx')
);
const EditTraningAndTransaction = lazy(
  () => import('./pages/Hrms/TraningAndTranaction/TraningTransactionEdit.tsx')
);
const EditPassportRequest = lazy(
  () => import('./pages/Hrms/PassportRequest/PassportRequestEdit.tsx')
);
const PassportRequest = lazy(
  () => import('./pages/Hrms/PassportRequest/PassportRequest.tsx')
);
const CreateCertificates = lazy(
  () => import('./pages/Pr/certificates/CertificatesCreate.tsx')
);
const EditCertificates = lazy(
  () => import('./pages/Pr/certificates/CertificatesEdit.tsx')
);
const CreateCandidates = lazy(
  () => import('./pages/Pr/Candidates/CandidateCreate.tsx')
);
const EditCandidates = lazy(
  () => import('./pages/Pr/Candidates/CandidateEdit.tsx')
);
const CreateVehicleMaster = lazy(
  () => import('./pages/Pr/VehicleMaster/VehicleMasterCreate.tsx')
);
const EditVehicleMaster = lazy(
  () => import('./pages/Pr/VehicleMaster/VehicleMasterEdit.tsx')
);
const EssLogin = lazy(() => import('./pages/Login/EssLogin.tsx'));
const EssDashboard = lazy(() => import('./pages/Dashboard/EssDashboard.tsx'));
const CreateJobRequisition = lazy(
  () => import('./pages/Hrms/JobRequisition/JobRequisitionCreate.tsx')
);
const EditJobRequisition = lazy(
  () => import('./pages/Hrms/JobRequisition/JobRequisitionEdit.tsx')
);
const CreateJobRecruitment = lazy(
  () => import('./pages/Hrms/JobRecruitments/JobReCruitmentsCreate.tsx')
);
const EditJobRecruitment = lazy(
  () => import('./pages/Hrms/JobRecruitments/JobReCruitmentsEdit.tsx')
);
const SettingsForm = lazy(() => import('./utils/SettingsForm.tsx'));
const CreateFinalSettlement = lazy(
  () => import('./pages/Hrms/FinalSettlements/FinalSettlementCreate.tsx')
);
const EditFinalSettlement = lazy(
  () => import('./pages/Hrms/FinalSettlements/FinalSettlementEdit.tsx')
);
const WpsMasters = lazy(() => import('./pages/Hrms/AllMasters/WpsMasters.tsx'));
const EditTrainingAndEvents = lazy(
  () => import('./pages/Hrms/TrainingAndEvents/TrainingAndEventsEdit.tsx')
);
const CreateEnquiry = lazy(
  () => import('./pages/Presales/EnquiryMaster/CreateEnquiry.tsx')
);
const EditEnquiry = lazy(
  () => import('./pages/Presales/EnquiryMaster/EditEnquiry.tsx')
);
const CreateLeaveRequest = lazy(
  () =>
    import('./pages/Hrms/EmployeeMaster/LeaveRequest/CreateLeaveRequest.tsx')
);
const EditLeaveRequest = lazy(
  () => import('./pages/Hrms/EmployeeMaster/LeaveRequest/EditLeaveRequest.tsx')
);
const PassportAndVisa = lazy(
  () => import('./pages/Pr/PassportAndVisa/PassportAndVisa.tsx')
);
const DocumentsList = lazy(
  () => import('./pages/Fm/GeneralDocuments/DocumentsList.tsx')
);
const CreateDocuments = lazy(
  () => import('./pages/Fm/GeneralDocuments/CreateDocuments.tsx')
);
const PositionsMaster = lazy(
  () => import('./pages/Hrms/AllMasters/PositionsMaster.tsx')
);
const LeaveJoinCreate = lazy(
  () => import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoinCreate.tsx')
);
const CreateCashAccountManagement = lazy(
  () => import('./pages/Pr/CashAccountManagement/CreateCashAccount.tsx')
);
const EditCashAccountManagement = lazy(
  () => import('./pages/Pr/CashAccountManagement/EditCashAccount.tsx')
);
const CompanyMasters = lazy(
  () => import('./pages/Hrms/AllMasters/CompanyMasters.tsx')
);
const DepartmentShifts = lazy(
  () => import('./pages/Hrms/DepartmentShifts/DepatmentShifts.tsx')
);
const CreateEmpCancellation = lazy(
  () => import('./pages/Hrms/EmployementCancellation/CreateEmpCancellation.tsx')
);
const EditEmpCancellation = lazy(
  () => import('./pages/Hrms/EmployementCancellation/EditEmpCancellation.tsx')
);
const ApprovalNode = lazy(
  () => import('./pages/Fm/ApprovalNode/ApprovalNode.tsx')
);
const HolidaysList = lazy(() => import('./pages/Fm/Holidays/HolidaysList.tsx'));
const CreateHolidays = lazy(
  () => import('./pages/Fm/Holidays/CreateHolidays.tsx')
);
const LeaveJoinEdit = lazy(
  () => import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoinEdit.tsx')
);
const EditHolidays = lazy(() => import('./pages/Fm/Holidays/EditHoliday.tsx'));
const TenderEnquiry = lazy(
  () => import('./pages/Presales/TenderEnquiry/TenderEnquiry.tsx')
);
const CreateTenderEnquiry = lazy(
  () => import('./pages/Presales/TenderEnquiry/CreateTenderEnquiry.tsx')
);
const EditTenderEnquiry = lazy(
  () => import('./pages/Presales/TenderEnquiry/EditTenderEnquiry.tsx')
);
const DocumentTypeCategoryMasters = lazy(
  () => import('./pages/Hrms/AllMasters/DocumentTypeCategoryMasters.tsx')
);
const ExperienceTypeMasters = lazy(
  () => import('./pages/Hrms/AllMasters/ExperienceTypeMasters.tsx')
);
const TenderInspectionChecklist = lazy(
  () =>
    import(
      './pages/Presales/TenderInspectionChecklist/TenderInspectionChecklist.tsx'
    )
);
const CreateTenderInspectionChecklist = lazy(
  () =>
    import(
      './pages/Presales/TenderInspectionChecklist/CreateTenderInspectionChecklist.tsx'
    )
);
const CreateEstimationSheetForAMC = lazy(
  () =>
    import(
      './pages/Presales/EstimationSheetForAMC/CreateEstimationSheetForAMC.tsx'
    )
);
const EstimationSheetForAMC = lazy(
  () =>
    import('./pages/Presales/EstimationSheetForAMC/EstimationSheetForAMC.tsx')
);
const EditEstimationSheet = lazy(
  () => import('./pages/Presales/EstimationSheetForAMC/EditEstimationSheet.tsx')
);
const HRMSEmployeeMasterDashboard = lazy(
  () => import('./pages/Hrms/EmployeeMaster/HRMSEmployeeMasterDashboard.tsx')
);
const CalendarBooking = lazy(
  () => import('./pages/Fm/CalendarBooking/CalendarBooking.tsx')
);
const CardTypes = lazy(() => import('./pages/Pr/AllMasters/CardTypes.tsx'));
const ExampleComponent = lazy(() => import('./ExampleComponent.tsx'));
const SettingsForms = lazy(() => import('./utils/SettingsForm.js'));
const CreatePassportAndVisa = lazy(
  () => import('./pages/Pr/PassportAndVisa/CreatePassportAndVisa.tsx')
);
const EditPassportAndVisa = lazy(
  () => import('./pages/Pr/PassportAndVisa/EditPassportAndVisa.tsx')
);
const CreateContracts = lazy(
  () => import('./pages/Presales/Contract/CreateContracts.tsx')
);
const ManageContracts = lazy(
  () => import('./pages/Presales/Contract/ManageContracts.tsx')
);
const EditContracts = lazy(
  () => import('./pages/Presales/Contract/EditContracts.tsx')
);

const ViewContracts = lazy(
  () => import('./pages/Presales/Contract/ViewContract/ViewContracts.tsx')
);

const Suppliers = lazy(
  () => import('./pages/Presales/Suppliers/Suppliers.tsx')
);
const CreateSuppliers = lazy(
  () => import('./pages/Presales/Suppliers/CreateSuppliers.tsx')
);
const EditSuppliers = lazy(
  () => import('./pages/Presales/Suppliers/EditSuppliers.tsx')
);
const CafmTicket = lazy(() => import('./pages/Cafm/Ticket/CafmTicket.tsx'));
const CreateTicket = lazy(() => import('./pages/Cafm/Ticket/CreateTicket.tsx'));
const EmployeeUpdate = lazy(
  () => import('./pages/Hrms/EmployeeMaster/EmployeeUpdate/EmployeeUpdate.tsx')
);
const Clients = lazy(() => import('./pages/Presales/Clients/Clients.tsx'));
const Createclient = lazy(
  () => import('./pages/Presales/Clients/CreateClients.tsx')
);
const EditClients = lazy(
  () => import('./pages/Presales/Clients/EditClients.tsx')
);
const MaterialRequestList = lazy(
  () => import('./pages/Procurement/MaterialRequest/MaterialRequestList.tsx')
);
const CreateMaterialRequest = lazy(
  () => import('./pages/Procurement/MaterialRequest/CreateMaterialRequest.tsx')
);
const ViewMaterialRequest = lazy(
  () => import('./pages/Procurement/MaterialRequest/ViewMaterialRequest.tsx')
);
const Projects = lazy(() => import('./pages/Presales/Projects/Projects.tsx'));
const CreateProjects = lazy(
  () => import('./pages/Presales/Projects/CreateProjects.tsx')
);

const ViewProjects = lazy(
  () => import('./pages/Presales/Projects/ViewProjects/ViewProjects.tsx')
);

const EditProjects = lazy(
  () => import('./pages/Presales/Projects/EditProjects.tsx')
);
const TemplateList = lazy(
  () => import('./pages/QuickLinks/Template/TemplateList.tsx')
);
const CreateTemplate = lazy(
  () => import('./pages/QuickLinks/Template/CreateTemplate.tsx')
);
const ViewEnquiry = lazy(
  () => import('./pages/Presales/EnquiryMaster/ViewEnquiry.tsx')
);
const EstimationSheetForMEP = lazy(
  () => import('./pages/Presales/EstimationSheetForMEP/EstimationSheetsMEP.tsx')
);
const CreateEstimationSheetForMEP = lazy(
  () => import('./pages/Presales/EstimationSheetForMEP/CreateEstimationMEP.tsx')
);
const EditEstimationSheetMEP = lazy(
  () => import('./pages/Presales/EstimationSheetForMEP/EditEstimationMEP.tsx')
);
const ViewEstimationMEP = lazy(
  () => import('./pages/Presales/EstimationSheetForMEP/ViewEstimationMEP.tsx')
);
const EditTenderInspectionChecklist = lazy(
  () =>
    import(
      './pages/Presales/TenderInspectionChecklist/EditTenderInspectionChecklist.tsx'
    )
);
const ViewTenderCheckListDetail = lazy(
  () =>
    import(
      './pages/Presales/TenderInspectionChecklist/ViewTenderChecklistDetail.tsx'
    )
);
const ViewSuppliers = lazy(
  () => import('./pages/Presales/Suppliers/ViewSuppliers.tsx')
);
const QuestionsBuilderList = lazy(
  () => import('./pages/QuickLinks/QuestionsBuilder/QuestionsBuilderList.tsx')
);
const CreateQuestionsBuilder = lazy(
  () => import('./pages/QuickLinks/QuestionsBuilder/CreateQuestionsBuilder.tsx')
);
const ProposalList = lazy(
  () => import('./pages/Presales/ProposalLineItemsMaster/List.tsx')
);
const ProposalCreate = lazy(
  () => import('./pages/Presales/ProposalLineItemsMaster/Create.tsx')
);
const ProposalView = lazy(
  () => import('./pages/Presales/ProposalLineItemsMaster/View.tsx')
);
const Building = lazy(() => import('./pages/Procurement/Masters/Building.tsx'));
const Levels = lazy(() => import('./pages/Procurement/Masters/Levels.tsx'));
const LevelUnits = lazy(
  () => import('./pages/Procurement/Masters/LevelUnits.tsx')
);
const CostCenters = lazy(
  () => import('./pages/Procurement/Masters/CostCenters.tsx')
);
const ItemManagementList = lazy(
  () => import('./pages/Procurement/ItemManagement/ItemManagementList.tsx')
);
/////Request Quote MAnagement
const RequestQuoteManagement = lazy(
  () =>
    import(
      './pages/Procurement/RequestQuoteManagement/RequestQuoteManagement.tsx'
    )
);
const CreateRequestQuote = lazy(
  () =>
    import('./pages/Procurement/RequestQuoteManagement/CreateQuoteRequest.tsx')
);
/////
const PurchaseOrderViewList = lazy(
  () =>
    import('./pages/Procurement/PurchaseOrderView/PurchaseOrderViewList.tsx')
);
const CreatePurchaseOrderView = lazy(
  () =>
    import('./pages/Procurement/PurchaseOrderView/CreatePurchaseOrderView.tsx')
);
const PurchaseOrderPreview = lazy(
  () => import('./pages/Procurement/PurchaseOrderView/PurchaseOrderPreview.tsx')
);
const GoodsReceivedNoteList = lazy(
  () =>
    import('./pages/Procurement/GoodsReceivedNote/GoodsReceivedNoteList.tsx')
);
const PayGroupMaster = lazy(
  () => import('./pages/Hrms/AllMasters/PayGroupMasters.tsx')
);
const TinyMCEEditor = lazy(() => import('./pages/TextEditor.tsx'));
const CostCenterMasters = lazy(
  () => import('./pages/Hrms/AllMasters/CostCenterMasters.tsx')
);
const PurchaseOrderViewSendLPO = lazy(
  () =>
    import('./pages/Procurement/PurchaseOrderView/PurchaseOrderViewSendLPO.tsx')
);
const LeaveConfiguration = lazy(
  () => import('./pages/Hrms/LeaveConfiguration.tsx')
);
const QuotaEmployeesList = lazy(
  () => import('./pages/Pr/QuotaEmployeesSummary/QuotaEmployeesList.tsx')
);
const ManpowerRateCardList = lazy(
  () => import('./pages/Hrms/ManpowerRatecard/ManpowerRateCardList.tsx')
);
const PRDashboard = lazy(() => import('./pages/Dashboard/PRDashboard.tsx'));
const CreateTransactionManagement = lazy(
  () =>
    import('./pages/Pr/TransactionManagement/CreateTransactionManagement.tsx')
);
const EditTransactionManagement = lazy(
  () => import('./pages/Pr/TransactionManagement/EditTransactionManagement.tsx')
);
const ViewTransactionManagement = lazy(
  () => import('./pages/Pr/TransactionManagement/ViewTransactionManagement.tsx')
);
const InterviewAssessmentForm = lazy(
  () => import('./pages/Pr/Candidates/InterviewAssessmentForm.tsx')
);
const CandidateConfirmation = lazy(
  () => import('./pages/Pr/Candidates/CandidateConfirmation.tsx')
);
const EmployeeSalaryCalculations = lazy(
  () => import('./pages/Hrms/EmployeeMaster/EmployeeSalaryCalculations.tsx')
);
const DocumentCollection = lazy(
  () => import('./pages/Pr/Candidates/DocumentCollection.tsx')
);

const GoodsReceivedNotesCreate = lazy(
  () =>
    import('./pages/Procurement/GoodsReceivedNote/GoodsReceivedNotesCreate.tsx')
);

const ProjectDivisionList = lazy(
  () => import('./pages/Cafm/ProjectDivision/ProjectDivsion.tsx')
);

const ManageAssigneeList = lazy(
  () =>
    import('./pages/Cafm/ProjectDivision/ManageAssignee/ManageAssigneeList.tsx')
);

const ProjectPlanList = lazy(
  () => import('./pages/Cafm/ProjectDivision/ProjectPlan/ProjectPlanList.tsx')
);

const ProjectActivityList = lazy(
  () =>
    import(
      './pages/Cafm/ProjectDivision/ProjectActivity/ProjectActivityList.tsx'
    )
);

const BoqHeadMasterList = lazy(
  () => import('./pages/Cafm/AllMaster/BoqHeadMasterList.tsx')
);

const BoqPackageMaster = lazy(
  () => import('./pages/Cafm/AllMaster/BoqPackageMaster.tsx')
);

const BoqSubHeadMaster = lazy(
  () => import('./pages/Cafm/AllMaster/BoqSubHead/BoqSubHeadMaster.tsx')
);

const BoqLineItemMaster = lazy(
  () => import('./pages/Cafm/AllMaster/BoqLineItemMaster.tsx')
);

const ProjectMainCost = lazy(
  () =>
    import(
      './pages/Cafm/ProjectDivision/ProjectCostTracking/ProjectMainCost/ProjectMainCost.tsx'
    )
);

const BudgetProjectCostTracking = lazy(
  () =>
    import(
      './pages/Cafm/ProjectDivision/ProjectCostTracking/Budget/BudgetProjectCostTracking.tsx'
    )
);

const ManpowerProjectCostTracking = lazy(
  () =>
    import(
      './pages/Cafm/ProjectDivision/ProjectCostTracking/Manpower/ManpowerProjectCostTracking.tsx'
    )
);

const PettyCashProjectCostTracking = lazy(
  () =>
    import(
      './pages/Cafm/ProjectDivision/ProjectCostTracking/Pettycash/PettyCashProjectCostTracking.tsx'
    )
);
//inventory
const InventoryList = lazy(
  () => import('./pages/Cafm/inventory/InventoryList.tsx')
);
const InventoryView = lazy(
  () => import('./pages/Cafm/inventory/InventoryView.tsx')
);
const AddInventory = lazy(
  () => import('./pages/Cafm/inventory/AddInventory.tsx')
);
const AssetHistory = lazy(
  () => import('./pages/Cafm/AssetHistory/AssetHistoryList.tsx')
);
const AssetHistoryView = lazy(
  () => import('./pages/Cafm/AssetHistory/View/AssetHistoryView.tsx')
);

const CompanyInsurancePolicyList = lazy(
  () =>
    import(
      './pages/Admin/CompanyInsurancePolicy/CompanyInsurancePolicyList.tsx'
    )
);

const ComparisonPolicy = lazy(
  () => import('./pages/Admin/CompanyInsurancePolicy/ComparisonPolicy.tsx')
);

const CompanyMedicalInsurancePolicy = lazy(
  () =>
    import(
      './pages/Admin/CompanyMedicalInsurancePolicy/CompanyMedicalInsurancePolicy.tsx'
    )
);

const CreateCompanyMedicalInsurance = lazy(
  () =>
    import(
      './pages/Admin/CompanyMedicalInsurancePolicy/CreateCompanyMedicalInsurance.tsx'
    )
);

const MIPPremiumCalculator = lazy(
  () =>
    import(
      './pages/Admin/CompanyMedicalInsurancePolicy/MIPPremiumCalculator.tsx'
    )
);

const EmployeeWiseMIPCalculator = lazy(
  () =>
    import(
      './pages/Admin/CompanyMedicalInsurancePolicy/EmployeeWiseMIPCalculator.tsx'
    )
);

const AccommodationList = lazy(
  () => import('./pages/Admin/Accommodation/AccommodationList.tsx')
);

const UniformRequistionList = lazy(
  () => import('./pages/Admin/UniformAccessories/UniformRequistionList.tsx')
);

const EventsCalender = lazy(
  () => import('./pages/Admin/EventsCalendar/EventsCalendar.tsx')
);

const ViewEventsCalendar = lazy(
  () => import('./pages/Admin/EventsCalendar/View/ViewEventsCalendar.tsx')
);

const PurchaseVoucher = lazy(
  () =>
    import(
      './pages/Financial/AccountsPayable/PurchaseVoucher/PurchaseVoucher.tsx'
    )
);

const SalesOrder = lazy(
  () => import('./pages/Financial/AccountsPayable/SalesOrder/SalesOrder.tsx')
);

const SalesRetention = lazy(
  () =>
    import(
      './pages/Financial/AccountsPayable/SalesRetention/SalesRetention.tsx'
    )
);

const InspectionReport = lazy(
  () => import('./pages/Cafm/InspectionReport/InspectionReport.tsx')
);

const ViewInspectionReport = lazy(
  () => import('./pages/Cafm/InspectionReport/View/ViewInspectionReport.tsx')
);

const IncidentReport = lazy(
  () => import('./pages/Cafm/IncidentReport/IncidentReport.tsx')
);

const ViewIncident = lazy(
  () => import('./pages/Cafm/IncidentReport/View/ViewIncident.tsx')
);

const ViewIncidentRemarks = lazy(
  () => import('./pages/Cafm/IncidentReport/View/ViewRemarks.tsx')
);

const ViewInjuries = lazy(
  () => import('./pages/Cafm/IncidentReport/View/ViewInjuries.tsx')
);

const SnagReport = lazy(() => import('./pages/Cafm/SnagReport/SnagReport.tsx'));

const SnagTab = lazy(() => import('./pages/Cafm/SnagReport/View/SnagTab.tsx'));

const CreateWorkOrder = lazy(
  () =>
    import('./pages/Cafm/WorkOrderMaster/CreateWorkOrder/CreateWorkOrder.tsx')
);

const CreateSalesQuotation = lazy(
  () =>
    import(
      './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/CreateSalesQuotation.tsx'
    )
);

const ViewCreateSalesQuotation = lazy(
  () =>
    import(
      './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/View/ViewCreateSalesQuotation.tsx'
    )
);

const CreateSalesOrder = lazy(
  () =>
    import('./pages/Cafm/WorkOrderMaster/CreateSalesOrder/CreateSalesOrder.tsx')
);

const CreateCheckInForm = lazy(
  () =>
    import('./pages/Cafm/SnagReport/CreateCheckInForm/CreateCheckInForm.tsx')
);

const ViewCreateCheckInForm = lazy(
  () =>
    import(
      './pages/Cafm/SnagReport/CreateCheckOutForm/View/ViewCreateCheckInForm.tsx'
    )
);

const CreateCheckoutView = lazy(
  () =>
    import(
      './pages/Cafm/SnagReport/CreateCheckInForm/View/CreateCheckOutView.tsx'
    )
);

const SalesorderDistribution = lazy(
  () =>
    import('./pages/Presales/SalesOrderDistribution/SalesOrderDistribution.tsx')
);

const ViewSalesOrderDistribution = lazy(
  () =>
    import(
      './pages/Presales/SalesOrderDistribution/View/ViewSalesOrderDistribution.tsx'
    )
);

const VendorLPOReceivable = lazy(
  () => import('./pages/Financial/VendorLPOReceivable/VendorLPOReceivable.tsx')
);

const ViewVendorLPOReceivable = lazy(
  () =>
    import(
      './pages/Financial/VendorLPOReceivable/View/ViewVendorLPOReceivable.tsx'
    )
);

const CashBankReceipts = lazy(
  () => import('./pages/Financial/CashAndBank/Receipts/CashBankReceipts.tsx')
);

const ViewCashBankReceipt = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/Receipts/View/ViewCashBankReceipt.tsx'
    )
);

const CashBankPayments = lazy(
  () => import('./pages/Financial/CashAndBank/Payments/CashBankPayments.tsx')
);

const ViewCBPayments = lazy(
  () => import('./pages/Financial/CashAndBank/Payments/View/ViewCBPayments.tsx')
);

const PaymentPendingBills = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/Payments/PendingBills/PaymentsPendingBills.tsx'
    )
);

const PettyCash = lazy(
  () => import('./pages/Financial/CashAndBank/PettyCash/PettyCash.tsx')
);

const ViewPettyCash = lazy(
  () => import('./pages/Financial/CashAndBank/PettyCash/View/ViewPettyCash.tsx')
);

const PostDatedReceipts = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PostDatedReceipts/PostDatedReceipts.tsx'
    )
);

const ViewPostDateReceipts = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PostDatedReceipts/View/ViewPostDatedReceipts.tsx'
    )
);

const PendingBillsPostDatedReceipts = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PostDatedReceipts/PendingBills/PendingBillsPostDatedReceipts.tsx'
    )
);

const PostDatedPayments = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PostDatedPayments/PostDatedPayments.tsx'
    )
);

const PendingBillsPDCPayments = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PendingBillsPDCPayments/PendingBillsPDCPayments.tsx'
    )
);

const PettyCashProjects = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PettyCashProjects/PettyCashProjects.tsx'
    )
);

const ViewPettyCashProjects = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PettyCashProjects/View/ViewPettyCashProjects.tsx'
    )
);

const MaturedPDCPV = lazy(
  () => import('./pages/Financial/CashAndBank/MaturedPDCPV/MaturedPDCPV.tsx')
);

const ViewMaturedPDCPV = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/MaturedPDCPV/View/ViewMaturedPDCPV.tsx'
    )
);

const PendingBillsMaturedPDCPV = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/MaturedPDCPV/PendingBills/PendingBillsMaturedPDCPV.tsx'
    )
);

const PettyCashProjectsView = lazy(
  () =>
    import(
      './pages/Financial/CashAndBank/PettyCashProjectsView/PettyCashProjectsView.tsx'
    )
);

const FATrialBalance = lazy(
  () =>
    import('./pages/Financial/FinalAccounts/TrialBalance/FATrialBalance.tsx')
);

const ProfitAndLoss = lazy(
  () =>
    import('./pages/Financial/FinalAccounts/ProfitAndLoss/ProfitAndLoss.tsx')
);

const TradingAccount = lazy(
  () =>
    import('./pages/Financial/FinalAccounts/TradingAccount/TradingAccount.tsx')
);

const TradingAndProfitAndLoss = lazy(
  () =>
    import(
      './pages/Financial/FinalAccounts/TradingAndProfitAndLoss/TradingAndProfitAndLoss.tsx'
    )
);

const BalanceSheet = lazy(
  () => import('./pages/Financial/FinalAccounts/BalanceSheet/BalanceSheet.tsx')
);

const FASchedules = lazy(
  () => import('./pages/Financial/FinalAccounts/FASchedules/FASchedules.tsx')
);

const FundsFlow = lazy(
  () => import('./pages/Financial/FinalAccounts/FundsFlow/FundsFlow.tsx')
);

const CashFlow = lazy(
  () => import('./pages/Financial/FinalAccounts/CashFlow/CashFlow.tsx')
);
const CashFlowAnalysis = lazy(
  () =>
    import(
      './pages/Financial/FinalAccounts/CashFlowAnalysis/CashFlowAnalysis.tsx'
    )
);

const AdvanceCashFlow = lazy(
  () =>
    import(
      './pages/Financial/FinalAccounts/AdvanceCashFlow/AdvanceCashFlow.tsx'
    )
);

const IncomeExpenseTrends = lazy(
  () =>
    import(
      './pages/Financial/FinalAccounts/IncomeExpenseTrends/IncomeExpenseTrends.tsx'
    )
);

const ReceivablePayableReport = lazy(
  () =>
    import('./pages/Financial/FinalAccounts/ReceivablePayableReport/index.tsx')
);

const CashFlowCalenderIndex = lazy(
  () => import('./pages/Financial/FinalAccounts/CashFlowCalender/index.tsx')
);

const BalanceSheetReports = lazy(
  () => import('./pages/Reports/BalanceSheetReports/BalanceSheetReports.tsx')
);

const SAPAccountsPayableOpenItemsReport = lazy(
  () =>
    import(
      './pages/Reports/SAPAccountsPayableOpenItemsReport/SAPAccountsPayableOpenItemsReport.tsx'
    )
);

const AccountsPayableReconciliation = lazy(
  () =>
    import(
      './pages/Reports/AccountsPayableReconciliation/AccountsPayableReconciliation.tsx'
    )
);

const AccountsPayableTrialBalance = lazy(
  () =>
    import(
      './pages/Reports/AccountsPayableTrialBalance/AccountsPayableTrialBalance.tsx'
    )
);

const FinancialStatementOfAccounts = lazy(
  () =>
    import(
      './pages/Reports/FinancialStatementOfAccounts/FinancialStatementOfAccounts.tsx'
    )
);

const TrialBalanceReport = lazy(
  () => import('./pages/Reports/TrialBalanceReport/TrialBalanceReport.tsx')
);

const FixedAssetsDepreciationReport = lazy(
  () =>
    import(
      './pages/Reports/FixedAssetsDepreciationReport/FixedAssetsDepreciationReport.tsx'
    )
);

const AccountsReceivableReconciliation = lazy(
  () =>
    import(
      './pages/Reports/AccountsReceivableReconciliation/AccountsReceivableReconciliation.tsx'
    )
);

const BankReconciliationStatement = lazy(
  () =>
    import(
      './pages/Reports/BankReconciliationStatement/BankReconciliationStatement.tsx'
    )
);

const CashTrialBalance = lazy(
  () => import('./pages/Reports/CashTrialBalance/CashTrialBalance.tsx')
);

const CostAuditReport = lazy(
  () => import('./pages/Reports/CostAuditReport/CostAuditReport.tsx')
);

const CostCenterReport = lazy(
  () => import('./pages/Reports/CostCenterReport/CostCenterReport.tsx')
);

const AccomBlock = lazy(
  () => import('./pages/Admin/Accommodation/AccomBlock/AccomBlock.tsx')
);

const AccomRoom = lazy(
  () => import('./pages/Admin/Accommodation/AccomRoom/AccomRoom.tsx')
);

const AccomRoomCapacity = lazy(
  () =>
    import(
      './pages/Admin/Accommodation/AccomRoomCapacity/AccomRoomCapacity.tsx'
    )
);

const AccomCamps = lazy(
  () => import('./pages/Admin/Accommodation/AccomCamps/AccomCamps.tsx')
);

const AccomSummary = lazy(
  () => import('./pages/Admin/Accommodation/AccomSummary/AccomSummary.tsx')
);

const VehicleTypes = lazy(
  () => import('./pages/Admin/AllMasters/VehicleTypes.tsx')
);

const QACModule = lazy(() => import('./pages/QAC/QACModule/QACModule.tsx'));

const CustomerSatisfaction = lazy(
  () => import('./pages/QAC/CustomerSatisfaction/CustomerSatisfaction.tsx')
);

const ViewCustomerSatisfaction = lazy(
  () =>
    import('./pages/QAC/CustomerSatisfaction/View/ViewCustomerSatisfaction.tsx')
);

const CustomerSatisfactionReport = lazy(
  () =>
    import(
      './pages/QAC/CustomerSatisfactionReport/CustomerSatisfactionReport.tsx'
    )
);

const QuestionsTemplate = lazy(
  () => import('./pages/QuickLinks/QuestionsTemplate/QuestionsTemplate.tsx')
);

const CreateQuestionsTemplate = lazy(
  () =>
    import(
      './pages/QuickLinks/QuestionsTemplate/Create/CreateQuestionsTemplate.tsx'
    )
);

const ViewQACModule = lazy(
  () => import('./pages/QAC/QACModule/View/ViewQACModule.tsx')
);

const ComparisonRequestQuoteManagement = lazy(
  () =>
    import(
      './pages/Procurement/RequestQuoteManagement/Comparison/ComparisonRequestQuoteManagement.tsx'
    )
);

const HseCommittee = lazy(() =>
  import('./pages/Hse/HseCommittee/HseCommittee.tsx')
);

const ViewHseCommittee = lazy(() =>
  import('./pages/Hse/HseCommittee/View/ViewHseCommittee.tsx')
);

const PPEIssuedReport = lazy(() =>
  import('./pages/Hse/PPEIssuedReport/PPEIssuedReport.tsx')
);

const SafetyInduction = lazy(() =>
  import('./pages/Hse/SafetyInduction/SafetyInduction.tsx')
);

const SafetyViolationSlip = lazy(() =>
  import('./pages/Hse/SafetyViolationSlip/SafetyViolationSlip.tsx')
);

const ViewSafetyViolationSlip = lazy(() =>
  import('./pages/Hse/SafetyViolationSlip/View/ViewSafetyViolationSlip.tsx')
);

const App = () => {
  useEffect(() => {
    runOneSignal();
  });

  return (
    <>
      <SettingsProvider>
        <Router>
          <Routes>
            <Route path='/DataGrid-inline' element={<ExampleWithProviders />} />
            <Route path='*' element={<UnderConstruction />} />
            <Route path='/' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='user-master' element={<SettingsForm />} />
            <Route path='SettingsForms' element={<SettingsForms />} />
            <Route path='ExampleComponent' element={<ExampleComponent />} />
            <Route path='text-editor' element={<TinyMCEEditor />} />
            {/* ------------------FM-------------------- */}
            <Route path='fm'>
              <Route
                index
                element={<Navigate replace to='/fm/general-documents' />}
              />
              <Route path='general-documents'>
                <Route index element={<DocumentsList />} />
                <Route path='create' element={<CreateDocuments />} />
              </Route>
              <Route path='approval-node' element={<ApprovalNode />} />
              <Route path='holidays'>
                <Route index element={<HolidaysList />} />
                <Route path='create' element={<CreateHolidays />} />
                <Route path='edit' element={<EditHolidays />} />
              </Route>
              <Route path='calendar-booking' element={<CalendarBooking />} />
              <Route path='template'>
                <Route index element={<TemplateList />} />
                <Route path='create' element={<CreateTemplate />} />
              </Route>
              <Route path='questions'>
                <Route index element={<QuestionsBuilderList />} />
                <Route path='create' element={<CreateQuestionsBuilder />} />
              </Route>
              <Route path='questions-template'>
                <Route index element={<QuestionsTemplate />} />
                <Route path='create' element={<CreateQuestionsTemplate />} />
              </Route>
            </Route>
            {/* -----------------------HRMS------------------------- */}
            <Route path='hrms'>
              <Route
                index
                element={<Navigate replace to='/hrms/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='company-certificate'>
                <Route index element={<CompanyCertificate />} />
                <Route path='create' element={<CreateCompanyCertificates />} />
                <Route path='edit/:id' element={<EditCompanyCertificates />} />
              </Route>
              <Route path='employee'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/employee/dashboard' />}
                />
                <Route
                  path='dashboard'
                  element={<HRMSEmployeeMasterDashboard />}
                />
                <Route path='master-list'>
                  <Route index element={<HRMSEmployeeMaster />} />
                  <Route path='create' element={<EmployeeCreate />} />
                  <Route path='edit/:id' element={<EmployeeUpdate />} />
                </Route>
                <Route path='attendance' element={<AttendanceCalculations />} />
                <Route path='leave-request'>
                  <Route index element={<LeaveRequest />} />
                  <Route path='create' element={<CreateLeaveRequest />} />
                  <Route path='edit/:id' element={<EditLeaveRequest />} />
                </Route>
                <Route
                  path='leave-transaction'
                  element={<LeaveTransaction />}
                />

                <Route path='leave-join'>
                  <Route index element={<LeaveJoin />} />
                  <Route path='create' element={<LeaveJoinCreate />} />
                  <Route path='edit/:id' element={<LeaveJoinEdit />} />
                </Route>
                <Route path='passport-request'>
                  <Route index element={<PassportRequest type={'HRMS'} />} />
                  <Route path='create' element={<CreatePassportRequest />} />
                  <Route path='edit/:id' element={<EditPassportRequest />} />
                </Route>

                <Route path='cancellation'>
                  <Route index element={<EmployementCancellation />} />
                  <Route path='create' element={<CreateEmpCancellation />} />
                  <Route path='edit/:id' element={<EditEmpCancellation />} />
                </Route>
                <Route path='final-settlement'>
                  <Route index element={<FinalSettlement />} />
                  <Route path='create' element={<CreateFinalSettlement />} />
                  <Route path='edit/:id' element={<EditFinalSettlement />} />
                </Route>
                <Route path='employee-studies' element={<EmployeeStudies />} />
                <Route path='training-transaction'>
                  <Route
                    index
                    element={<TrainingTransaction type={'HRMS'} />}
                  />
                  <Route
                    path='create'
                    element={<CreateTraningAndTransaction />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditTraningAndTransaction />}
                  />
                </Route>
                <Route path='certificates'>
                  <Route index element={<Certificates type={'HRMS'} />} />
                  <Route path='create' element={<CreateCertificates />} />
                  <Route path='edit/:id' element={<EditCertificates />} />
                </Route>
                <Route path='candidates'>
                  <Route index element={<Candidates type={'HRMS'} />} />
                  <Route
                    path='create'
                    element={<CreateCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCandidates type={'HRMS'} />}
                  />
                </Route>
                <Route path='candidates' element={<Candidates type={1} />} />
                <Route
                  path='employee-salary-calculations'
                  element={<EmployeeSalaryCalculations />}
                />

                <Route
                  path='attendance-summary'
                  element={<AttendanceSummary />}
                />
              </Route>
              <Route path='job-requisition'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/job-requisition/list' />}
                />
                <Route path='list' element={<JobRequisition type={'HRMS'} />} />
                <Route path='list/create' element={<CreateJobRequisition />} />
                <Route path='list/edit/:id' element={<EditJobRequisition />} />
                <Route path='jobrecruitments'>
                  <Route index element={<JobRecruitments />} />
                  <Route path='create' element={<CreateJobRecruitment />} />
                  <Route path='edit/:id' element={<EditJobRecruitment />} />
                </Route>
                <Route path='candidates'>
                  <Route index element={<Candidates type={'HRMS'} />} />
                  <Route
                    path='create'
                    element={<CreateCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='document-collection'
                    element={<DocumentCollection type={'HRMS'} />}
                  />
                  <Route
                    path='interview-assessment'
                    element={<InterviewAssessmentForm />}
                  />
                  <Route
                    path='candidate-confirmation'
                    element={<CandidateConfirmation />}
                  />
                </Route>
              </Route>

              <Route path='training-event'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/training-event/list' />}
                />
                <Route
                  path='list'
                  element={<TrainingAndEvents type={'HRMS'} />}
                />
                <Route
                  path='list/create'
                  element={<CreateTrainingAndEvents />}
                />
                <Route
                  path='list/edit/:id'
                  element={<EditTrainingAndEvents />}
                />
              </Route>

              <Route path='masters'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/masters/department' />}
                />
                <Route index element={<EmpAllMasters />} />
                <Route path='department' element={<DepartmentMasters />} />
                <Route path='designation' element={<DesignationMasters />} />
                <Route path='wps' element={<WpsMasters />} />
                <Route path='region' element={<RegionMasters />} />
                <Route path='location' element={<LocationMasters />} />
                <Route path='nationality' element={<NationalityMasters />} />
                <Route
                  path='experience-type'
                  element={<ExperienceTypeMasters />}
                />
                <Route path='contract-type' element={<ContractTypeMasters />} />
                <Route path='blood-group' element={<BloodGroupMasters />} />
                <Route
                  path='certificate-type'
                  element={<CertificateTypeMasters />}
                />
                <Route path='document-type' element={<DocumentTypeMasters />} />
                <Route
                  path='document-type-category'
                  element={<DocumentTypeCategoryMasters />}
                />

                <Route
                  path='cancellation-type'
                  element={<CancellationTypeMasters />}
                />
                <Route path='positions' element={<PositionsMaster />} />
                <Route path='companies' element={<CompanyMasters />} />
                <Route path='pay-groups' element={<PayGroupMaster />} />
                <Route path='cost-centers' element={<CostCenterMasters />} />
              </Route>

              <Route path='shift-time-master' element={<ShiftTimeMaster />} />
              <Route path='department-shifts' element={<DepartmentShifts />} />

              <Route path='dashboard' element={<Dashboard />} />
              <Route path='employee'>
                <Route
                  index
                  element={<Navigate replace to='/pr/employee/list' />}
                />
              </Route>

              <Route
                path='approval-workflow'
                element={<HRMSEmployeeMaster />}
              />
              <Route path='letter-management' />
              <Route
                path='leave-configuration'
                element={<LeaveConfiguration />}
              />
              <Route
                path='manpower-ratecard'
                element={<ManpowerRateCardList />}
              />
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList type={process.env.REACT_APP_ENUM_HRMS} />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments type={process.env.REACT_APP_ENUM_HRMS} />
                  }
                />
              </Route>
            </Route>
            {/* ----------------------PR---------------------------- */}
            <Route>
              <Route path='pr'>
                <Route
                  index
                  element={<Navigate replace to='/pr/dashboard' />}
                />
                <Route path='dashboard' element={<PRDashboard />} />
                <Route path='employee'>
                  <Route
                    index
                    element={<Navigate replace to='/pr/employee/list' />}
                  />
                  <Route
                    path='list'
                    element={<HRMSEmployeeMaster type={'PR'} />}
                  />
                  <Route path='list/create' element={<EmployeeCreate />} />
                  <Route path='list/edit/:id' element={<EmployeeUpdate />} />
                  <Route path='passport-request'>
                    <Route index element={<PassportRequest type={'PR'} />} />
                    <Route path='create' element={<CreatePassportRequest />} />
                    <Route path='edit/:id' element={<EditPassportRequest />} />
                  </Route>
                  <Route path='certificates'>
                    <Route index element={<Certificates type={'PR'} />} />
                    <Route path='create' element={<CreateCertificates />} />
                    <Route path='edit/:id' element={<EditCertificates />} />
                  </Route>
                  <Route path='candidates'>
                    <Route index element={<Candidates type={'PR'} />} />
                    <Route path='create' element={<CreateCandidates />} />
                    <Route path='edit/:id' element={<EditCandidates />} />
                    <Route
                      path='document-collection'
                      element={<DocumentCollection type={'PR'} />}
                    />
                    <Route
                      path='interview-assessment'
                      element={<InterviewAssessmentForm />}
                    />
                    <Route
                      path='candidate-confirmation'
                      element={<CandidateConfirmation />}
                    />
                  </Route>
                  <Route path='passport-visa'>
                    <Route index element={<PassportAndVisa />} />
                    <Route path='create' element={<CreatePassportAndVisa />} />
                    <Route path='edit/:id' element={<EditPassportAndVisa />} />
                  </Route>
                  <Route path='training-transaction'>
                    <Route
                      index
                      element={
                        <Navigate replace to='/pr/training-transaction/list' />
                      }
                    />
                    <Route
                      path='list'
                      element={<TrainingTransaction type={'PR'} />}
                    />
                    <Route
                      path='create'
                      element={<CreateTraningAndTransaction />}
                    />
                    <Route
                      path='edit/:id'
                      element={<EditTraningAndTransaction />}
                    />
                  </Route>
                </Route>
                <Route path='masters'>
                  <Route
                    index
                    element={<Navigate replace to='/pr/masters/card-types' />}
                  />
                  <Route path='card-types' element={<CardTypes />} />
                  <Route path='expense-type' element={<ExpenseTypePr />} />
                  <Route path='policies' element={<CompanyPolicies />} />
                  <Route path='policy-type' element={<CompanyPolicyTypes />} />
                  <Route path='end-user' element={<EndUsers />} />
                  <Route path='type-of-product' element={<TypeOfProducts />} />
                  <Route
                    path='premise-location-name'
                    element={<PremiseLocations />}
                  />
                  <Route
                    path='premise-location'
                    element={<PremiseLocation />}
                  />
                  <Route
                    path='medical-insurance-status'
                    element={<MedicalInsuranceStatus />}
                  />
                  <Route
                    path='medical-insurance-age-band'
                    element={<MedicalInsuranceAgeBands />}
                  />
                  <Route
                    path='medical-insurance-salary-band'
                    element={<MedicalInsuranceSalaryBands />}
                  />
                  <Route
                    path='medical-insurance-policy'
                    element={<MedicalInsurancePolicies />}
                  />
                  <Route path='policy-premiums' element={<PolicyPremium />} />
                  <Route
                    path='medical-insurance-dependencies'
                    element={<MedicalInsuranceDependencies />}
                  />
                  <Route
                    path='medical-insurance-premise-purpose'
                    element={<MedicalInsurancePremisePurpose />}
                  />
                  <Route
                    path='medical-insurance-premise-types'
                    element={<MedicalInsurancePremiseTypes />}
                  />
                  <Route path='uniform-sizes' element={<UniformSizes />} />
                  <Route path='uniform-types' element={<UniformTypes />} />
                  <Route path='insurer' element={<Insurer />} />
                </Route>
                <Route path='training-and-event'>
                  <Route
                    index
                    element={
                      <Navigate replace to='/pr/training-and-event/list' />
                    }
                  />
                  <Route
                    path='list'
                    element={<TrainingAndEvents type={'PR'} />}
                  />
                  <Route
                    path='list/create'
                    element={<CreateTrainingAndEvents />}
                  />
                  <Route
                    path='list/edit/:id'
                    element={<EditTrainingAndEvents />}
                  />
                </Route>
                <Route path='vechicle-master'>
                  <Route index element={<VehicleMaster />} />
                  <Route path='create' element={<CreateVehicleMaster />} />
                  <Route path='edit/:id' element={<EditVehicleMaster />} />
                </Route>
                {/* <Route path='document-management'>
                  <Route index element={<DocumentManagement type={'PR'} />} />
                </Route> */}
                <Route path='quota-employees-summary'>
                  <Route index element={<QuotaEmployeesList />} />
                </Route>
                <Route path='cash-account-management'>
                  <Route
                    index
                    element={
                      <Navigate replace to='/pr/cash-account-management/list' />
                    }
                  />
                  <Route path='list' element={<CashAccountMgmt />} />
                  <Route
                    path='list/create'
                    element={<CreateCashAccountManagement />}
                  />
                  <Route
                    path='list/edit/:id'
                    element={<EditCashAccountManagement />}
                  />
                </Route>
                <Route path='transaction'>
                  <Route index element={<TransactionManagement />} />
                  <Route
                    path='create'
                    element={<CreateTransactionManagement />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditTransactionManagement />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewTransactionManagement />}
                  />
                </Route>
                <Route path='document-management'>
                  <Route
                    index
                    element={
                      <DocumentsList type={process.env.REACT_APP_ENUM_PR} />
                    }
                  />
                  <Route
                    path='create'
                    element={
                      <CreateDocuments type={process.env.REACT_APP_ENUM_PR} />
                    }
                  />
                </Route>
              </Route>
            </Route>
            {/* --------------------PreSales-------------------- */}
            <Route path='presales'>
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route path='edit/:id' element={<EditEnquiry />} />
              </Route>
              <Route path='manage-contracts'>
                <Route index element={<ManageContracts />} />
                <Route path='create' element={<CreateContracts />} />
                <Route path='edit/:id' element={<EditContracts />} />
                <Route path='view/:id' element={<ViewContracts />} />
              </Route>

              <Route path='clients'>
                <Route index element={<Clients />} />
                <Route path='create' element={<Createclient />} />
                <Route path='edit/:id' element={<EditClients />} />
              </Route>

              <Route path='suppliers'>
                <Route index element={<Suppliers />} />
                <Route path='create' element={<CreateSuppliers />} />
                <Route path='view/:id' element={<ViewSuppliers />} />
                <Route path='edit/:id' element={<EditSuppliers />} />
                <Route path='add-contacts/:id' element={<SuppliersSpoc />} />
                <Route
                  path='add-documents/:id'
                  element={<SupplierDocuments />}
                />
              </Route>
              <Route path='projects'>
                <Route index element={<Projects />} />
                <Route path='create' element={<CreateProjects />} />
                <Route path='view/:id' element={<ViewProjects />} />
                <Route path='edit/:id' element={<EditProjects />} />
              </Route>
              <Route path='tender-lead-enquiry'>
                <Route index element={<TenderEnquiry />} />
                <Route path='create' element={<CreateTenderEnquiry />} />
                <Route path='edit/:id' element={<EditTenderEnquiry />} />
              </Route>
              <Route path='tender-inspection-checklist'>
                <Route index element={<TenderInspectionChecklist />} />
                <Route
                  path='create'
                  element={<CreateTenderInspectionChecklist />}
                />
                <Route
                  path='view/:id'
                  element={<ViewTenderCheckListDetail />}
                />
                <Route
                  path='edit/:id'
                  element={<EditTenderInspectionChecklist />}
                />
              </Route>
              <Route path='estimation-sheet-amc'>
                <Route index element={<EstimationSheetForAMC />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForAMC />}
                />
                <Route path='edit/:id' element={<EditEstimationSheet />} />
              </Route>
              <Route path='estimation-sheet-mep'>
                <Route index element={<EstimationSheetForMEP />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForMEP />}
                />
                <Route path='view/:id' element={<ViewEstimationMEP />} />
                <Route path='edit/:id' element={<EditEstimationSheetMEP />} />
              </Route>
              <Route path='CRUDEnquiry' />
              <Route
                path='manage-document'
                element={<DocumentManagement type={3} />}
              />
              <Route path='BidbondNotification' />
              <Route path='proposal'>
                <Route index element={<ProposalList />} />
                <Route path='create' element={<ProposalCreate />} />
                <Route
                  path='view/:id'
                  element={<ProposalView type={'view'} />}
                />
                <Route
                  path='edit/:id'
                  element={<ProposalView type={'edit'} />}
                />
              </Route>
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList type={process.env.REACT_APP_ENUM_PRESALES} />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments
                      type={process.env.REACT_APP_ENUM_PRESALES}
                    />
                  }
                />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/presales/masters/tender-enquiry-types'
                    />
                  }
                />
                <Route
                  path='tender-enquiry-types'
                  element={<TenderEnquiryTypes />}
                />
                <Route
                  path='tender-project-types'
                  element={<TenderProjectTypes />}
                />
                <Route path='tender-formats' element={<TenderFormats />} />
                <Route path='countries' element={<CountryMaster />} />
                <Route path='supplier-types' element={<SupplierTypes />} />
                <Route
                  path='tender-followup-types'
                  element={<TenderFollowUpTypesMasters />}
                />
                <Route path='tender-status' element={<TenderStatusMasters />} />
                <Route path='contact-modes' element={<ContactModesMasters />} />
                <Route
                  path='regions'
                  element={<RegionMasters type={'presales'} />}
                />
                <Route
                  path='buildings'
                  element={<Building type={'presales'} />}
                />
                <Route
                  path='locations'
                  element={<LocationMasters type={'presales'} />}
                />
                <Route
                  path='departments'
                  element={<DepartmentMasters type={'presales'} />}
                />
              </Route>
              <Route path='sales-order-distribution'>
                <Route index element={<SalesorderDistribution />} />
                <Route
                  path='view/:id'
                  element={<ViewSalesOrderDistribution />}
                />
              </Route>
            </Route>
            {/* --------------------Cafm-------------------- */}
            <Route path='cafm'>
              <Route
                index
                element={<Navigate replace to='/cafm/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='tickets'>
                <Route index element={<CafmTicket />} />
                <Route path='create' element={<CreateTicket />} />
              </Route>

              <Route path='inventory-management'>
                <Route index element={<InventoryList />} />
                <Route path='create' element={<AddInventory />} />
                <Route path='edit/:id' element={<AddInventory />} />
                <Route path='view/:id' element={<InventoryView />} />
              </Route>

              <Route path='asset-history'>
                <Route index element={<AssetHistory />} />
                <Route path='view' element={<AssetHistoryView />} />
              </Route>
            </Route>
            {/* --------------------Procurement-------------------- */}
            <Route path='procurement'>
              <Route
                index
                element={<Navigate replace to='/procurement/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='material-request'>
                <Route index element={<MaterialRequestList />} />
                <Route path='create' element={<CreateMaterialRequest />} />
                <Route path='edit/:id' element={<CreateMaterialRequest />} />
                <Route path='view/:id' element={<ViewMaterialRequest />} />
              </Route>
              <Route path='inventory-management'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/procurement/inventory-management/store-locations'
                    />
                  }
                />

                <Route path='store-locations'>
                  <Route index element={<StoreLocations />} />
                </Route>
                <Route path='inventory-items'>
                  <Route index element={<InventoryList />} />
                  <Route path='create' element={<AddInventory />} />
                  <Route path='edit/:id' element={<AddInventory />} />
                  <Route path='view/:id' element={<InventoryView />} />
                </Route>
              </Route>
              <Route path='suppliers'>
                <Route index element={<Suppliers />} />
                <Route path='create' element={<CreateSuppliers />} />
                <Route path='view/:id' element={<ViewSuppliers />} />
                <Route path='edit/:id' element={<EditSuppliers />} />
                <Route path='add-contacts/:id' element={<SuppliersSpoc />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate replace to='/procurement/masters/building' />
                  }
                />
                <Route path='building' element={<Building />} />
                <Route path='levels' element={<Levels />} />
                <Route path='levelUnits' element={<LevelUnits />} />
                <Route path='costCenters' element={<CostCenters />} />
                <Route path='supplier-items' element={<SupplierItems />} />
                <Route path='brand' element={<Brand />} />
                <Route path='tax-code' element={<TaxCodeMasters />} />
                <Route
                  path='unit-of-measurement'
                  element={<UnitOfMeasurement />}
                />
                <Route path='item-types' element={<ItemTypesMasters />} />
                <Route path='valuation-method' element={<ValuationMethod />} />
                <Route path='item-group' element={<ItemGroup />} />
                <Route path='group-types' element={<GroupTypes />} />
                <Route
                  path='companies'
                  element={<CompanyMasters type={'procurement'} />}
                />
                <Route
                  path='regions'
                  element={<RegionMasters type={'procurement'} />}
                />
                <Route
                  path='department'
                  element={<DepartmentMasters type={'procurement'} />}
                />
                <Route
                  path='location'
                  element={<LocationMasters type={'procurement'} />}
                />
                <Route
                  path='material-requisition-types'
                  element={<MaterialRequisitionType />}
                />
                <Route
                  path='material-requisition-status'
                  element={<MaterialRequisitionStatus />}
                />
                <Route path='payment-terms' element={<PaymentTerms />} />
                <Route path='currencies' element={<Currencies />} />
                <Route
                  path='local-purchase-order-status'
                  element={<LocalPurchaseOrderStatus />}
                />
                <Route path='category' element={<Category />} />
              </Route>
              <Route path='item-management' element={<ItemManagementList />} />
              <Route path='purchase-order-view'>
                <Route index element={<PurchaseOrderViewList />} />
                <Route path='create' element={<CreatePurchaseOrderView />} />
                <Route path='edit/:id' element={<CreatePurchaseOrderView />} />
                <Route path='preview/:id' element={<PurchaseOrderPreview />} />
                <Route path='send/:id' element={<PurchaseOrderViewSendLPO />} />
              </Route>
              <Route path='request-for-quote-management'>
                <Route index element={<RequestQuoteManagement />} />
                <Route path='create' element={<CreateRequestQuote />} />
                <Route path='edit/:id' element={<CreateRequestQuote />} />
                <Route
                  path='request-for-quote-management-comparison/:id'
                  element={<ComparisonRequestQuoteManagement />}
                />
                {/* <Route path='preview/:id' element={<PurchaseOrderPreview />} />
                <Route path='send' element={<PurchaseOrderViewSendLPO />} /> */}
              </Route>
              <Route path='goods-received-note'>
                <Route index element={<GoodsReceivedNoteList />} />
                <Route path='create' element={<GoodsReceivedNotesCreate />} />
                <Route path='edit/:id' element={<GoodsReceivedNotesCreate />} />
              </Route>
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList
                      type={process.env.REACT_APP_ENUM_PROCUREMENT}
                    />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments
                      type={process.env.REACT_APP_ENUM_PROCUREMENT}
                    />
                  }
                />
              </Route>
            </Route>
            {/*------------------Budgeting------------------*/}
            <Route path='/Budgeting/dashboard'>
              <Route index element={<UnderConstruction />} />
            </Route>
            {/*------------------Budgeting------------------*/}
            {/*------------------Financial------------------*/}
            <Route path='/financial'>
              <Route
                index
                element={<Navigate replace to='/financial/dashboard' />}
              />
              <Route path='dashboard' element={<UnderConstruction />} />
              <Route path='accounts-payable'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/accounts-payable/purchase-voucher'}
                    />
                  }
                />
                <Route path='purchase-voucher'>
                  <Route index element={<PurchaseVoucher type={1} />} />
                  <Route
                    path='edit/:id'
                    element={<ViewPurchaseVoucherList type={1} />}
                  />
                </Route>
                <Route path='purchase-return-voucher'>
                  <Route index element={<PurchaseVoucher type={2} />} />
                  <Route
                    path='edit/:id'
                    element={<ViewPurchaseVoucherList type={2} />}
                  />
                </Route>
                <Route path='direct-purchase-order'>
                  <Route index element={<PurchaseVoucher type={3} />} />
                  <Route
                    path='edit/:id'
                    element={<ViewPurchaseVoucherList type={3} />}
                  />
                </Route>
              </Route>
              <Route path='accounts-receivable'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/accounts-receivable/sales-order'}
                    />
                  }
                />
                <Route path='sales-order'>
                  <Route index element={<CreateSalesOrder type={2} />} />
                  <Route
                    path='view/:id'
                    element={<ViewCreateSalesQuotation type={2} />}
                  />
                </Route>
                <Route path='sales-return'>
                  <Route index element={<CreateSalesOrder type={3} />} />
                  <Route
                    path='view/:id'
                    element={<ViewCreateSalesQuotation type={3} />}
                  />
                </Route>
                <Route path='sales-retention'>
                  <Route index element={<SalesRetention />} />
                  <Route path='view/:id' element={<SalesRetentionItem />} />
                </Route>
              </Route>
              <Route path='vendor-lpo-receivable'>
                <Route index element={<VendorLPOReceivable />} />
                <Route path='view' element={<ViewVendorLPOReceivable />} />
              </Route>
              <Route path='cash-and-bank'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/cash-and-bank/receipts'}
                    />
                  }
                />
                <Route path='receipts'>
                  <Route index element={<CashBankReceipts type={1} />} />
                  <Route path='view/:id' element={<ViewCashBankReceipt />} />
                </Route>
                <Route path='payments'>
                  <Route index element={<CashBankReceipts type={2} />} />
                  <Route path='view/:id' element={<ViewCashBankReceipt />} />
                </Route>
                <Route
                  path='pending-bills-payments'
                  element={<PaymentPendingBills />}
                />
                <Route path='petty-cash'>
                  <Route index element={<CashBankReceipts type={3} />} />
                  <Route path='view/:id' element={<ViewCashBankReceipt />} />
                </Route>
                <Route path='post-dated-receipts'>
                  <Route index element={<PostDatedReceipts />} />
                  <Route path='view' element={<ViewPostDateReceipts />} />
                </Route>
                <Route
                  path='pending-bills-post-dated-receipts'
                  element={<PendingBillsPostDatedReceipts />}
                />
                <Route
                  path='post-dated-payments'
                  element={<PostDatedPayments />}
                />
                <Route
                  path='pending-bills-pdc-payments'
                  element={<PendingBillsPDCPayments />}
                />
                <Route path='petty-cash-projects'>
                  <Route index element={<PettyCashProjects />} />
                  <Route path='view' element={<ViewPettyCashProjects />} />
                </Route>
                <Route path='matured-pdc-pv'>
                  <Route index element={<MaturedPDCPV />} />
                  <Route path='view' element={<ViewMaturedPDCPV />} />
                </Route>
                <Route
                  path='pending-bills-matured-pdc-pv'
                  element={<PendingBillsMaturedPDCPV />}
                />
                <Route
                  path='petty-cash-projects-view'
                  element={<PettyCashProjectsView />}
                />
              </Route>
              <Route path='final-accounts'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/final-accounts/trial-balance'}
                    />
                  }
                />
                <Route path='trial-balance' element={<FATrialBalance />} />
                <Route path='profit-and-loss' element={<ProfitAndLoss />} />
                <Route path='trading-account' element={<TradingAccount />} />
                <Route
                  path='trading-and-profit-and-loss'
                  element={<TradingAndProfitAndLoss />}
                />
                <Route path='balance-sheet' element={<BalanceSheet />} />
                <Route
                  path='final-account-schedules'
                  element={<FASchedules />}
                />
                <Route path='funds-flow' element={<FundsFlow />} />
                <Route path='cash-flow' element={<CashFlow />} />
                <Route
                  path='cash-flow-analysis'
                  element={<CashFlowAnalysis />}
                />
                <Route path='advance-cash-flow' element={<AdvanceCashFlow />} />
                <Route
                  path='income-expense-trends'
                  element={<IncomeExpenseTrends />}
                />
                <Route
                  path='receivable-payable-report'
                  element={<ReceivablePayableReport />}
                />
                <Route
                  path='cash-flow-calender'
                  element={<CashFlowCalenderIndex />}
                />
              </Route>
            </Route>
            {/*------------------Financial------------------*/}
            {/*------------------HSE------------------*/}
            <Route path='/hse'>
              <Route index element={<Navigate replace to='/hse/dashboard' />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='hse-committee'>
                <Route index element={<HseCommittee />} />
                <Route path='view' element={<ViewHseCommittee />} />
              </Route>
              <Route path='ppe-issued-report' element={<PPEIssuedReport />} />
              <Route path='safty-induction' element={<SafetyInduction />} />
              <Route path='safty-violation-slip'>
                <Route index element={<SafetyViolationSlip />} />
                <Route path='view' element={<ViewSafetyViolationSlip />} />
              </Route>
            </Route>
            {/*------------------HSE------------------*/}
            {/*------------------Admin------------------*/}
            <Route path='/admin'>
              <Route
                index
                element={<Navigate replace to='/admin/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='company-insurance-policy'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/company-insurance-policy/list'
                    />
                  }
                />
                <Route path='list' element={<CompanyInsurancePolicyList />} />
                <Route path='comparison' element={<ComparisonPolicy />} />
              </Route>
              <Route path='company-medical-insurance-policy'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/company-medical-insurance-policy/list'
                    />
                  }
                />
                <Route path='list'>
                  <Route index element={<CompanyMedicalInsurancePolicy />} />
                  <Route
                    path='create'
                    element={<CreateCompanyMedicalInsurance />}
                  />
                </Route>
                <Route
                  path='medical-insurance-policy-premium-calculator'
                  element={<MIPPremiumCalculator />}
                />
                <Route
                  path='employee-wise-premium-calculator'
                  element={<EmployeeWiseMIPCalculator />}
                />
              </Route>
              <Route path='accommodation'>
                <Route
                  index
                  element={
                    <Navigate replace to={'/admin/accommodation/summary'} />
                  }
                />
                <Route path='summary' element={<AccomSummary />} />
                <Route path='accomodations' element={<AccommodationList />} />
                <Route path='block' element={<AccomBlock />} />
                <Route path='room' element={<AccomRoom />} />
                <Route path='room-capacity' element={<AccomRoomCapacity />} />
                <Route path='camp' element={<AccomCamps />} />
              </Route>
              <Route path='uniform-accessories-requistions'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/uniform-accessories-requistions/list'
                    />
                  }
                />
                <Route path='list' element={<UniformRequistionList />} />
                <Route path='uniforms' element={<Uniforms />} />
                <Route path='return' element={<UniformReturn />} />
              </Route>
              <Route path='events-calendar'>
                <Route index element={<EventsCalender />} />
                <Route path='events-budget' element={<ViewEventsCalendar />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/masters/admin-vehicle-master'
                    />
                  }
                />
                <Route path='vehicles' element={<Vehicles />} />
                <Route path='admin-vehicle-master' element={<VehicleTypes />} />
                <Route path='vehicle-category' element={<VehicleCategory />} />
                <Route path='vehicle-brand' element={<VehicleBrands />} />
                <Route path='vehicle-statuses' element={<VehicleStatuses />} />
                <Route path='drivers' element={<Drivers />} />
                <Route path='insurance' element={<VehicleInsurance />} />
                <Route
                  path='vehicle-advertisement'
                  element={<VehicleAdvertisement />}
                />
                <Route path='traffic-sources' element={<TrafficSource />} />
                <Route
                  path='vehicle-suppliers'
                  element={<VehicleSuppliers />}
                />
              </Route>

              <Route path='vehicles'>
                <Route
                  index
                  element={
                    <Navigate replace to='/admin/vehicles/own-vehicles' />
                  }
                />
                <Route path='own-vehicles' element={<OwnVehicles />} />
                <Route path='hired-vehicles' element={<HiredVechicles />} />
                <Route path='month-updates' element={<MonthUpdate />} />
                <Route path='asateel' element={<Asateel />} />
                {/* <Route path='traffic-fines' element={<TrafficFines />} />
                <Route
                  path='invoice-submission'
                  element={<InvoiceSubmission />}
                /> */}
                <Route path='advertisement' element={<Advertisement />} />
              </Route>
            </Route>
            {/*------------------Project Division------------------*/}
            <Route path='/project-Division'>
              <Route
                index
                element={<Navigate replace to='/project-Division/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='project-division'>
                <Route index element={<ProjectDivisionList />} />
                <Route
                  path='manage-assignee'
                  element={<ManageAssigneeList />}
                />
                <Route path='project-plan/:id' element={<ProjectPlanList />} />
                <Route
                  path='add-project-activity/:id'
                  element={<ProjectActivityList />}
                />
                <Route path='project-cost-tracking'>
                  <Route
                    index
                    element={
                      <Navigate
                        replace
                        to='/project-Division/project-division/project-cost-tracking/project-main-cost'
                      />
                    }
                  />
                  <Route
                    path='project-main-cost'
                    element={<ProjectMainCost />}
                  />
                  <Route
                    path='budget'
                    element={<BudgetProjectCostTracking />}
                  />
                  {/* This is Common Component for all */}
                  <Route
                    path='Materials/:id'
                    element={<ManpowerProjectCostTracking type={'materials'} />}
                  />
                  <Route
                    path='manpower/:id'
                    element={<ManpowerProjectCostTracking type={'manpower'} />}
                  />
                  <Route
                    path='machineries/:id'
                    element={
                      <ManpowerProjectCostTracking type={'machineries'} />
                    }
                  />
                  <Route
                    path='general/:id'
                    element={<ManpowerProjectCostTracking type={'general'} />}
                  />
                  <Route
                    path='others/:id'
                    element={<ManpowerProjectCostTracking type={'others'} />}
                  />
                  {/* This is Common Component for all */}
                  <Route
                    path='PettyCash/:id'
                    element={<PettyCashProjectCostTracking />}
                  />
                </Route>
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/project-Division/masters/project-boq-head-master'
                    />
                  }
                />
                <Route
                  path='project-boq-head-master'
                  element={<BoqHeadMasterList />}
                />
                <Route
                  path='project-boq-package-master'
                  element={<BoqPackageMaster />}
                />
                <Route
                  path='project-boq-sub-head-master'
                  element={<BoqSubHeadMaster />}
                />
                <Route
                  path='boq-line-item-master'
                  element={<BoqLineItemMaster />}
                />
              </Route>
            </Route>
            {/*------------------ELV------------------*/}
            <Route path='/elv'>
              <Route index element={<Navigate replace to='/elv/dashboard' />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='inspection-report'>
                <Route index element={<InspectionReport />} />
                <Route path='view/:id' element={<ViewInspectionReport />} />
              </Route>
              <Route path='incident-report'>
                <Route index element={<IncidentReport />} />
                <Route path='incident/:id' element={<ViewIncident />} />
                <Route path='injuries/:id' element={<ViewInjuries />} />
                <Route path='remarks/:id' element={<ViewIncidentRemarks />} />
              </Route>
              <Route path='enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route path='edit/:id' element={<EditEnquiry />} />
              </Route>
              <Route path='site-enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route path='edit/:id' element={<EditEnquiry />} />
              </Route>
              <Route path='estimation-sheet-amc'>
                <Route index element={<EstimationSheetForAMC />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForAMC />}
                />
                <Route path='edit/:id' element={<EditEstimationSheet />} />
              </Route>
              <Route path='snag'>
                <Route
                  index
                  element={<Navigate replace to='/elv/snag/snag-report' />}
                />
                <Route path='snag-report'>
                  <Route index element={<SnagReport module={1} />} />
                  <Route path='snag-tab/:id' element={<SnagTab type={1} />} />
                  <Route
                    path='functional-test-comments-tab/:id'
                    element={<SnagTab type={2} />}
                  />
                </Route>
              </Route>
              <Route path='create-sales-quotation'>
                <Route index element={<CreateSalesQuotation type={4} />} />
                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='quotation' />}
                />
              </Route>
              <Route path='create-sales-order'>
                <Route index element={<CreateSalesOrder type={4} />} />
                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='order' />}
                />
              </Route>
              <Route path='material-request'>
                <Route index element={<MaterialRequestList />} />
                <Route path='create' element={<CreateMaterialRequest />} />
                <Route path='edit/:id' element={<CreateMaterialRequest />} />
                <Route path='view/:id' element={<ViewMaterialRequest />} />
              </Route>
              <Route path='certificates'>
                <Route index element={<Certificates type={'ELV'} />} />
                <Route path='create' element={<CreateCertificates />} />
                <Route path='edit/:id' element={<EditCertificates />} />
              </Route>
              <Route path='company-certificate'>
                <Route index element={<CompanyCertificate />} />
                <Route path='create' element={<CreateCompanyCertificates />} />
                <Route path='edit/:id' element={<EditCompanyCertificates />} />
              </Route>
              <Route path='job-requisition'>
                <Route
                  index
                  element={<Navigate replace to='/elv/job-requisition/list' />}
                />
                <Route path='list' element={<JobRequisition type={'ELV'} />} />
                <Route path='create' element={<CreateJobRequisition />} />
              </Route>
            </Route>
            {/*------------------FM Division------------------*/}
            <Route path='/fm-Division'>
              <Route
                index
                element={<Navigate replace to='/fm-Division/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='work-order'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/fm-Division/work-order/create-work-order'
                    />
                  }
                />
                <Route path='create-work-order' element={<CreateWorkOrder />} />
                <Route path='create-sales-quotation'>
                  <Route index element={<CreateSalesQuotation type={1} />} />
                  <Route
                    path='view/:id'
                    element={<ViewCreateSalesQuotation type='quotation' />}
                  />
                </Route>
                <Route path='create-sales-order'>
                  <Route index element={<CreateSalesOrder type={1} />} />
                  <Route
                    path='view/:id'
                    element={<ViewCreateSalesQuotation type='order' />}
                  />
                </Route>
                <Route path='create-check-in-form'>
                  <Route
                    index
                    element={<CreateCheckInForm type='checkinforms' />}
                  />
                  <Route path='view/:id' element={<ViewCreateCheckInForm />} />
                </Route>
                <Route path='create-check-out-form'>
                  <Route
                    index
                    element={<CreateCheckInForm type='checkoutforms' />}
                  />
                  <Route path='view/:id' element={<CreateCheckoutView />} />
                </Route>
                <Route path='snag-report'>
                  <Route index element={<SnagReport module={2} />} />
                  <Route path='snag-tab/:id' element={<SnagTab type={1} />} />
                  <Route
                    path='functional-test-comments-tab/:id'
                    element={<SnagTab type={2} />}
                  />
                </Route>
              </Route>
              <Route path='create-sales-quotation'>
                <Route index element={<CreateSalesQuotation type={4} />} />
                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='quotation' />}
                />
              </Route>
              <Route path='create-sales-order'>
                <Route index element={<CreateSalesOrder type={4} />} />
                <Route
                  path='view/:id'
                  element={<ViewCreateSalesQuotation type='order' />}
                />
              </Route>
              <Route path='material-request'>
                <Route index element={<MaterialRequestList />} />
                <Route path='create' element={<CreateMaterialRequest />} />
                <Route path='edit/:id' element={<CreateMaterialRequest />} />
                <Route path='view/:id' element={<ViewMaterialRequest />} />
              </Route>
              <Route path='certificates'>
                <Route index element={<Certificates type={'FM'} />} />
                <Route path='create' element={<CreateCertificates />} />
                <Route path='edit/:id' element={<EditCertificates />} />
              </Route>
              <Route path='company-certificate'>
                <Route index element={<CompanyCertificate />} />
                <Route path='create' element={<CreateCompanyCertificates />} />
                <Route path='edit/:id' element={<EditCompanyCertificates />} />
              </Route>
              <Route path='job-requisition'>
                <Route
                  index
                  element={
                    <Navigate replace to='/fm-division/job-requisition/list' />
                  }
                />
                <Route path='list' element={<JobRequisition type={'FM'} />} />
                <Route path='create' element={<CreateJobRequisition />} />
              </Route>
              <Route path='estimation-sheet-amc'>
                <Route index element={<EstimationSheetForAMC />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForAMC />}
                />
                <Route path='edit/:id' element={<EditEstimationSheet />} />
              </Route>
            </Route>
            {/*------------------Reports Start ------------------*/}
            <Route path='/reports'>
              <Route
                index
                element={<Navigate replace to='/reports/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route
                path='sap-accounts-payable-open-items-report'
                element={<SAPAccountsPayableOpenItemsReport />}
              />

              <Route
                path='accounts-payable-reconciliation'
                element={<AccountsPayableReconciliation />}
              />

              <Route
                path='accounts-payable-trial-balance'
                element={<AccountsPayableTrialBalance />}
              />

              <Route
                path='accounts-receivable-reconciliation'
                element={<AccountsReceivableReconciliation />}
              />

              <Route
                path='bank-reconciliation-statement'
                element={<BankReconciliationStatement />}
              />

              <Route path='cash-trial-balance' element={<CashTrialBalance />} />

              <Route path='cost-audit-report' element={<CostAuditReport />} />

              <Route path='cost-center-report' element={<CostCenterReport />} />

              <Route
                path='fixed-assets-depreciation-report'
                element={<FixedAssetsDepreciationReport />}
              />

              <Route
                path='balance-sheet-report'
                element={<BalanceSheetReports />}
              />
              <Route
                path='trial-balance-report'
                element={<TrialBalanceReport />}
              />

              <Route
                path='financial-statement-of-accounts'
                element={<FinancialStatementOfAccounts />}
              />
            </Route>
            {/*------------------Reports End ------------------*/}
            {/* QA and QC */}
            <Route path='/qaqc'>
              <Route
                index
                element={<Navigate replace to='/qaqc/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='qa-and-qc-module'>
                <Route index element={<QACModule />} />
                <Route path='view/:id' element={<ViewQACModule />} />
              </Route>
              <Route path='customer-satisfaction'>
                <Route index element={<CustomerSatisfaction />} />
                <Route path='view/:id' element={<ViewCustomerSatisfaction />} />
              </Route>
              <Route path='customer-satisfaction-report'>
                <Route index element={<CustomerSatisfactionReport />} />
                <Route path='view' element={<ViewCustomerSatisfaction />} />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={<Navigate replace to='/qaqc/masters/customers' />}
                />
                <Route path='customers' element={<Customers />} />
                <Route path='areas' element={<Areas />} />
                <Route path='sub-areas' element={<SubAreas />} />
              </Route>
            </Route>
            {/* QA and QC */}
            {/*------------------ESS Portal------------------*/}
            <Route path='essPortal'>
              <Route index element={<EssLogin />} />
              <Route path='dashboard' element={<EssDashboard />} />
            </Route>{' '}
          </Routes>
        </Router>{' '}
      </SettingsProvider>
    </>
  );
};

export default App;
