import React from 'react';
import { GridToolbarColumnsButton, GridToolbarExport } from '@mui/x-data-grid';
import { Box } from '@mui/material';

interface DataTableToolbarButtonsProps {
  disableColumn?: boolean;
  disableExport?: boolean;
  handleExport?: () => void;
}
const DataTableToolbarButtons = (props: DataTableToolbarButtonsProps) => {
  const { disableColumn, disableExport, handleExport } = props;
  return (
    <Box className='flex flex-row gap-2'>
      {!disableColumn && (
        <GridToolbarColumnsButton
          sx={{
            textTransform: 'inherit',
            fontSize: 'inherit',
            color: 'text.main',
            borderRadius: '35px',
            width: '45px',
            height: '45px',
            minWidth: 'inherit',
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            '& .MuiSvgIcon-root': {
              fontSize: '22px !important',
            },
          }}
        />
      )}
      {!disableExport && (
        <GridToolbarExport
          onClick={handleExport}
          sx={{
            textTransform: 'inherit',
            fontSize: 'inherit',
            color: 'text.main',
            borderRadius: '35px',
            width: '45px',
            height: '45px',
            minWidth: 'inherit',
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            '& .MuiSvgIcon-root': {
              fontSize: '22px !important',
            },
          }}
        />
      )}
    </Box>
  );
};

export default DataTableToolbarButtons;
