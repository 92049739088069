import React from 'react';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../../apis/CrudService.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import { HiredVechiclesSchema } from '../../../../utils/CommonvalidationSchemas.tsx';

const GridValue = 3;
const AddHiredVechicles = (props) => {
  const { fetchData } = props;
  const crud = new Crud_Service();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(HiredVechiclesSchema),
    mode: 'onChange',
  });
  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();

  const handleSubmithiredVechicles = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      pickUpTime: dayjs(values.pickUpTime).format('HH:mm:ss'),
      dropUpTime: dayjs(values.dropUpTime).format('HH:mm:ss'),
    };

    await crud.create('hiredvehicles', combinedData, (err, res) => {
      if (res?.status === 201) {
        toast.success('Hired Vechicles created successfully');
        reset();
        fetchData();
        stopCreateLoading();
      } else {
        stopCreateLoading();
      }
    });
  };

  return (
    <GlassCard className='mb-3 p-3'>
      <form onSubmit={handleSubmit(handleSubmithiredVechicles)}>
        <Grid container spacing={2}>
          <Grid item md={GridValue}>
            <FmTextField
              name='vehicleRegNo'
              control={control}
              label='Vehicle Reg No'
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmTextField
              name='vehiclePlateNo'
              control={control}
              label='Vehicle Plate No'
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='vehicleTypeId'
              control={control}
              apiUrl='vehicletypes'
              valueField='vehicleTypeId'
              labelField={['vehicleTypeCode', 'vehicleTypeName']}
              showField={['vehicleTypeCode', 'vehicleTypeName']}
              pageSize={20}
              label={'Vehicle Type'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='siteId'
              control={control}
              apiUrl='buildings'
              valueField='buildingId'
              labelField={['buildingName']}
              showField={['buildingName']}
              pageSize={20}
              label={'Site'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='locationId'
              control={control}
              apiUrl='locations'
              valueField='locationId'
              labelField={['locationCode', 'locationName']}
              showField={['locationCode', 'locationName']}
              pageSize={20}
              label={'Location'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='divisionId'
              control={control}
              apiUrl='divisions'
              valueField='divisionId'
              labelField={['divisionCode', 'divisionName']}
              showField={['divisionCode', 'divisionName']}
              pageSize={20}
              label={'Division'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmTimePicker
              control={control}
              name='pickUpTime'
              label='Pick-up Time'
              required={true}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmTimePicker
              control={control}
              name='dropUpTime'
              label='Drop-up Time'
              required={true}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmTextField
              name='currentKilometer'
              control={control}
              label='Current Kilometer'
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='supplierId'
              control={control}
              apiUrl='suppliers'
              valueField='supplierId'
              labelField={['supplierCode', 'officialSupplierName']}
              showField={['supplierCode', 'officialSupplierName']}
              pageSize={20}
              label={'Supplier'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='stationId'
              control={control}
              apiUrl='emirates'
              valueField='emirateId'
              labelField={['emirateName']}
              showField={['emirateName']}
              pageSize={20}
              label={'Station'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='driverId'
              control={control}
              apiUrl='drivers'
              valueField='driverId'
              labelField={['driverName', 'driverContactNumber']}
              showField={['driverName', 'driverContactNumber']}
              pageSize={20}
              label={'Driver'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='projectId'
              control={control}
              apiUrl='projects'
              valueField='projectId'
              labelField={['projectCode', 'projectName']}
              showField={['projectCode', 'projectName']}
              pageSize={20}
              label={'Project'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='contractId'
              control={control}
              apiUrl='contracts'
              valueField='contractId'
              labelField={['contractCode', 'contractName']}
              showField={['contractCode', 'contractName']}
              pageSize={20}
              label={'Contract'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='contactPersonId'
              control={control}
              apiUrl='supplierspocs'
              valueField='supplierSPOCId'
              labelField={['supplierSPOCName', 'phoneNumber']}
              showField={['supplierSPOCName', 'phoneNumber']}
              pageSize={20}
              label={'Contact Person'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmTextField
              name='rentAmount'
              control={control}
              label='Rent Amount'
            />
          </Grid>
          <Grid item md={6}>
            <FmTextField
              name='remarks'
              control={control}
              label='Remarks'
              multiline={true}
              rows={2}
              maxRows={3}
            />
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSubmithiredVechicles)}
          onReset={reset}
          submitLoading={createLoading}
        />
      </form>
    </GlassCard>
  );
};
export default AddHiredVechicles;
