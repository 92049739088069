import React, { useEffect, useState } from 'react';
import Crud_Service from '../../../apis/CrudService.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { ViewField } from '../../../components/_form/FormElements.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Box, Button, Grid, TextField } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.tsx';
import Nav from '../../../utils/Nav.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import TableActionButtons from '../../../components/_form/TableActionButtons.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import CommonLoader from '../../../components/page/CommonLoader.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import MRTExpandTableStyle from '../../../components/table/MRTExpandTableStyle.tsx';
import { MaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';

const Insurer = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [loader, setLoader] = useState(false);
  const { setValue, control, reset, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);

  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    editLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const [openView, setOpenView] = useState(false);
  const { rows, isLoading, fetchData } = useTableLogic('insurers');
  const crud = new Crud_Service();
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);

  const statusOptions = [
    { statusId: 2, statusName: 'Active' },
    { statusId: 1, statusName: 'Inactive' },
  ];

  useEffect(() => {
    setLoader(true);
  }, []);

  const handleEdit = (row) => {
    setEditingRowId(row?.insurerId);
    setEditingRowData({ ...row });
  };

  const handleSubmitInsurer = async (values) => {
    startCreateLoading();
    await crud.create('insurers', values, (err, res) => {
      if (res?.status === 201) {
        toast.success('Insurer created successfully!');
        setOpenCreate(false);
        stopCreateLoading();
        reset();
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
      }
    });
  };

  //Delete companyinsurancepolicytypeofproducts
  const handleDeleteExpense = async (view) => {
    await crud.remove('insurers', view?.insurerId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Insurer deleted successfully!');
        fetchData();
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };

  const handleEditExpense = async (view) => {
    setOpenUpdate(true);
    setLoading(true);
    await crud.getSingle('insurers', view?.insurerId, (err, res) => {
      if (res?.status === 200) {
        Object.entries({
          ...res?.data,
        }).forEach(([key, value]) => {
          setValue(key, value);
        });
        setLoading(false);
      } else {
      }
    });
  };

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };

  const handleViewClickOpen = async (view) => {
    setOpenView(true);
    setLoading(true);
    await crud.getSingle('insurers', view?.insurerId, (err, res) => {
      if (res?.status === 200) {
        setSelectedRowData(res?.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  };

  const handleUpdateInsurer = async (values) => {
    startEditLoading();
    const updatedValues = {
      insurerName: values?.insurerName,
      description: values?.description,
      status: values?.status, // Make sure this is correctly set (e.g., 1 for active)
    };
    await crud.update(
      'insurers',
      values?.insurerId,
      updatedValues,
      (err, res) => {
        if (res?.status === 200) {
          setOpenUpdate(false);
          stopEditLoading();
          fetchData();
          toast.success('Insurer Updated Successfully');
        } else {
          stopEditLoading();
          setOpenUpdate(true);
        }
      }
    );
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  useEffect(() => {
    if (editingRowData) {
      Object.entries(editingRowData).forEach(([key, value]) => {
        setValue(key, value); // Set the form values when editing data is available
      });
    }
  }, [editingRowData, setValue]);

  // new MRT
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.insurerId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateInsurer)}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
          createControl
        </Box>
      ),
    },
    {
      accessorKey: 'insurerName',
      header: 'Insurer Name',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.insurerId ? (
          <TextField {...register('insurerName')} variant='standard' />
        ) : (
          row?.original?.insurerName
        );
      },
    },
    {
      accessorKey: 'insurerCode',
      header: 'Insurer Code',
      Cell: ({ row }) => {
        // Simply return the insurerCode as plain text, without any conditional editing
        return row?.original?.insurerCode;
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      enableEditing: true,
      Cell: ({ row }) => {
        return editingRowId === row?.original?.insurerId ? (
          <TextField {...register('description')} variant='standard' />
        ) : (
          row.original.description
        );
      },
    },
  ];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props) => handleViewClickOpen(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      // onClick: (props) => handleEditExpense(props),
      onClick: (props) => {
        handleEdit(props);
      },
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteExpense(props),
      danger: true,
    },
  ];
  const handleCreateClose = () => {
    reset();
    setOpenCreate(false);
  };
  const handleViewClose = () => {
    setOpenView(false);
  };

  return (
    <DashboardLayout
      title='Medical Insurance Locations'
      menu={Nav[3].child[2].children}
      hasSubmenu
    >
      <GlassCard>
        <Box className='flex justify-end p-1'>
          <CreateButton name='New' onClick={handleCreateClickOpen} />
        </Box>

        <Box sx={{ height: 'calc(100vh - 100px)' }}>
          <MRTExpandTableStyle>
            <MaterialReactTable
              columns={columns}
              data={rows}
              editingRow={editingRowId}
              enableColumnActions={false}
              isLoading={isLoading}
            />
          </MRTExpandTableStyle>
        </Box>
      </GlassCard>

      <MuiDialogOne
        title='Create New'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitInsurer)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FmTextField
                name='insurerName'
                control={control}
                label='Insurer Name'
                rules={{
                  required: 'Insurer Name is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FmTextField
                name='description'
                control={control}
                label='Description'
                rules={{
                  required: 'Description is required',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitInsurer)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                errorFields={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={3} className='mb-5'>
            <Grid item md={6}>
              <ViewField
                label='Insurer Name'
                title={selectedRowData?.insurerName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Description'
                title={selectedRowData?.description}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Status' title={selectedRowData?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/* Update */}
      <MuiDialogOne
        title='Update'
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateInsurer)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FmTextField
                  name='insurerName'
                  control={control}
                  label='InsurerName'
                  rules={{
                    required: 'InsurerName is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='description'
                  control={control}
                  label='Description'
                  rules={{
                    required: 'Description is required',
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FmAutoComplete
                  name='status'
                  control={control}
                  options={statusOptions}
                  label='Status'
                  displayField='statusName'
                  optionFields={['statusName']}
                  valueKey='statusId'
                />
              </Grid>

              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleUpdateInsurer)}
                  onCancel={() => setOpenUpdate(false)}
                  onReset={reset}
                  submitLoading={editLoading}
                  cancelLoading={false}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default Insurer;
