import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../../components/DashboardLayout.tsx';
import GlassCard from '../../../../components/small/GlassCard.tsx';
import MuiDialogOne from '../../../../components/_mui/MuiDialogOne.tsx';
import useTableLogic from '../../../../components/helpers/MRTUseTableLogic.tsx';
import Crud_Service from '../../../../apis/CrudService.tsx';
import { toast } from 'react-toastify';
import CommonView from '../../../../components/helpers/CommonView.tsx';
import DynamicViewFields from '../../../../components/helpers/DynamicViewFields.tsx';
import Nav from '../../../../utils/Nav.tsx';
import dayjs from 'dayjs';
import AddHiredVechicles from './AddHiredVechicles.tsx';
import HiredVechiclesDataTable from './HiredVechiclesDataTable.tsx';

const HiredVechicles = () => {
  const [viewDetails, setViewdetails] = useState(false);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`hiredvehicles`);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();
  useEffect(() => {
    document.title = `Hired Vechicles | Admin`;
  }, []);
  const handleUpdateHiredVechicles = async (values) => {
    const combinedData = {
      ...values,
      requistionDate: dayjs(values?.requistionDate).format('YYYY-MM-DD'),
    };
    await crud.update(
      'hiredvehicles',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('Hired Vechicles updated successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
          toast.error('Something went wrong!');
        }
      }
    );
  };
  const excludeKeys = [
    'contactPersonId',
    'contractId',
    'divisionId',
    'driverId',
    'hiredVehicleId',
    'locationId',
    'projectId',
    'siteId',
    'stationId',
    'supplierId',
    'vehicleTypeId',
    'status',
  ];
  return (
    <>
      <DashboardLayout
        title='Hired Vechicles'
        hasSubmenu
        menu={Nav[10].child[7].children}
      >
        <AddHiredVechicles fetchData={fetchData} />
        <GlassCard>
          <HiredVechiclesDataTable
            rows={rows}
            editingRowId={editingRowId}
            isLoading={isLoading}
            editingRowData={editingRowData}
            handleUpdateHiredVechicles={handleUpdateHiredVechicles}
            setViewdetails={setViewdetails}
            setEditingRowId={setEditingRowId}
            setEditingRowData={setEditingRowData}
            crud={crud}
            fetchData={fetchData}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            tableRecordCounts={tableRecordCounts}
            setPagination={setPagination}
            setGlobalFilter={setGlobalFilter}
            setColumnFilters={setColumnFilters}
            setSorting={setSorting}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </GlassCard>
      </DashboardLayout>
      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
        width='800px'
      >
        {viewDetails && viewDetails?.hiredVehicleId && (
          <CommonView
            url='hiredvehicles'
            id={viewDetails?.hiredVehicleId}
            excludeKeys={excludeKeys}
            renderFields={(data, fields) => (
              <DynamicViewFields data={data} fields={fields} />
            )}
          />
        )}
      </MuiDialogOne>
      {/* view */}
    </>
  );
};
export default HiredVechicles;
