import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    timeFormat: '12h',
  };
  
  const timeSlice = createSlice({
    name: 'time',
    initialState,
    reducers: {
      changeTimeFormat(state, action) {
        state.timeFormat = action.payload;
      },
    },
  });
  
  export const {
    changeTimeFormat,
  } = timeSlice.actions;
  
  export default timeSlice.reducer;