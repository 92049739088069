import React, { useEffect, useState, useCallback } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

interface FmSearchableSelectProps {
  name: string;
  control: any;
  apiUrl: string;
  valueField: string;
  labelField: string[];
  pageSize?: number;
  required?: boolean;
  label: string;
  defaultValue?: any[];
  showField?: string[];
  onChangeProp?: (value: any) => void;
  resultChange?: string;
  disabled?: boolean;
  callApi?: string | null;
  queryparam?: string;
  queryparamValue?: any;
}

const FmSearchableSelect = (props: FmSearchableSelectProps) => {
  const {
    name,
    control,
    apiUrl,
    valueField,
    labelField,
    pageSize = 10,
    required,
    label,
    defaultValue,
    showField,
    onChangeProp,
    resultChange,
    disabled,
    callApi,
    queryparam,
    queryparamValue,
  } = props;

  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInnitialLoad] = useState(false);
  const rooturl = process.env.REACT_APP_BASE;

  const fetchData = useCallback(
    debounce(async (page, search = '') => {
      setLoading(true);
      try {
        const config = getAxiosConfig();
        const regionQuery = queryparamValue
          ? `${queryparam}=${queryparamValue}&`
          : '';
        const url = `${rooturl}/${apiUrl}?${regionQuery}pageSize=${pageSize}&pageNumber=${page}&searchKeyword=${search}`;
        //TODO
        // const url = `${rooturl}/${apiUrl}?${
        //   query ? query : ''
        // }pageSize=${pageSize}&pageNumber=${page}&searchKeyword=${search}`;
        const response = await axios.get(url, config);
        const result =
          resultChange === 'candidates'
            ? response?.data?.candidates?.data
            : response?.data?.data || [];
        setOptions((prev) => (page === 1 ? result : [...prev, ...result]));
      } catch (error) {
        toast.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }, 500),
    [apiUrl, pageSize, queryparamValue]
  );

  useEffect(() => {
    if (initialLoad) {
      fetchData(pageCount, searchQuery);
    }
  }, [initialLoad, fetchData, pageCount, searchQuery, callApi]);

  const getAxiosConfig = () => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const getOptionLabel = (option) => {
    if (option.isLoading) return 'Loading more...';
    return (
      showField
        ?.map((field) => option[field])
        .filter(Boolean)
        .join(' - ') || ''
    );
  };
  //TODO
  // const handleScroll = (event) => {
  //   const { scrollTop, scrollHeight, clientHeight } = event.target;
  //   if (scrollHeight - scrollTop <= clientHeight && !loading) {
  //     setPageCount((prev) => prev + 1);
  //   }
  // };
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (
      scrollTop > 0 &&
      scrollHeight - scrollTop <= clientHeight + 1 &&
      !loading
    ) {
      setPageCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const lazyContainer = document.querySelector('#lazyLoadDropdown-listbox');
    if (lazyContainer) {
      lazyContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (lazyContainer) {
        lazyContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, pageCount]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          id='lazyLoadDropdown'
          disabled={disabled}
          options={loading ? [...options, { isLoading: true }] : options}
          value={
            options.find((option) => option[valueField] === field.value) ||
            defaultValue ||
            null
          }
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) =>
            option.isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography sx={{ marginLeft: 2 }}>Loading more...</Typography>
              </Box>
            ) : (
              <Box
                {...props}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '8px 8px',
                  borderBottom: '1px solid #e0e0e0',
                  textAlign: 'left',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  '& .text-left:first-child': {
                    fontWeight: 700,
                  },
                }}
              >
                {labelField.map((field, index) => (
                  <Box className='text-left w-full' key={index}>
                    {option[field] || ''}
                  </Box>
                ))}
              </Box>
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              disabled={disabled || loading}
              variant='filled'
              required={required}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : null}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading &&
                      !options.some((option: any) => option.isLoading) && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onInputChange={(_event, value, reason) => {
            if (reason === 'input') {
              setSearchQuery(value);
              field.onChange(value);
            }
          }}
          onChange={(_event, option, reason) => {
            if (reason === 'selectOption') {
              field.onChange(option ? option[valueField] : null);
              onChangeProp
                ? onChangeProp(option)
                : field.onChange(option[valueField]);
            }
            if (reason === 'clear') {
              field.onChange(null);
              //TODO
              // setPageCount(1);
              setSearchQuery('');
            }
          }}
          onOpen={() => {
            setTimeout(() => {
              setInnitialLoad(true);
              const lazyContainer = document.querySelector(
                '#lazyLoadDropdown-listbox'
              );
              if (lazyContainer) {
                lazyContainer.addEventListener('scroll', handleScroll);
              }
            }, 100);
          }}
          onClose={() => {
            const lazyContainer = document.querySelector(
              '#lazyLoadDropdown-listbox'
            );
            if (lazyContainer) {
              lazyContainer.removeEventListener('scroll', handleScroll);
            }
          }}
          ListboxProps={{
            style: { paddingBottom: '40px' },
          }}
        />
      )}
    />
  );
};

export default FmSearchableSelect;
