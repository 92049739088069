import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import { Grid } from '@mui/material';
import GlassCard from '../../../components/small/GlassCard.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import { useNavigate } from 'react-router-dom';
import Crud_Service from '../../../apis/CrudService.tsx';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { toast } from 'react-toastify';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';

const CreateCompanyCertificates = () => {
  const navigate = useNavigate();
  const crud = new Crud_Service();
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      fileId: null,
      startDate: dayjs(),
      expiryDate: dayjs(),
      lastRenewDate: dayjs(),
    },
  });
  const [certificates, setCertificates] = useState([]);
  const [locations, setLocations] = useState([]);
  const { createLoading, startCreateLoading, stopCreateLoading } = useLoader();
  const [companies, setCompanies] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [projects, setProjects] = useState([]);

  const [loading, setLoading] = useState();
  const [fileId, setFileId] = useState(null);

  const handleFileUpload = async (event) => {
    let newLoadingState = loading;
    newLoadingState = true;
    setLoading(newLoadingState);

    const file = event.target.files[0];
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', 2);

      try {
        const response = await new Promise((resolve, reject) => {
          crud.create('files/upload', fileFormData, (err, res) => {
            if (res?.status === 200) {
              resolve(res);
              newLoadingState = false;
              setLoading(newLoadingState);
            } else {
              reject(new Error('File upload failed'));
              newLoadingState = false;
              setLoading(newLoadingState);
            }
          });
        });

        setFileId(response?.data?.fileId);
        setValue(`documentName`, response?.data?.originalFileName);
      } catch (error) {
        toast.error(error.message);
        newLoadingState = false;
        setLoading(newLoadingState);
      } finally {
        newLoadingState = false;
        setLoading(newLoadingState);
      }
    }
  };

  useEffect(() => {
    getCertificates();
    getLocation();
    getCompanies();
    getCertificateTypes();
    getProfessions();
    getProjects();
    getContracts();
    getLocation();
  }, []);

  //EMployees get all
  const getCompanies = async () => {
    await crud.getAll('companies', '', (err, res) => {
      if (res?.status === 200) {
        setCompanies(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };
  //getCertifications
  const getCertificates = async () => {
    await crud.getAll('certifications', '', (err, res) => {
      if (res?.status === 200) {
        setCertificates(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };
  //get Locations
  const getLocation = async () => {
    await crud.getAll('locations', '', (err, res) => {
      if (res?.status === 200) {
        setLocations(res?.data?.data);
      } else {
        toast.error('?Something Went Wrong!');
      }
    });
  };

  const getCertificateTypes = async () => {
    await crud.getAll(
      'certificatetypes?pageSize=100&pageNumber=1',
      '',
      (err, res) => {
        if (res?.status === 200) {
          setCertificateTypes(res?.data?.data);
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };
  const getProfessions = async () => {
    await crud.getAll(
      'professions?pageSize=100&pageNumber=1',
      '',
      (err, res) => {
        if (res?.status === 200) {
          setProfessions(res?.data?.data);
        } else {
          toast.error('Something Went Wrong!');
        }
      }
    );
  };

  const getProjects = async () => {
    await crud.getAll('projects?pageSize=100&pageNumber=1', '', (err, res) => {
      if (res?.status === 200) {
        setProjects(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };
  const getContracts = async () => {
    await crud.getAll('contracts?pageSize=100&pageNumber=1', '', (err, res) => {
      if (res?.status === 200) {
        setContracts(res?.data?.data);
      } else {
        toast.error('Something Went Wrong!');
      }
    });
  };

  const handleSubmitEmployeeCertificates = async (values) => {
    startCreateLoading();
    const CombinedData = {
      companyId: Number(values?.companyId),
      certificationName: values?.certificationName,
      startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
      expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
      lastRenewDate: dayjs(values?.lastRenewDate).format('YYYY-MM-DD'),
      reminderDays: values?.reminderDays,
      locationId: Number(values?.locationId),
      remarks: values?.remarks,
      certificateStatusId: Number(values?.certificateStatusId) || 2,
      contractId: Number(values?.contractId),
      fileId: fileId,
    };

    await crud.create(`companycertifications`, CombinedData, (err, res) => {
      if (res?.status === 201) {
        navigate(-1);
        stopCreateLoading();
        toast.success('Created Successfully');
      } else {
        toast.error('Something Went Wrong!');
        stopCreateLoading();
      }
    });
  };

  return (
    <DashboardLayout title='Create New Company Certificates'>
      <GlassCard className='p-4'>
        <form onSubmit={handleSubmit(handleSubmitEmployeeCertificates)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FmSearchableSelect
                  name='companyId'
                  control={control}
                  apiUrl='companies'
                  valueField='companyId'
                  headerField={['Code', 'Name', 'Category']}
                  labelField={[
                    'companyCode',
                    'companyName',
                    'companyCategoryName',
                  ]}
                  showField={['companyName']}
                  pageSize={20}
                  label={'Companies'}
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='certificationName'
                  control={control}
                  label='Certification Name'
                  placeholder='Enter Certification Name'
                />
              </Grid>
              <Grid item md={3}>
                <Controller
                  name='startDate'
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label='Start Date'
                      value={value}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <Controller
                  name='expiryDate'
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label='Expiry Date'
                      value={value}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <Controller
                  name='lastRenewDate'
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label='Last Renew Date'
                      value={value}
                      format='DD/MM/YYYY'
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <FmTextField
                  name='reminderDays'
                  control={control}
                  label='Reminder Days'
                  pattern='Number'
                />
              </Grid>
              <Grid item md={3}>
                <FmAutoComplete
                  name='locationId'
                  control={control}
                  options={locations}
                  label='Locations'
                  displayField='locationName'
                  optionFields={['locationName']}
                  valueKey='locationId'
                />
              </Grid>

              <Grid item md={12}>
                <FmTextField
                  name='remarks'
                  control={control}
                  label='Remarks'
                  multiline
                  rows={3}
                  maxRows={4}
                />
              </Grid>
              <Grid item md={3}>
                <FmSearchableSelect
                  name='contractId'
                  control={control}
                  apiUrl='contracts'
                  valueField='contractId'
                  headerField={['Code', 'Name']}
                  labelField={['contractCode', 'contractName']}
                  showField={['contractName']}
                  pageSize={20}
                  label={'Contracts'}
                />
              </Grid>
              <Grid item md={3}>
                <FmFileInput
                  name='fileId'
                  documentName={'documentName'}
                  onChange={(e) => handleFileUpload(e)}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <ActionButtons
            onSubmit={handleSubmit(handleSubmitEmployeeCertificates)}
            onCancel={() => navigate(-1)}
            onReset={reset}
            submitLoading={createLoading}
            cancelLoading={false}
          />
        </form>
      </GlassCard>
    </DashboardLayout>
  );
};

export default CreateCompanyCertificates;
