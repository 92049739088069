// src/apis/AuthService.ts
import axios from 'axios';
import { RootState } from '../store/store';
import { getToken } from '../utils/tokenHelper.ts';

const AuthService = {
  login: async (data: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE}/auth/login`,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Invalid credentials');
    }
  },

  logout: async (authState: RootState['auth']) => {
    const token = getToken(authState);
    await axios.post(
      `${process.env.REACT_APP_BASE}/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  impersonate: async (data: any, authState: RootState['auth']) => {
    const token = getToken(authState);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE}/auth/impersonate`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Impersonation failed');
    }
  },
};

export default AuthService;
