import React, { useState, useEffect } from 'react';
import MuiDialogOne from './../_mui/MuiDialogOne.tsx';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import Icons from '../../utils/Icon.tsx';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { impersonateRequest } from '../../redux/loginSlice.ts';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
const HeaderMenuImpersonator = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.auth?.token);
  const error = useSelector((state) => state?.auth?.error);
  const [loading, setLoading] = useState(false);

  // Define Yup schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const handleLogin = async (values) => {
    setLoading(true);

    try {
      const resultAction = await dispatch(
        impersonateRequest({
          values,
          token,
          navigate,
        })
      );

      if (impersonateRequest.fulfilled.match(resultAction)) {
        toast.success('Impersonation successful!');
        onClose();
      }

      if (impersonateRequest.rejected.match(resultAction)) {
        toast.error(error.message);
      }
    } catch (error) {
      toast.error('Login failed:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MuiDialogOne
      title='Impersonator Mode'
      open={open}
      onClose={() => {
        onClose(); // Close the dialog
        reset(); // Clear the form and error messages
      }}
      width='450px'
    >
      <Box className='flex flex-col gap-3 justify-center items-center text-center px-10 mb-5'>
        <Box className='text-6xl opacity-45'>{Icons.impersonator}</Box>
        <p>
          Impersonator mode is a feature that allows users to temporarily take
          on the role of another user.
        </p>
      </Box>

      <Box
        component='form'
        onSubmit={handleSubmit(handleLogin)}
        className='flex gap-4 flex-col px-10 w-full mb-4'
      >
        <TextField
          fullWidth
          label='Email Address'
          {...register('email')}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
        />
        <Button
          type='submit'
          variant='contained'
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Submitting...' : 'Submit'}{' '}
        </Button>
      </Box>
    </MuiDialogOne>
  );
};

export default HeaderMenuImpersonator;
