import React, { memo } from 'react';
import {
  Controller,
  Control,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Box, InputAdornment } from '@mui/material';

interface FmTextFieldProps<T extends FieldValues> {
  name: string;
  control: Control<T>;
  label?: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  type?: string;
  multiline?: any;
  rows?: number;
  maxRows?: number;
  readOnly?: boolean;
  value?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputProps?: any;
  required?: boolean;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  prefix?: string; // Add prefix prop
  suffix?: string; // Add suffix prop
}

const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  pattern?: string,
  prefix?: string,
  suffix?: string
) => {
  let inputValue = e.target.value;
  // Remove the prefix and suffix from the displayed value when validating
  if (prefix && inputValue.startsWith(prefix)) {
    inputValue = inputValue.substring(prefix.length);
  }
  if (suffix && inputValue.endsWith(suffix)) {
    inputValue = inputValue.slice(0, -suffix.length);
  }

  let filteredValue = inputValue;

  switch (pattern) {
    case 'Text':
      filteredValue = inputValue.replace(/[^A-Za-z\s-]+/g, '');
      break;
    case 'Email':
      filteredValue = inputValue.replace(/[^A-Za-z0-9@._-]/g, '').toLowerCase();
      break;
    case 'Number':
      filteredValue = inputValue.replace(/[^\d]/g, '');
      break;
    case 'GST':
      filteredValue = inputValue.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
      break;
    case 'URL':
      filteredValue = inputValue.replace(
        /[^A-Za-z0-9!@#$%^&*_+\-=;:'",./?]/g,
        ''
      );
      break;
    case 'Decimal': {
      filteredValue = inputValue.replace(/[^\d.]/g, '');
      const parts = filteredValue.split('.');
      if (parts.length > 2) {
        const integerPart = parts.shift();
        const decimalPart = parts.join('');
        filteredValue = integerPart + '.' + decimalPart;
      } else {
        parts[0] = parts[0].slice(0, 9);
        if (parts[1]) {
          parts[1] = parts[1].slice(0, 2);
        }
        filteredValue = parts.join('.');
      }
      break;
    }
    case 'Text With Space':
      filteredValue = inputValue.replace(/^\s+|[^A-Za-z\s]+/g, '');
      break;
    case 'Text Number':
      filteredValue = inputValue.replace(/[^A-Za-z0-9]/g, '');
      break;
    case 'Upper Case':
      filteredValue = inputValue
        .replace(/^\s+|[^A-Za-z\s]+/g, '')
        .toUpperCase();
      break;
    case 'Lower Case':
      filteredValue = inputValue
        .replace(/^\s+|[^A-Za-z\s]+/g, '')
        .toLowerCase();
      break;
    case 'Init Caps':
      filteredValue =
        inputValue
          .replace(/^\s+|[^A-Za-z\s]+/g, '')
          .charAt(0)
          .toUpperCase() + inputValue.slice(1).toLowerCase();
      break;
    default:
      filteredValue = inputValue;
      break;
  }
  return `${prefix || ''}${filteredValue}${suffix || ''}`;
};

const FmTextField = <T extends FieldValues>({
  name,
  control,
  label,
  defaultValue = '',
  rules = {},
  type = 'text',
  required = false,
  multiline,
  rows,
  maxRows,
  readOnly = false,
  onChange,
  disabled = false,
  inputProps = {},
  pattern, // Add pattern prop
  minLength, // Add minLength prop
  maxLength, // Add maxLength prop
  prefix,
  suffix,
}: FmTextFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <Box>
        <TextField
          {...field}
          label={label}
          type={type}
          variant='filled'
          error={!!error}
          fullWidth
          required={required}
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          inputProps={{
            ...inputProps,
            minLength,
            maxLength,
            ...(readOnly ? { readOnly: true } : { min: 0 }),
          }}
          InputProps={{
            startAdornment: prefix ? (
              <InputAdornment position='start'>{prefix}</InputAdornment>
            ) : null,
            endAdornment: suffix ? (
              <InputAdornment position='end'>{suffix}</InputAdornment>
            ) : null,
          }}
          onChange={(e) => {
            const fullValue = handleInputChange(e, pattern, prefix, suffix); // Get the full value with prefix and suffix
            field.onChange(fullValue); // Pass the full value to field.onChange
            onChange?.(e); // Trigger any external onChange handlers
          }}
          disabled={disabled ? true : false}
        />
        {error ? (
          <Box sx={{ marginTop: 0.2, color: 'error.main', fontSize: '8px' }}>
            {error?.message}
          </Box>
        ) : null}
      </Box>
    )}
  />
);

export default memo(FmTextField);
