import React from 'react';
import { Button, IconButton, Tooltip, Zoom, alpha } from '@mui/material';
import { Icon } from '@iconify/react';

const CreateButton = ({ name, ...props }) => {
  return (
    <Button
      variant='contained'
      className='items-center flex px-3 py-1.5'
      startIcon={<Icon icon='ant-design:plus-outlined' />}
      {...props}
    >
      {name}
    </Button>
  );
};

const ViewButton = ({ name, ...props }) => {
  return (
    <Button
      className='rounded-lg p-1 px-3'
      endIcon={<Icon icon='pixelarticons:arrow-right' />}
      {...props}
    >
      {name}
    </Button>
  );
};

const IconButtonWithTooltip = ({ name, icon, onClick, placement }) => {
  return (
    <>
      <Tooltip
        title={name}
        arrow
        TransitionComponent={Zoom}
        placement={placement}
      >
        <IconButton
          sx={{ color: 'inherit', borderRadius: '15px', padding: '5px' }}
          onClick={onClick}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </>
  );
};

const IconButtonWithTooltipSidebar = ({ name, icon, onClick, placement }) => {
  return (
    <>
      <Tooltip
        title={name}
        arrow
        TransitionComponent={Zoom}
        placement={placement}
      >
        <IconButton
          sx={{
            color: 'inherit',
            borderRadius: '5px',
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.background.white, 0.1),
            },
          }}
          onClick={onClick}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </>
  );
};

export {
  CreateButton,
  IconButtonWithTooltip,
  IconButtonWithTooltipSidebar,
  ViewButton,
};
