import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.tsx';
import useTableLogic from '../../../components/helpers/MRTUseTableLogic.tsx';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import Crud_Service from '../../../apis/CrudService.tsx';
import { toast } from 'react-toastify';
import CommonView from '../../../components/helpers/CommonView.tsx';
import DynamicViewFields from '../../../components/helpers/DynamicViewFields.tsx';
import Nav from '../../../utils/Nav.tsx';
import AddUniformReturn from './AddUniformReturn.tsx';
import UniformReturnDataTable from './UniformReturnDatatTable.tsx';
import dayjs from 'dayjs';

const UniformReturn = () => {
  const [viewDetails, setViewdetails] = useState(false);
  const {
    rows,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setColumnFilters,
    setGlobalFilter,
    fetchData,
    setSearchKeyword,
    searchKeyword,
  } = useTableLogic(`uniformandaccessoriesrequisitionreturns`);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const crud = new Crud_Service();

  useEffect(() => {
    document.title = `Uniforms Return`;
  }, []);

  const handleUpdateUniforms = async (values) => {
    const combinedData = {
      ...values,
      requistionDate: dayjs(values?.requistionDate).format('YYYY-MM-DD'),
    };

    await crud.update(
      'uniformandaccessoriesrequisitionreturns',
      editingRowId,
      combinedData,
      (err, res) => {
        if (res?.status === 200) {
          toast.success('uniform and Accessories return updated successfully');
          fetchData();
          setEditingRowId(null);
          setEditingRowData(null);
        } else {
          toast.error('Something went wrong!');
        }
      }
    );
  };

  const excludeKeys = [
    'createdDate',
    'employeeId',
    'uniformAndAccessoriesRequisitionId',
    'uniformAndAccessoriesRequisitionReturnId',
    'uniformSizeName',
    'uniformTypeName',
    'status',
  ];

  return (
    <>
      <DashboardLayout
        title='Uniforms'
        hasSubmenu
        menu={Nav[10].child[4].children}
        parentMenu={Nav[10].child[4].name}
      >
        <AddUniformReturn fetchData={fetchData} />
        <GlassCard>
          <UniformReturnDataTable
            rows={rows}
            editingRowId={editingRowId}
            isLoading={isLoading}
            editingRowData={editingRowData}
            handleUpdateUniforms={handleUpdateUniforms}
            setViewdetails={setViewdetails}
            setEditingRowId={setEditingRowId}
            setEditingRowData={setEditingRowData}
            crud={crud}
            fetchData={fetchData}
            pagination={pagination}
            sorting={sorting}
            columnFilters={columnFilters}
            globalFilter={globalFilter}
            tableRecordCounts={tableRecordCounts}
            setPagination={setPagination}
            setGlobalFilter={setGlobalFilter}
            setColumnFilters={setColumnFilters}
            setSorting={setSorting}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </GlassCard>
      </DashboardLayout>

      {/* view */}

      <MuiDialogOne
        title='View Details'
        open={viewDetails}
        onClose={() => setViewdetails(!viewDetails)}
      >
        {viewDetails &&
          viewDetails?.uniformAndAccessoriesRequisitionReturnId && (
            <CommonView
              url='uniformandaccessoriesrequisitionreturns'
              id={viewDetails?.uniformAndAccessoriesRequisitionReturnId}
              excludeKeys={excludeKeys}
              renderFields={(data, fields) => (
                <DynamicViewFields data={data} fields={fields} />
              )}
            />
          )}
      </MuiDialogOne>
      {/* view */}
    </>
  );
};

export default UniformReturn;
