import React from 'react';
import { Grid, } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Crud_Service from '../../../apis/CrudService.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import dayjs from 'dayjs';
import { VehicleAdvertisementSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { useTranslation } from 'react-i18next';

const GridSize = 2.4;
const AddAdvertisement = (props) => {
    const { fetchData } = props;
    const crud = new Crud_Service();
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(VehicleAdvertisementSchema),
        mode: 'onChange',
        defaultValues: {
            issueDate: dayjs(),
            expiryDate: dayjs(),
        }
    });
    const {
        createLoading,
        startCreateLoading,
        stopCreateLoading,
        editLoading,
        startEditLoading,
        stopEditLoading,
    } = useLoader();

    const handleReset = () => {
        reset();

    };

    const submitAdvertisement = async (values) => {
        startCreateLoading();
        const combinedData = {
            ...values,
            issueDate: dayjs(values?.issueDate).format('YYYY-MM-DD'),
            expiryDate: dayjs(values?.expiryDate).format('YYYY-MM-DD'),
        };
        await crud.create('vehicleadvertisements', combinedData, (err, res) => {
            if (err) {
                toast.error(t('something_Went_Wrong!'), err);
                return;
            }
            if (res?.status === 201) {
                toast.success('Advertisement created successfully!');
                stopCreateLoading();
                fetchData();
                reset();
            } else {
                stopCreateLoading();
                toast.error(t('something_Went_Wrong!'), res);

            }
        });
    };
    return (
        <GlassCard className='p-3'>
            <form onSubmit={handleSubmit(submitAdvertisement)}>
                <Grid container spacing={2}>
                    <Grid item md={GridSize}>
                        <FmSearchableSelect
                            name='vechicleId'
                            label='Vehicle'
                            apiUrl={'vehicles'}
                            valueField='vehicleId'
                            required
                            labelField={['vehicleCode', 'vehicleName']}
                            showField={['vehicleCode', 'vehicleName']}
                            control={control}
                        />
                    </Grid>
                    <Grid item md={GridSize}>
                        <FmTextField
                            name='permitNumber'
                            required
                            label='Permit Number'
                            control={control}
                        />
                    </Grid>
                    <Grid item md={GridSize}>
                        <FmDatePicker
                            name="issueDate"
                            label='Issue Date'
                            control={control}
                            required
                        />

                    </Grid>
                    <Grid item md={GridSize}>
                        <FmDatePicker
                            name="expiryDate"
                            label='Expiry Date'
                            control={control}
                            required
                        />

                    </Grid>

                    <Grid item md={GridSize}>
                        <FmTextField
                            name='remarks'
                            label='Remarks'
                            required
                            control={control}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <ActionButtons
                            onSubmit={handleSubmit(submitAdvertisement)}
                            onReset={handleReset}
                            submitLoading={false}
                            submitText='Create'
                        />
                    </Grid>
                </Grid>
            </form>
        </GlassCard>
    );
};

export default AddAdvertisement;
