import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Icons from '../../utils/Icon.tsx';
import { IconButton, Menu, Tooltip, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Zoom from '@mui/material/Zoom';

const LanguageData = [
  { code: 'en', title: 'English', country_code: 'gb', icon: Icons.english },
  { code: 'ar', title: 'عربي', country_code: 'sa', icon: Icons.arabic },
  { code: 'fr', title: 'Français', country_code: 'fr', icon: Icons.france },
];

const HeaderLanguage = () => {
  const { i18n } = useTranslation();
  const [, setSelectedLanguage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (code) => {
    i18n.changeLanguage(code);
    setSelectedLanguage(code);
    handleClose();
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <Box>
      <Tooltip title='Language' arrow TransitionComponent={Zoom}>
        <IconButton
          sx={{ color: 'inherit', padding: '5px' }}
          onClick={handleClick}
        >
          {Icons.language}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: { maxWidth: 320, mt: '5px' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableScrollLock={true}
      >
        <Box
          component='li'
          sx={{ color: 'text.main' }}
          className='font-semibold text-base p-2 ps-5'
        >
          <span>Languages</span>
        </Box>

        {LanguageData?.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => handleChangeLanguage(language.code)}
          >
            <span
              className={`flag-icon flag-icon-${language.country_code}`}
            ></span>
            <span className='text-2xl me-4'>{language?.icon}</span>
            {language?.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default HeaderLanguage;
