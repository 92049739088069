import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import TableActionButtons from '../../../../components/_form/TableActionButtons.tsx';
import FmTextField from '../../../../components/_mui/FmTextField.tsx';
import FmMRTDataTable from '../../../../components/FmMRTDataTable.tsx';
import FmSearchableSelect from '../../../../components/_mui/FmSearchableSelect.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import FmTimePicker from '../../../../components/_mui/FmTimePicker.tsx';
import { HiredVechiclesSchema } from '../../../../utils/CommonvalidationSchemas.tsx';
const HiredVechiclesDataTable = (props) => {
  const {
    rows,
    editingRowId,
    isLoading,
    setEditingRowId,
    setEditingRowData,
    fetchData,
    handleUpdateHiredVechicles,
    pagination,
    sorting,
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    tableRecordCounts,
    setPagination,
    setSorting,
    setSearchKeyword,
    searchKeyword,
    setViewdetails,
    crud,
  } = props;
  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(HiredVechiclesSchema),
    mode: 'onChange',
  });
  const handleEdit = async (row) => {
    setEditingRowId(row?.hiredVehicleId);
    setEditingRowData({ ...row });
    Object.entries({
      ...row,
    }).forEach(([key, value]) => {
      setValue(key, value);
    });
  };
  const handleDelete = async (props) => {
    await crud.remove('hiredVehicles', props?.hiredVehicleId, (err, res) => {
      if (res?.status === 204) {
        toast.success('Hired Vechicles deleted successfully');
        fetchData();
      } else {
        toast.error('Something went wrong!');
      }
    });
  };
  const handleViewClick = async (props) => {
    setViewdetails(props);
  };
  const handleCancel = () => {
    setEditingRowId(null);
    setEditingRowData(null);
  };
  const ActionData = [
    {
      name: 'View Details',
      icon: <Icon icon='solar:eye-broken' />,
      onClick: (props) => handleViewClick(props),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-2-broken' />,
      onClick: (props) => handleEdit(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-minimalistic-broken' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];
  const columns = [
    {
      header: 'Actions',
      Cell: ({ row }) => (
        <Box className='flex gap-2'>
          {editingRowId === row?.original?.hiredVehicleId ? (
            <>
              <Button onClick={handleSubmit(handleUpdateHiredVechicles)}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <TableActionButtons
              Actions={ActionData?.map((action) => ({
                ...action,
                onClick: () => action.onClick(row?.original),
              }))}
            />
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'vehicleRegNo',
      header: 'Vehicle Reg No',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmTextField
            name='vehicleRegNo'
            control={control}
            label='Vehicle Reg No'
          />
        ) : (
          cell?.row?.original?.vehicleRegNo
        );
      },
    },
    {
      accessorKey: 'vehiclePlateNo',
      header: 'Vehicle Plate No',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmTextField
            name='vehiclePlateNo'
            control={control}
            label='Vehicle Plate No'
          />
        ) : (
          cell?.row?.original?.vehiclePlateNo
        );
      },
    },
    {
      accessorKey: 'vehicleTypeId',
      header: 'Vehicle Type',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='vehicleTypeId'
            control={control}
            apiUrl='vehicletypes'
            valueField='vehicleTypeId'
            labelField={['vehicleTypeCode', 'vehicleTypeName']}
            showField={['vehicleTypeCode', 'vehicleTypeName']}
            pageSize={20}
            label={'Vehicle Type'}
            defaultValue={{
              vehicleTypeId: cell?.row?.original?.vehicleTypeId,
              vehicleTypeName: cell?.row?.original?.vehicleTypeName,
            }}
          />
        ) : (
          cell?.row?.original?.vehicleTypeName
        );
      },
    },
    {
      accessorKey: 'siteId',
      header: 'Site',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='siteId'
            control={control}
            apiUrl='buildings'
            valueField='buildingId'
            labelField={['buildingName']}
            showField={['buildingName']}
            pageSize={20}
            label={'Site'}
            defaultValue={{
              buildingId: cell?.row?.original?.siteId,
              buildingName: cell?.row?.original?.siteName,
            }}
          />
        ) : (
          cell?.row?.original?.siteName
        );
      },
    },
    {
      accessorKey: 'locationId',
      header: 'Location',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='locationId'
            control={control}
            apiUrl='locations'
            valueField='locationId'
            labelField={['locationCode', 'locationName']}
            showField={['locationCode', 'locationName']}
            pageSize={20}
            label={'Location'}
            defaultValue={{
              locationId: cell?.row?.original?.locationId,
              locationName: cell?.row?.original?.locationName,
            }}
          />
        ) : (
          cell?.row?.original?.locationName
        );
      },
    },
    {
      accessorKey: 'divisionId',
      header: 'Division',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='divisionId'
            control={control}
            apiUrl='divisions'
            valueField='divisionId'
            labelField={['divisionCode', 'divisionName']}
            showField={['divisionCode', 'divisionName']}
            pageSize={20}
            label={'Division'}
            defaultValue={{
              divisionId: cell?.row?.original?.divisionId,
              divisionName: cell?.row?.original?.divisionName,
            }}
          />
        ) : (
          cell?.row?.original?.divisionName
        );
      },
    },
    {
      accessorKey: 'pickUpTime',
      header: 'Pick Up Time',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmTimePicker
            name='pickUpTime'
            control={control}
            label='Pick Up Time'
          />
        ) : (
          cell?.row?.original?.pickUpTime
        );
      },
    },
    {
      accessorKey: 'dropUpTime',
      header: 'Drop Up Time',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmTimePicker
            name='dropUpTime'
            control={control}
            label='Drop Up Time'
          />
        ) : (
          cell?.row?.original?.dropUpTime
        );
      },
    },
    {
      accessorKey: 'currentKilometer',
      header: 'Current Kilometer',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmTextField
            name='currentKilometer'
            control={control}
            label='Current Kilometer'
          />
        ) : (
          cell?.row?.original?.currentKilometer
        );
      },
    },
    {
      accessorKey: 'supplierId',
      header: 'Supplier',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='supplierId'
            control={control}
            apiUrl='suppliers'
            valueField='supplierId'
            labelField={['supplierCode', 'officialSupplierName']}
            showField={['supplierCode', 'officialSupplierName']}
            pageSize={20}
            label={'Supplier'}
            defaultValue={{
              supplierId: cell?.row?.original?.supplierId,
              officialSupplierName: cell?.row?.original?.supplierName,
            }}
          />
        ) : (
          cell?.row?.original?.supplierName
        );
      },
    },
    {
      accessorKey: 'stationId',
      header: 'Station',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='stationId'
            control={control}
            apiUrl='emirates'
            valueField='emirateId'
            labelField={['emirateName']}
            showField={['emirateName']}
            pageSize={20}
            label={'Station'}
            defaultValue={{
              emirateId: cell?.row?.original?.stationId,
              emirateName: cell?.row?.original?.stationName,
            }}
          />
        ) : (
          cell?.row?.original?.stationName
        );
      },
    },
    {
      accessorKey: 'driverId',
      header: 'Driver',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='driverId'
            control={control}
            apiUrl='drivers'
            valueField='driverId'
            labelField={['driverName', 'driverContactNumber']}
            showField={['driverName', 'driverContactNumber']}
            pageSize={20}
            label={'Driver'}
            defaultValue={{
              driverId: cell?.row?.original?.driverId,
              driverName: cell?.row?.original?.driverName,
            }}
          />
        ) : (
          cell?.row?.original?.driverName
        );
      },
    },
    {
      accessorKey: 'projectId',
      header: 'Project',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='projectId'
            control={control}
            apiUrl='projects'
            valueField='projectId'
            labelField={['projectCode', 'projectName']}
            showField={['projectCode', 'projectName']}
            pageSize={20}
            label={'Project'}
            defaultValue={{
              projectId: cell?.row?.original?.projectId,
              projectName: cell?.row?.original?.projectName,
            }}
          />
        ) : (
          cell?.row?.original?.projectName
        );
      },
    },
    {
      accessorKey: 'contractId',
      header: 'Contract',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='contractId'
            control={control}
            apiUrl='contracts'
            valueField='contractId'
            labelField={['contractCode', 'contractName']}
            showField={['contractCode', 'contractName']}
            pageSize={20}
            label={'Contract'}
            defaultValue={{
              contractId: cell?.row?.original?.contractId,
              contractName: cell?.row?.original?.contractName,
            }}
          />
        ) : (
          cell?.row?.original?.contractName
        );
      },
    },
    {
      accessorKey: 'contactPersonId',
      header: 'Contact Person',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmSearchableSelect
            name='contactPersonId'
            control={control}
            apiUrl='supplierspocs'
            valueField='supplierSPOCId'
            labelField={['supplierSPOCName', 'phoneNumber']}
            showField={['supplierSPOCName', 'phoneNumber']}
            pageSize={20}
            label={'Contact Person'}
            defaultValue={{
              supplierSPOCId: cell?.row?.original?.contactPersonId,
              supplierSPOCName: cell?.row?.original?.contactPersonName,
            }}
          />
        ) : (
          cell?.row?.original?.contactPersonName
        );
      },
    },
    {
      accessorKey: 'rentAmount',
      header: 'Rent Amount',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmTextField
            name='rentAmount'
            control={control}
            label='Rent Amount'
          />
        ) : (
          cell?.row?.original?.rentAmount
        );
      },
    },
    {
      accessorKey: 'remarks',
      header: 'Remarks',
      enableEditing: true,
      Cell: ({ cell }) => {
        return editingRowId === cell?.row?.original?.hiredVehicleId ? (
          <FmTextField
            name='remarks'
            control={control}
            label='Remarks'
            multiline={true}
            rows={2}
            maxRows={3}
          />
        ) : (
          cell?.row?.original?.remarks
        );
      },
    },
  ];

  return (
    <div>
      <FmMRTDataTable
        rows={rows}
        columns={columns}
        enableRowSelection={true}
        rowCount={tableRecordCounts}
        editingRow={editingRowId}
        pagination={pagination}
        sorting={sorting}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        isLoading={isLoading}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
    </div>
  );
};
export default HiredVechiclesDataTable;
