import axios from 'axios';
let store;
function getStore() {
  if (!store) {
    store = require('../store/store.ts').store;
  }
  return store;
}

const getToken = () => {
  const state = getStore().getState();
  return state.auth.token;
};

const BASE_URL = process.env.REACT_APP_BASE;


export const userRequest = axios.create({
  baseURL: BASE_URL,
});

userRequest.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const getRequest = axios.create({
  baseURL: BASE_URL,
});
