import React from 'react';
import {
  Popover,
  IconButton,
  Box,
  Button,
  MenuItem,
  Tooltip,
} from '@mui/material';

const MenuItemCustom = ({ name, icon, ...props }) => {
  return (
    <MenuItem
      sx={{
        color: 'error.main',
        fontSize: 'inherit',
        py: 0.8,
        whiteSpace: 'wrap',
        fontWeight: 700,
        '& svg': {
          fontSize: '16px',
        },
        '&:hover': {
          bgcolor: 'background.light',
        },
      }}
      {...props}
    >
      {icon && <span className='text-xl me-3'>{icon}</span>}
      <span>{name}</span>
    </MenuItem>
  );
};

const IconButtonCustom = ({ name, icon, color, ...props }) => {
  return (
    <Tooltip title={name}>
      <>
        <IconButton
          sx={{
            py: 1,
            color: color || 'default',
            '& svg': {
              fontSize: '17px',
            },
          }}
          title={name}
          {...props}
        >
          {icon}
        </IconButton>
      </>
    </Tooltip>
  );
};

interface confirmationProps {
  onConfirm: () => void;
  context: string;
  name: string;
  icon: JSX.Element;
  buttonColor?: string;
  dialogText?: string;
  dialogTitle?: string;
  disabled?: boolean;
}

const ConfirmationButton: React.FC<confirmationProps> = ({
  onConfirm,
  context,
  name,
  icon,
  buttonColor,
  dialogText,
  dialogTitle,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = () => {
    setAnchorEl(null);
    onConfirm();
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ position: 'relative' }}>
      {context === 'TableDropDown' && (
        <MenuItemCustom onClick={handleClick} icon={icon} name={name} />
      )}

      {context === 'TableActionButtons' && (
        <IconButtonCustom
          onClick={handleClick}
          name={name}
          icon={icon}
          color={buttonColor}
          disabled={disabled}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 2,
            },
          },
        }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <Box className='font-semibold text-sm'>
            {dialogTitle || 'Confirmation'}
          </Box>

          <Box className='opacity-60'>
            {dialogText || 'Are you sure you want to perform this action?'}
          </Box>
          <Box
            className='flex justify-end items-center gap-3 mt-2'
            sx={{
              '& .MuiButtonBase-root': {
                minWidth: '80px',
                height: '36px',
              },
            }}
          >
            <Button
              onClick={handleClose}
              variant='outlined'
              size='small'
              color='error'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleConfirm();
                handleClose();
              }}
              variant='contained'
              size='small'
              color='error'
              sx={{ fontWeight: 700 }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default ConfirmationButton;
