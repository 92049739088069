import React from 'react';
import MuiDialogOne from './MuiDialogOne.tsx';
import { Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';

const MuiAlertModal = ({
  open,
  close,
  title = 'Alert',
  submitOnclick,
  desc,
  submitText = 'Submit',
}) => {
  return (
    <MuiDialogOne open={open} onClose={close} title={title} width='400px'>
      <div className='text-center'>
        <Box
          className='flex justify-center mb-3 text-6xl'
          sx={{ color: 'error.main' }}
        >
          <Icon icon='octicon:alert-16' />
        </Box>
        <p>{desc}</p>
        <div className='flex gap-5 mt-3 justify-center'>
          <Button variant='outlined' color='error' onClick={close}>
            Cancel
          </Button>
          <Button
            variant='contained'
            className='font-semibold'
            color='error'
            onClick={submitOnclick}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </MuiDialogOne>
  );
};

export default MuiAlertModal;
