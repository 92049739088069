import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import MRTExpandTableStyle from './table/MRTExpandTableStyle.tsx';
import { Box, Button } from '@mui/material';
import TableSearch from './_form/TableSearch.tsx';

interface FmMRTDataTableProps {
  rows: any[];
  columns: any[];
  isLoading?: boolean;
  rowCount?: number;
  columnFilters?: any;
  globalFilter?: any;
  editingRow?: any;
  handlePaginationModelChange?: any;
  handleSortModelChange?: any;
  tableRecordCounts?: number;
  pageSize?: number;
  pageIndex?: number;
  editingRowId?: string | null;
  setPagination?: any;
  pagination?: any;
  setSorting: any;
  sorting: any;
  disableNewButton?: boolean;
  setSearchKeyword?: (value: any) => void;
  searchKeyword?: string | number;
  onCreateRow?: () => void;
  isCreatingRow?: boolean;
}

const FmMRTDataTable: React.FC<FmMRTDataTableProps> = React.memo((props) => {
  const {
    columns,
    rows,
    rowCount,
    isLoading,
    pagination,
    sorting,
    columnFilters,
    globalFilter,
    setPagination,
    setSorting,
    editingRow,
    searchKeyword,
    setSearchKeyword,
    onCreateRow,
    isCreatingRow,
    disableNewButton = true,
  } = props;

  return (
    <MRTExpandTableStyle>
      <MaterialReactTable
        columns={columns}
        data={rows}
        manualPagination
        manualSorting={true}
        rowCount={rowCount}
        editingRow={editingRow}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          sorting,
        }}
        enableColumnActions={false}
        isLoading={isLoading}
        enableFilters={false}
        enableDensityToggle={false}
        initialState={{
          columnPinning: { left: ["Actions"],right:["Action"] },
        }}
        renderTopToolbarCustomActions={() => (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                maxWidth: "250px",
              }}
            >
              <TableSearch
                placeholder="Search"
                fullWidth
                setSearchKeyword={setSearchKeyword}
                searchValue={searchKeyword}
              />
            </Box>
            {onCreateRow && disableNewButton && (
              <Button
                onClick={onCreateRow}
                variant="contained"
                color="primary"
                sx={{ ml: 1 }}
                disabled={isCreatingRow}
              >
                New
              </Button>
            )}
          </>
        )}
      />
    </MRTExpandTableStyle>
  );
});

export default FmMRTDataTable;
