import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Crud_Service from '../apis/CrudService.jsx';
import { setError, setLoaded, setMenu } from '../store/menuSlice.ts';
import { RootState } from '../store/store.ts';

export const NavProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { loaded, error } = useSelector((state: RootState) => state.menu);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const crud = new Crud_Service();

    const fetchNavData = async () => {
      if (!isLoggedIn || loaded) return;

      try {
        const response = await crud.getAll('loginusers/1/menus');
        dispatch(setMenu(response?.data?.modules || []));
        dispatch(setLoaded(true));
      } catch (err: any) {
        console.error('Failed to fetch navigation data:', err);
        dispatch(setError(err.message || 'Failed to load menus'));
      }
    };

    fetchNavData();
  }, [isLoggedIn, loaded, dispatch]);

  return (
    <>
      {error && <div className='error-banner'>{error}</div>}
      {children}
    </>
  );
};