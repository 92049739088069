import { Icon } from '@iconify/react';
import React from 'react';
interface NavItem {
  id?: number;
  name: string;
  icon: React.ReactNode;
  color?: string;
  path?: string;
  child?: NavItem[];
  count?: string;
  children?: any;
}

const Nav: NavItem[] = [
  {
    id: 1,
    name: 'HRMS',
    icon: <Icon icon='fluent:people-community-24-regular' />,
    color: '#067e89',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/hrms/dashboard',
        children: null,
      },
      {
        name: 'Employee',
        icon: <Icon icon='fluent:people-list-16-regular' />,
        count: '3',
        path: '/hrms/employee',
        children: [
          {
            name: 'Employee Dashboard',
            path: '/hrms/employee/dashboard',
          },
          {
            name: 'Employees',
            path: '/hrms/employee/master-list',
          },

          {
            name: 'Attendance',
            path: '/hrms/employee/attendance-summary',
          },
          {
            name: 'Employee Attendance Calculations',
            path: '/hrms/employee/attendance',
          },
          {
            name: 'Leave Request',
            path: '/hrms/employee/leave-request',
          },
          // {
          //   name: "Leave Transaction",
          //   path: "/hrms/employee-master/leave-transaction",
          // },
          {
            name: 'Leave Join',
            path: '/hrms/employee/leave-join',
          },
          {
            name: 'Passport Request',
            path: '/hrms/employee/passport-request',
          },
          {
            name: 'Employee Exit / Cancellation',
            path: '/hrms/employee/cancellation',
          },
          {
            name: 'Final Settlements',
            path: '/hrms/employee/final-settlement',
          },

          // {
          //   name: 'Training Transaction',
          //   path: '/hrms/employee-master/training-transaction',
          // },
          {
            name: 'Certificates',
            path: '/hrms/employee/certificates',
          },
          {
            name: 'Employee Salary Calculations',
            path: '/hrms/employee/employee-salary-calculations',
          },
        ],
      },

      // {
      //   name: "Document Management",
      //   icon: <Icon icon='streamline:definition-search-book' />,
      //   path: "/hrms/document-management",
      //   children: null,
      // },

      {
        name: 'Job Requisition',
        path: '/hrms/job-requisition',
        icon: <Icon icon='fluent:line-horizontal-4-search-16-regular' />,
        children: [
          {
            name: 'Job Requisition',
            path: '/hrms/job-requisition/list',
          },
          {
            name: 'Job Recruitments',
            path: '/hrms/job-requisition/jobrecruitments',
          },
          {
            name: 'Candidates',
            path: '/hrms/job-requisition/candidates',
          },
        ],
      },
      // {
      //   name: 'Final Settlement',
      //   path: '/hrms/final-settlement',
      //   icon: <Icon icon='streamline:receipt-check' />,
      // },
      {
        name: 'Company Certificate',
        path: '/hrms/company-certificate',
        icon: <Icon icon='ph:certificate-duotone' />,
      },
      {
        name: 'Training and Event',
        path: '/hrms/training-event',
        icon: <Icon icon='fluent:calendar-16-regular' />,
        children: [
          {
            name: 'Training and Event',
            path: '/hrms/training-event/list',
          },
        ],
      },
      // {
      //    name: "Approval Workflow",
      //    path: "/hrms/approval-workflow",
      //    icon: <Icon icon="mdi:workflow-outline" />,
      // },
      // {
      //    name: "Letter Management",
      //    path: "/hrms/letter-management",
      //    icon: <Icon icon="pepicons-pencil:letter-open" />,
      // },
      {
        name: 'All Masters',
        path: '/hrms/masters',
        icon: <Icon icon='fluent:settings-16-regular' />,
        children: [
          {
            name: 'Department',
            path: '/hrms/masters/department',
          },
          {
            name: 'Designation',
            path: '/hrms/masters/designation',
          },
          {
            name: 'Location',
            path: '/hrms/masters/location',
          },
          {
            name: 'Nationality',
            path: '/hrms/masters/nationality',
          },
          {
            name: 'Region',
            path: '/hrms/masters/region',
          },
          {
            name: 'Experience Type',
            path: '/hrms/masters/experience-type',
          },
          {
            name: 'Contract Type',
            path: '/hrms/masters/contract-type',
          },
          {
            name: 'Blood Group',
            path: '/hrms/masters/blood-group',
          },
          // {
          //   name: "Certificate Type",
          //   path: "/hrms/all-masters/certificate-type",
          // },
          {
            name: 'Document Type',
            path: '/hrms/masters/document-type',
          },
          {
            name: 'Document Type Category',
            path: '/hrms/masters/document-type-category',
          },
          {
            name: 'WPS',
            path: '/hrms/masters/wps',
          },
          {
            name: 'Type of Cancellation',
            path: '/hrms/masters/cancellation-type',
          },
          {
            name: 'Positions',
            path: '/hrms/masters/positions',
          },
          {
            name: 'Companies',
            path: '/hrms/masters/companies',
          },
          {
            name: 'Pay Groups',
            path: '/hrms/masters/pay-groups',
          },
          {
            name: 'Cost Centers',
            path: '/hrms/masters/cost-centers',
          },
        ],
      },
      {
        name: 'Shift Time',
        path: '/hrms/shift-time-master',
        icon: <Icon icon='fluent:timer-16-regular' />,
        children: null,
      },
      {
        name: 'Department Shifts',
        path: '/hrms/department-shifts',
        icon: <Icon icon='fluent:folder-list-16-regular' />,
        children: null,
      },
      {
        name: 'Leave Configuration',
        path: '/hrms/leave-configuration',
        icon: <Icon icon='fluent:arrow-exit-20-regular' />,
        children: null,
      },
      {
        name: 'Manpower RateCard',
        icon: <Icon icon='fluent:accessibility-16-regular' />,
        path: '/hrms/manpower-ratecard',
        children: null,
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/hrms/document-management',
        children: null,
      },
    ],
  },

  {
    // id: 2,
    // name: "CRM",
    // color: "#3F51B5",
    // icon: <Icon icon="solar:archive-down-minimlistic-linear" />,
    // child: [
    //   {
    //     name: "HRMS Contact",
    //     icon: <Icon icon="mdi:contact" />,
    //   },
    //   {
    //     name: "HRMS Appoinments",
    //     icon: <Icon icon="uis:calender" />,
    //   },
    // ],
  },

  {
    // id: 3,
    // name: "ERP",
    // icon: <Icon icon="solar:armchair-broken" />,
    // color: "#FF9800",
    // child: null,
  },

  {
    id: 4,
    name: 'PR',
    icon: <Icon icon='fluent:people-audience-24-regular' />,
    color: '#FF9800',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/pr/dashboard',
        children: null,
      },
      {
        name: 'Employee',
        icon: <Icon icon='fluent:people-list-16-regular' />,
        count: '3',
        path: '/pr/employee',
        children: [
          {
            name: 'Employee',
            path: '/pr/employee/list',
          },
          {
            name: 'Passport Request',
            path: '/pr/employee/passport-request',
          },
          {
            name: 'Employee Certificates',
            path: '/pr/employee/certificates',
          },
          // {
          //   name: 'Training transaction',
          //   path: '/pr/employee-master/training-transaction',
          // },
          {
            name: 'Candidates',
            path: '/pr/employee/candidates',
          },
          {
            name: 'Passport / Visa',
            path: '/pr/employee/passport-visa',
          },
        ],
      },

      {
        name: 'All master',
        icon: <Icon icon='fluent:settings-16-regular' />,
        path: '/pr/masters',
        children: [
          {
            name: 'Card Types',
            path: '/pr/masters/card-types',
          },
          {
            name: 'Expense type',
            path: '/pr/masters/expense-type',
          },
          {
            name: 'Company Insurance Policy',
            path: '/pr/masters/policies',
          },
          {
            name: 'Company Insurance Policy Types',
            path: '/pr/masters/policy-type',
          },
          {
            name: 'End Users',
            path: '/pr/masters/end-user',
          },
          {
            name: 'Type of Products',
            path: '/pr/masters/type-of-product',
          },
          {
            name: 'Premise Locations',
            path: '/pr/masters/premise-location',
          },
          {
            name: 'Medical Insurance Status',
            path: '/pr/masters/medical-insurance-status',
          },
          {
            name: 'Medical Insurance Age Bands',
            path: '/pr/masters/medical-insurance-age-band',
          },
          {
            name: 'Medical Insurance Policy',
            path: '/pr/masters/medical-insurance-policy',
          },
          {
            name: 'Medical Insurance Salary Band',
            path: '/pr/masters/medical-insurance-salary-band',
          },
          // {
          //   name: 'Premise Location Name',
          //   path: '/pr/masters/premise-location-name',
          // },
          {
            name: 'Policy Premiums',
            path: '/pr/masters/policy-premiums',
          },
          {
            name: 'Medical Insurance Dependencies',
            path: '/pr/masters/medical-insurance-dependencies',
          },
          {
            name: 'Premise Purpose',
            path: '/pr/masters/medical-insurance-premise-purpose',
          },
          {
            name: 'Premise Types',
            path: '/pr/masters/medical-insurance-premise-types',
          },
          {
            name: 'Uniform Sizes',
            path: '/pr/masters/uniform-sizes',
          },
          {
            name: 'Uniform Types',
            path: '/pr/masters/uniform-types',
          },
          {
            name: 'Insurer',
            path: '/pr/masters/insurer',
          },
        ],
      },
      {
        name: 'Training / Event',
        path: '/pr/training-and-event',
        icon: <Icon icon='fluent:calendar-arrow-counterclockwise-16-regular' />,
        children: [
          {
            name: 'Training / Event',
            path: '/pr/training-and-event/list',
          },
        ],
      },

      {
        name: 'Quota Employees Summary',
        path: '/pr/quota-employees-summary',
        icon: <Icon icon='fluent:slide-search-16-regular' />,
        children: [],
      },

      // {
      //   name: "Stock Reports",
      //   path: "/pr/stock-reports",
      //   icon: <Icon icon="pepicons-print:leave-off" />,
      //   children: [
      //     {
      //       name: "Uniform stocks",
      //       path: "/pr/stock-reports/uniform-stocks",
      //     },
      //     {
      //       name: "Shoe stocks",
      //       path: "/pr/stock-reports/shoe-stocks",
      //     },
      //   ],
      // },
      // {
      //   name: "Tenancy Tracker",
      //   path: "/pr/tenancy-tracker",
      //   icon: <Icon icon="pepicons-print:leave-off" />,
      //   children: null,
      // },
      // {
      //   name: "Vechicle",
      //   icon: <Icon icon='streamline:car-taxi-1' />,
      //   path: "/pr/vechicle-master",
      //   children: [
      //     {
      //       name: "Vechicle Maintenance",
      //       path: "/pr/vechicle-master/vechicle-maintenance",
      //     },
      //     {
      //       name: "Petrol expense",
      //       path: "/pr/vechicle-master/petrol-expense",
      //     },
      //     {
      //       name: "Toll Recharge",
      //       path: "/pr/vechicle_maste/toll-recharger",
      //     },
      //   ],
      // },
      // // {
      //   name: "Document Management",
      //   icon: <Icon icon='streamline:definition-search-book' />,
      //   path: "/pr/document-management",
      //   children: null,
      // },
      {
        name: 'Cash Account management',
        icon: <Icon icon='fluent:money-16-regular' />,
        path: '/pr/cash-account-management',
        children: [
          {
            name: 'Cash Account management',
            path: '/pr/cash-account-management/list',
          },
          {
            name: 'Invoice/Transaction Management',
            path: '/pr/cash-account-management/transaction',
          },
        ],
      },
      {
        name: 'Transaction Management',
        icon: <Icon icon='fluent:wallet-credit-card-16-regular' />,
        path: '/pr/transaction',
        children: [
          {
            name: 'Invoice/Transaction Management',
            path: '/pr/transaction',
          },
        ],
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/pr/document-management',
        children: null,
      },
    ],
  },
  {
    id: 5,
    name: 'PreSales',
    icon: <Icon icon='fluent:wallet-credit-card-24-regular' />,
    color: '#FF9800',
    path: '/PreSales/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/presales/dashboard',
        children: null,
      },
      {
        name: 'Enquiry',
        icon: <Icon icon='fluent:mail-unread-16-regular' />,
        count: '3',
        path: '/presales/enquiry',
        children: null,
      },
      // {
      //   name: 'Manage Document',
      //   icon: <Icon icon='streamline:definition-search-book' />,
      //   path: '/presales/manage-document',
      //   children: null,
      // },
      // {
      //   name: 'Bidbond /Sitevisit Notification & Collection',
      //   path: '/presales/bidbond',
      //   icon: <Icon icon='streamline:color-palette' />,
      //   children: null,
      // },
      {
        name: 'Proposal',
        path: '/presales/proposal',
        icon: <Icon icon='fluent:prompt-16-regular' />,
        children: null,
      },

      {
        name: 'Suppliers',
        icon: <Icon icon='fluent:vehicle-truck-profile-16-regular' />,
        path: '/presales/suppliers',
        children: null,
      },
      {
        name: 'Projects',
        icon: <Icon icon='fluent:apps-16-regular' />,
        path: '/presales/projects',
        children: null,
      },
      {
        name: 'Contracts',
        icon: <Icon icon='fluent:notepad-16-regular' />,
        path: '/presales/manage-contracts',
        children: [
          { name: 'CRUD Contract', path: '/presales/manage-contracts/crud' },
        ],
      },
      {
        name: 'Clients',
        icon: <Icon icon='fluent:people-team-16-regular' />,
        path: '/presales/clients',
        children: null,
      },
      {
        name: 'Tender Inspection Checklist',
        icon: <Icon icon='fluent:task-list-square-16-regular' />,
        path: '/presales/tender-inspection-checklist',
        children: null,
      },
      {
        name: 'Estimation Sheet for AMC',
        icon: <Icon icon='fluent:text-grammar-wand-16-regular' />,
        path: '/presales/estimation-sheet-amc',
        children: null,
      },
      {
        name: 'Estimation Sheet for MEP',
        icon: <Icon icon='fluent:book-16-regular' />,
        path: '/presales/estimation-sheet-mep',
        children: null,
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/presales/document-management',
        children: null,
      },
      {
        name: 'All master',
        icon: <Icon icon='fluent:settings-16-regular' />,
        path: '/presales/masters',
        children: [
          {
            name: 'Tender Enquiry Types',
            path: '/presales/masters/tender-enquiry-types',
          },
          {
            name: 'Tender Project Types',
            path: '/presales/masters/tender-project-types',
          },
          {
            name: 'Tender Formats',
            path: '/presales/masters/tender-formats',
          },
          {
            name: 'Countries',
            path: '/presales/masters/countries',
          },
          {
            name: 'Supplier Types',
            path: '/presales/masters/supplier-types',
          },
          {
            name: 'Tender Followup Types',
            path: '/presales/masters/tender-followup-types',
          },
          {
            name: 'Tender Status',
            path: '/presales/masters/tender-status',
          },
          {
            name: 'Contact Modes',
            path: '/presales/masters/contact-modes',
          },
          {
            name: 'Regions',
            path: '/presales/masters/regions',
          },
          {
            name: 'Buildings',
            path: '/presales/masters/buildings',
          },
          {
            name: 'Locations',
            path: '/presales/masters/locations',
          },
          {
            name: 'Departments',
            path: '/presales/masters/departments',
          },
        ],
      },
      {
        name: 'Sales Order Distribution',
        icon: <Icon icon='fluent:center-vertical-20-regular' />,
        path: '/presales/sales-order-distribution',
        children: null,
      },
    ],
  },

  {
    id: 6,
    name: 'CAFM',
    icon: <Icon icon='fluent:calculator-24-regular' />,
    color: '#FF9800',
    path: '/cafm/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/cafm/dashboard',
        children: null,
      },
      {
        name: 'Tickets',
        icon: <Icon icon='fluent:ticket-diagonal-16-regular' />,
        path: '/cafm/tickets',
        children: null,
      },

      {
        name: 'Incident Management',
        icon: <Icon icon='fluent:chat-warning-16-regular' />,
        path: '/cafm/incident-management',
        children: null,
      },
      {
        name: 'Service Request Management',
        icon: <Icon icon='fluent:service-bell-16-regular' />,
        path: '/cafm/service-request-management',
        children: null,
      },
      {
        name: 'Escalation management',
        icon: <Icon icon='fluent:contract-up-right-16-regular' />,
        path: '/cafm/escalation-management',
        children: null,
      },
      {
        name: 'Knowledge Management',
        icon: <Icon icon='fluent:lightbulb-filament-16-regular' />,
        path: '/cafm/knowledge-management',
        children: null,
      },
      {
        name: 'Reporting & Analytics',
        icon: <Icon icon='fluent:bug-16-regular' />,
        path: '/cafm/reporting-analytics',
        children: null,
      },

      {
        name: 'Asset History',
        icon: <Icon icon='fluent:layer-diagonal-16-regular' />,
        path: '/cafm/asset-history',
        children: null,
      },
    ],
  },

  {
    id: 7,
    name: 'Procurement',
    icon: <Icon icon='fluent:channel-share-24-regular' />,
    color: '#FF9800',
    path: '/procurement/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/procurement/dashboard',
        children: null,
      },
      {
        name: 'Material Request',
        icon: <Icon icon='fluent:call-add-16-regular' />,
        path: '/procurement/material-request',
        children: null,
      },
      {
        name: 'Request for Quote Management',
        icon: <Icon icon='fluent:chat-add-16-regular' />,
        path: '/procurement/request-for-quote-management',
        children: null,
      },
      {
        name: 'Purchase Order View',
        icon: <Icon icon='fluent:border-inside-16-regular' />,
        path: '/procurement/purchase-order-view',
        children: null,
      },
      {
        name: 'Inventory',
        icon: <Icon icon='fluent:building-home-16-regular' />,
        path: '/procurement/inventory-management',
        children: [
          {
            name: 'Store Locations',
            path: '/procurement/inventory-management/store-locations',
          },
          {
            name: 'Inventory Items',
            path: '/procurement/inventory-management/inventory-items',
          },
        ],
      },

      {
        name: 'All Masters',
        path: '/procurement/masters',
        icon: <Icon icon='fluent:settings-16-regular' />,
        children: [
          {
            name: 'Buildings',
            path: '/procurement/masters/building',
          },
          {
            name: 'Levels',
            path: '/procurement/masters/levels',
          },
          {
            name: 'Level Units',
            path: '/procurement/masters/levelUnits',
          },
          {
            name: 'Cost Centers',
            path: '/procurement/masters/costCenters',
          },
          {
            name: 'Supplier Items',
            path: '/procurement/masters/supplier-items',
          },
          {
            name: 'Brand',
            path: '/procurement/masters/brand',
          },
          {
            name: 'Tax Code',
            path: '/procurement/masters/tax-code',
          },
          {
            name: 'Unit Of Measurement',
            path: '/procurement/masters/unit-of-measurement',
          },
          {
            name: 'Item Types',
            path: '/procurement/masters/item-types',
          },
          {
            name: 'Valuation Method',
            path: '/procurement/masters/valuation-method',
          },
          {
            name: 'Item Group',
            path: '/procurement/masters/item-group',
          },
          {
            name: 'Group Types',
            path: '/procurement/masters/group-types',
          },
          {
            name: 'Companies',
            path: '/procurement/masters/companies',
          },
          {
            name: 'Regions',
            path: '/procurement/masters/regions',
          },
          {
            name: 'Department',
            path: '/procurement/masters/department',
          },
          {
            name: 'Location',
            path: '/procurement/masters/location',
          },
          {
            name: 'Material Requisition Types',
            path: '/procurement/masters/material-requisition-types',
          },
          {
            name: 'Material Requisition Status',
            path: '/procurement/masters/material-requisition-status',
          },
          {
            name: 'Payment Terms',
            path: '/procurement/masters/payment-terms',
          },
          {
            name: 'Currencies',
            path: '/procurement/masters/currencies',
          },
          {
            name: 'Local Purchase Order Status',
            path: '/procurement/masters/local-purchase-order-status',
          },
          {
            name: 'Category',
            path: '/procurement/masters/category',
          },
        ],
      },
      {
        name: 'Suppliers',
        icon: <Icon icon='fluent:vehicle-truck-profile-16-regular' />,
        path: '/procurement/suppliers',
        children: null,
      },

      {
        name: 'Item Management',
        icon: <Icon icon='fluent:box-search-16-regular' />,
        path: '/procurement/item-management',
        children: null,
      },

      {
        name: 'Goods Received Note',
        icon: <Icon icon='fluent:vehicle-truck-profile-16-regular' />,
        path: '/procurement/goods-received-note',
        children: null,
      },
      {
        name: 'Document Management',
        icon: <Icon icon='fluent:document-one-page-multiple-16-regular' />,
        path: '/procurement/document-management',
        children: null,
      },
      // {
      //   name: "Vendor Management",
      //   icon: <Icon icon="icon-park-outline:every-user" />,
      //   path: "/procurement/vendor-management",
      //   children: null,
      // },
      // {
      //   name: "Transaction Approval Management",
      //   icon: <Icon icon="grommet-icons:transaction" />,
      //   path: "/procurement/transaction-approval-management",
      //   children: null,
      // },
    ],
  },
  {
    id: 8,
    name: 'Financial',
    icon: <Icon icon='fluent:chart-multiple-24-regular' />,
    color: '#FF9800',
    path: '/financial/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='material-symbols-light:finance-rounded' />,
        path: '/financial/dashboard',
        children: null,
      },
      {
        name: 'General Ledger Accounting',
        icon: <Icon icon='emojione-monotone:ledger' />,
        path: '/financial/general-ledger-accounting',
        children: null,
      },
      {
        name: 'Accounts Payable',
        icon: <Icon icon='game-icons:pay-money' />,
        path: '/financial/accounts-payable',
        children: [
          {
            name: 'Purchase Voucher',
            path: '/financial/accounts-payable/purchase-voucher',
          },
          {
            name: 'Purchase Return Voucher',
            path: '/financial/accounts-payable/purchase-return-voucher',
          },
          {
            name: 'Direct Purchase Order',
            path: '/financial/accounts-payable/direct-purchase-order',
          },
        ],
      },
      {
        name: 'Accounts Receivable',
        icon: <Icon icon='game-icons:receive-money' />,
        path: '/financial/accounts-receivable',
        children: [
          {
            name: 'Sales order',
            path: '/financial/accounts-receivable/sales-order',
          },
          {
            name: 'Sales Return',
            path: '/financial/accounts-receivable/sales-return',
          },
          {
            name: 'Sales Retention',
            path: '/financial/accounts-receivable/sales-retention',
          },
        ],
      },
      {
        name: 'Asset Accounting',
        icon: <Icon icon='f7:building-2' />,
        path: '/financial/asset-accounting',
        children: null,
      },
      {
        name: 'Bank Accounting',
        icon: <Icon icon='akar-icons:bank' />,
        path: '/financial/bank-accounting',
        children: null,
      },
      {
        name: 'Travel Management',
        icon: <Icon icon='material-symbols-light:travel' />,
        path: '/financial/travel-management',
        children: null,
      },
      {
        name: 'Legal Consolidation',
        icon: <Icon icon='hugeicons:legal-document-01' />,
        path: '/financial/legal-consolidation',
        children: null,
      },
      {
        name: 'General Ledger Closing Operations',
        icon: <Icon icon='token:ledger' />,
        path: '/financial/general-ledger-closing-operations',
        children: null,
      },
      {
        name: 'Vendor LPO Receivable',
        icon: <Icon icon='fluent:call-inbound-24-regular' />,
        path: '/financial/vendor-lpo-receivable',
        children: null,
      },
      {
        name: 'Cash and Bank',
        icon: <Icon icon='fluent:building-bank-24-regular' />,
        path: '/financial/cash-and-bank',
        children: [
          {
            name: 'Receipts',
            path: '/financial/cash-and-bank/receipts',
          },
          {
            name: 'Payments',
            path: '/financial/cash-and-bank/payments',
          },
          {
            name: 'Pending Bills - Payments',
            path: '/financial/cash-and-bank/pending-bills-payments',
          },
          {
            name: 'Petty Cash',
            path: '/financial/cash-and-bank/petty-cash',
          },
          {
            name: 'Post Dated - Receipts',
            path: '/financial/cash-and-bank/post-dated-receipts',
          },
          {
            name: 'Pending Bills - Post Dated Receipts',
            path: '/financial/cash-and-bank/pending-bills-post-dated-receipts',
          },
          {
            name: 'Post Dated - Payments',
            path: '/financial/cash-and-bank/post-dated-payments',
          },
          {
            name: 'Pending Bills - PDC Payments',
            path: '/financial/cash-and-bank/pending-bills-pdc-payments',
          },
          {
            name: 'Petty Cash - Projects',
            path: '/financial/cash-and-bank/petty-cash-projects',
          },
          {
            name: 'Matured PDC PV',
            path: '/financial/cash-and-bank/matured-pdc-pv',
          },
          {
            name: 'Pending Bills - Matured PDC PV',
            path: '/financial/cash-and-bank/pending-bills-matured-pdc-pv',
          },
          {
            name: 'Petty Cash Projects - View',
            path: '/financial/cash-and-bank/petty-cash-projects-view',
          },
        ],
      },
      {
        name: 'Final Accounts',
        icon: <Icon icon='fluent:patient-24-regular' />,
        path: '/financial/final-accounts',
        children: [
          {
            name: 'Trial Balance',
            path: '/financial/final-accounts/trial-balance',
          },
          {
            name: 'Profit and Loss',
            path: '/financial/final-accounts/profit-and-loss',
          },
          {
            name: 'Trading Account',
            path: '/financial/final-accounts/trading-account',
          },
          {
            name: 'Trading and Profit and Loss',
            path: '/financial/final-accounts/trading-and-profit-and-loss',
          },
          {
            name: 'Balance Sheet',
            path: '/financial/final-accounts/balance-sheet',
          },
          {
            name: 'Final Account Schedules',
            path: '/financial/final-accounts/final-account-schedules',
          },
          {
            name: 'Funds Flow',
            path: '/financial/final-accounts/funds-flow',
          },
          {
            name: 'Cash Flow',
            path: '/financial/final-accounts/cash-flow',
          },
          {
            name: 'Cash Flow Analysis',
            path: '/financial/final-accounts/cash-flow-analysis',
          },
          {
            name: 'Advance Cash Flow',
            path: '/financial/final-accounts/advance-cash-flow',
          },
          {
            name: 'Income Expense Trends',
            path: '/financial/final-accounts/income-expense-trends',
          },
          {
            name: 'Receivable Payable Report',
            path: '/financial/final-accounts/receivable-payable-report',
          },
          {
            name: 'Cash Flow Calender',
            path: '/financial/final-accounts/cash-flow-calender',
          },
        ],
      },
    ],
  },
  {
    id: 9,
    name: 'Budgeting',
    icon: <Icon icon='fluent:money-hand-24-regular' />,
    color: '#FF9800',
    path: '/budgeting/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/budgeting/dashboard',
        children: null,
      },
      {
        name: 'Cost Element Accounting',
        icon: <Icon icon='material-symbols-light:jump-to-element' />,
        path: '/budgeting/cost-element-accounting',
        children: null,
      },
      {
        name: 'Cost Center Accounting',
        icon: <Icon icon='fluent-mdl2:add-home' />,
        path: '/budgeting/cost-center-accounting',
        children: null,
      },
      {
        name: 'Internal Orders',
        icon: <Icon icon='icon-park-outline:internal-transmission' />,
        path: '/budgeting/internal-orders',
        children: null,
      },
      {
        name: 'Profit Center Accounting',
        icon: <Icon icon='carbon:center-circle' />,
        path: '/budgeting/profit-center-accounting',
        children: null,
      },
      {
        name: 'Profitability Analysis',
        icon: <Icon icon='game-icons:profit' />,
        path: '/budgeting/profitability-analysis',
        children: null,
      },
    ],
  },
  {
    id: 10,
    name: 'HSE',
    icon: <Icon icon='fluent:crop-interim-20-regular' />,
    color: '#FF9800',
    path: '/hse/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/hse/dashboard',
        children: null,
      },
      {
        name: 'Occupational Health',
        icon: <Icon icon='solar:health-outline' />,
        path: '/hse/occupational-health',
        children: null,
      },
      {
        name: 'Occupational Safety',
        icon: <Icon icon='uiw:safety' />,
        path: '/hse/occupational-safety',
        children: null,
      },
      {
        name: 'Environmental Management',
        icon: <Icon icon='fa6-solid:helmet-safety' />,
        path: '/hse/environmental-management',
        children: null,
      },
      {
        name: 'Dangerous Goods Management',
        icon: <Icon icon='maki:danger' />,
        path: '/hse/dangerous-goods-management',
        children: null,
      },
      {
        name: 'Industrial Hygiene',
        icon: <Icon icon='covid:personal-hygiene-clean-gel' />,
        path: '/hse/industrial-hygiene',
        children: null,
      },
      {
        name: 'Incident Management',
        icon: <Icon icon='carbon:traffic-flow-incident' />,
        path: '/hse/incident-management',
        children: null,
      },
      {
        name: 'Audit Management',
        icon: <Icon icon='icon-park-outline:audit' />,
        path: '/hse/audit-management',
        children: null,
      },
      {
        name: 'Waste Management',
        icon: <Icon icon='mingcute:wastebasket-line' />,
        path: '/financial/waste-management',
        children: null,
      },
      {
        name: 'Chemical Management',
        icon: <Icon icon='game-icons:nuclear-waste' />,
        path: '/financial/chemical-management',
        children: null,
      },
      {
        name: 'Sustainability Performance Management',
        icon: <Icon icon='carbon:sustainability' />,
        path: '/financial/sustainability-performance-management',
        children: null,
      },
      {
        name: 'Risk & Regulatory Compliance',
        icon: <Icon icon='streamline:asterisk-1-solid' />,
        path: '/financial/risk-regulatory-compliance',
        children: null,
      },

      {
        name: 'Hse Committee',
        icon: <Icon icon='fluent:accessibility-more-20-regular' />,
        path: '/hse/hse-committee',
        children: null,
      },

      {
        name: 'PPE issued Report',
        icon: <Icon icon='fluent:app-generic-24-regular' />,
        path: '/hse/ppe-issued-report',
        children: null,
      },

      {
        name: 'Safety Induction',
        icon: <Icon icon='fluent:video-person-sparkle-24-regular' />,
        path: '/hse/safty-induction',
        children: null,
      },

      {
        name: 'Safety Violation Slip',
        icon: <Icon icon='fluent:pen-sparkle-24-regular' />,
        path: '/hse/safty-violation-slip',
        children: null,
      },
    ],
  },
  {
    id: 11,
    name: 'Admin',
    icon: <Icon icon='fluent:people-star-20-regular' />,
    path: '/admin/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/admin/dashboard',
        children: null,
      },
      {
        name: 'Company Insurance Policy',
        icon: <Icon icon='fluent:shield-task-16-regular' />,
        path: '/admin/company-insurance-policy',
        children: null,
      },
      {
        name: 'Company Medical Insurance Policy',
        icon: <Icon icon='fluent:briefcase-medical-16-regular' />,
        path: '/admin/company-medical-insurance-policy',
        children: null,
      },
      {
        name: 'Accommodation',
        icon: <Icon icon='fluent:conference-room-24-regular' />,
        path: '/admin/accommodation',
        children: [
          {
            name: 'Summary',
            path: '/admin/accommodation/summary',
          },
          {
            name: 'Accomodation',
            path: '/admin/accommodation/accomodations',
          },
          {
            name: 'Block',
            path: '/admin/accommodation/block',
          },
          {
            name: 'Room',
            path: '/admin/accommodation/room',
          },
          // TODO
          // {
          //   name: 'Room Capacity',
          //   path: '/admin/accommodation/room-capacity',
          // },
          {
            name: 'Camp',
            path: '/admin/accommodation/camp',
          },
        ],
      },
      {
        name: 'Uniform & Accessories - Requisitions',
        icon: <Icon icon='fluent:bow-tie-24-regular' />,
        path: '/admin/uniform-accessories-requistions',
        children: [
          {
            name: 'Uniform Requisitions',
            path: '/admin/uniform-accessories-requistions/list',
          },
          {
            name: 'Uniform Requisitions Return',
            path: '/admin/uniform-accessories-requistions/return',
          },
          {
            name: 'Uniforms',
            path: '/admin/uniform-accessories-requistions/uniforms',
          },
        ],
      },
      {
        name: 'Events Calendar',
        icon: <Icon icon='fluent:calendar-arrow-counterclockwise-24-regular' />,
        path: '/admin/events-calendar',
        children: null,
      },
      {
        name: 'All Masters',
        icon: <Icon icon='fluent:settings-16-regular' />,
        path: '/admin/masters',
        children: [
          {
            name: 'Vehicles',
            path: '/admin/masters/vehicles',
          },
          {
            name: 'Vehicles Types',
            path: '/admin/masters/admin-vehicle-master',
          },
          {
            name: 'Vehicles Category',
            path: '/admin/masters/vehicle-category',
          },
          {
            name: 'Vehicles Brand',
            path: '/admin/masters/vehicle-brand',
          },
          {
            name: 'Vehicles Statuses',
            path: '/admin/masters/vehicle-statuses',
          },
          {
            name: 'Drivers',
            path: '/admin/masters/drivers',
          },
          {
            name: 'Insurance',
            path: '/admin/masters/insurance',
          },
          {
            name: 'Vehicle Advertisements',
            path: '/admin/masters/vehicle-advertisement',
          },
          {
            name: 'Traffic Sources',
            path: '/admin/masters/traffic-sources',
          },
          {
            name: 'Vehicle Suppliers',
            path: '/admin/masters/vehicle-suppliers',
          },
        ],
      },
      {
        name: 'Vehicles',
        icon: <Icon icon='tdesign:vehicle' />,
        path: '/admin/vehicles',
        children: [
          {
            name: 'Own Vehicles',
            path: '/admin/vehicles/own-vehicles',
          },
          {
            name: 'Hired Vehicles',
            path: '/admin/vehicles/hired-vehicles',
          },
          {
            name: 'Month Updates',
            path: '/admin/vehicles/month-updates',
          },
          {
            name: 'Asateel',
            path: '/admin/vehicles/asateel',
          },
          {
            name: 'Traffic Fines',
            path: '/admin/vehicles/traffic-fines',
          },
          {
            name: 'Invoice Submission',
            path: '/admin/vehicles/invoice-submission',
          },
          {
            name: 'Advertisement',
            path: '/admin/vehicles/advertisement',
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name: 'Project Division',
    icon: <Icon icon='fluent:align-distribute-bottom-16-regular' />,
    path: '/project-Division/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/project-Division/dashboard',
        children: null,
      },
      {
        name: 'Project Division',
        icon: <Icon icon='fluent:clover-16-regular' />,
        path: '/project-Division/project-division',
        children: null,
      },

      {
        name: 'All Masters',
        icon: <Icon icon='fluent:settings-16-regular' />,
        path: '/project-Division/masters',
        children: [
          {
            name: 'Project Bill',
            path: '/project-Division/masters/project-boq-head-master',
          },
          {
            name: 'Project Head',
            path: '/project-Division/masters/project-boq-package-master',
          },
          {
            name: 'Project BOQ Sub Head',
            path: '/project-Division/masters/project-boq-sub-head-master',
          },
          {
            name: 'BOQ Line Item',
            path: '/project-Division/masters/boq-line-item-master',
          },
        ],
      },
    ],
  },
  {
    id: 13,
    name: 'FM Division',
    icon: <Icon icon='hugeicons:time-management-circle' />,
    path: '/fm-Division/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/fm-Division/dashboard',
        children: null,
      },
      {
        name: 'Work Order',
        icon: <Icon icon='fluent:building-lighthouse-24-regular' />,
        path: '/fm-Division/work-order',
        children: [
          {
            name: 'Work Order',
            path: '/fm-Division/work-order/create-work-order',
          },
          {
            name: 'Sales Quotation',
            path: '/fm-Division/work-order/create-sales-quotation',
          },
          {
            name: 'Sales Order',
            path: '/fm-Division/work-order/create-sales-order',
          },
          {
            name: 'Snag Report',
            path: '/fm-Division/work-order/snag-report',
          },
          {
            name: 'Check In form',
            path: '/fm-Division/work-order/create-check-in-form',
          },
          {
            name: 'Check Out form',
            path: '/fm-Division/work-order/create-check-out-form',
          },
        ],
      },
      {
        name: 'Enquiry',
        icon: <Icon icon='fluent:mail-unread-16-regular' />,
        count: '3',
        path: '/fm-Division/enquiry',
        children: null,
      },
      {
        name: 'Estimation Sheet for AMC',
        icon: <Icon icon='fluent:text-grammar-wand-16-regular' />,
        path: '/fm-Division/estimation-sheet-amc',
        children: null,
      },
      {
        name: 'Estimation Installation',
        icon: <Icon icon='icon-park-outline:install' />,
        path: '/fm-Division/estimation-installation',
        children: null,
      },
      {
        name: 'Site Visit & Follow Up',
        icon: <Icon icon='dashicons:admin-site' />,
        path: '/fm-Division/site-visit',
        children: null,
      },
      {
        name: 'Site Visit & CheckList',
        icon: <Icon icon='dashicons:admin-multisite' />,
        path: '/fm-Division/site-visit-checklist',
        children: null,
      },
      {
        name: 'Proposal',
        icon: <Icon icon='material-symbols:propane' />,
        path: '/fm-Division/proposal',
        children: null,
      },
      {
        name: 'Budget',
        icon: <Icon icon='arcticons:budgetwatch' />,
        path: '/fm-Division/budget',
        children: null,
      },
      {
        name: 'Sub Contractors',
        icon: <Icon icon='material-symbols-light:contract-outline' />,
        path: '/fm-Division/subcontractor',
        children: null,
      },
      {
        name: 'Sales Quotation',
        icon: <Icon icon='fluent:text-grammar-wand-16-regular' />,
        path: '/fm-Division/create-sales-quotation',
        children: null,
      },
      {
        name: 'Sales Orders',
        icon: <Icon icon='icon-park-outline:sales-report' />,
        path: '/fm-Division/create-sales-order',
        children: null,
      },
      {
        name: 'MR Request',
        icon: <Icon icon='lets-icons:materials' />,
        path: '/fm-Division/material-request',
        children: null,
      },
      {
        name: 'Company Legal certificate',
        icon: <Icon icon='carbon:certificate' />,
        path: '/fm-Division/company-certificate',
        children: null,
      },
      {
        name: 'Employee Certificate',
        path: '/fm-Division/certificates',
        icon: <Icon icon='iconamoon:certificate-badge-thin' />,
      },
      {
        name: 'Job Requisition',
        path: '/fm-Division/job-requisition',
        icon: <Icon icon='hugeicons:permanent-job' />,
      },
      {
        name: 'Inspection Report',
        icon: <Icon icon='fluent:book-coins-24-regular' />,
        path: '/fm-Division/inspection-report',
        children: null,
      },
      {
        name: 'Incident Report',
        icon: <Icon icon='fluent:layer-diagonal-sparkle-24-regular' />,
        path: '/fm-Division/incident-report',
        children: null,
      },
      {
        name: 'Snag Report',
        icon: <Icon icon='fluent:branch-24-regular' />,
        path: '/fm-Division/snag',
        children: [
          {
            name: 'Snag Report',
            path: '/fm-Division/snag/snag-report',
          },
        ],
      },
      {
        name: 'View Service Request',
        path: '/fm-Division/view-service-request',
        icon: <Icon icon='lsicon:service-outline' />,
      },
      {
        name: 'PPM planner',
        path: '/fm-Division/ppm-planner',
        icon: <Icon icon='mdi:planner-outline' />,
      },
      {
        name: 'PPM workorders',
        path: '/fm-Division/ppm-workorders',
        icon: <Icon icon='mdi:work-outline' />,
      },
      {
        name: 'Asset Details',
        path: '/fm-Division/asset-details',
        icon: (
          <Icon icon='material-symbols:videogame-asset-off-outline-sharp' />
        ),
      },
      {
        name: 'Monthly Reports - Reactive, PPM',
        path: '/fm-Division/monthly-reports',
        icon: <Icon icon='tabler:report' />,
      },
    ],
  },
  {
    id: 14,
    name: 'ELV',
    icon: <Icon icon='carbon:help' />,
    path: '/elv/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/elv/dashboard',
        children: null,
      },
      {
        name: 'Inspection Report',
        icon: <Icon icon='fluent:book-coins-24-regular' />,
        path: '/elv/inspection-report',
        children: null,
      },
      {
        name: 'Incident Report',
        icon: <Icon icon='fluent:layer-diagonal-sparkle-24-regular' />,
        path: '/elv/incident-report',
        children: null,
      },
      {
        name: 'Snag Report',
        icon: <Icon icon='fluent:branch-24-regular' />,
        path: '/elv/snag',
        children: [
          {
            name: 'Snag Report',
            path: '/elv/snag/snag-report',
          },
        ],
      },
      {
        name: 'Enquiry',
        icon: <Icon icon='fluent:mail-unread-16-regular' />,
        count: '3',
        path: '/elv/enquiry',
        children: null,
      },
      {
        name: 'Estimation Sheet for AMC',
        icon: <Icon icon='fluent:text-grammar-wand-16-regular' />,
        path: '/elv/estimation-sheet-amc',
        children: null,
      },
      {
        name: 'Estimation Installation',
        icon: <Icon icon='icon-park-outline:install' />,
        path: '/elv/estimation-installation',
        children: null,
      },
      {
        name: 'Site Visit & Follow Up',
        icon: <Icon icon='dashicons:admin-site' />,
        path: '/elv/site-enquiry',
        children: null,
      },
      {
        name: 'Site Visit & CheckList',
        icon: <Icon icon='dashicons:admin-multisite' />,
        path: '/elv/site-visit-checklist',
        children: null,
      },
      {
        name: 'Proposal',
        icon: <Icon icon='material-symbols:propane' />,
        path: '/elv/proposal',
        children: null,
      },
      {
        name: 'Budget',
        icon: <Icon icon='arcticons:budgetwatch' />,
        path: '/elv/budget',
        children: null,
      },
      {
        name: 'Sub Contractors',
        icon: <Icon icon='material-symbols-light:contract-outline' />,
        path: '/elv/subcontractor',
        children: null,
      },
      {
        name: 'Sales Quotation',
        icon: <Icon icon='fluent:text-grammar-wand-16-regular' />,
        path: '/elv/create-sales-quotation',
        children: null,
      },
      {
        name: 'Sales Orders',
        icon: <Icon icon='icon-park-outline:sales-report' />,
        path: '/elv/create-sales-order',
        children: null,
      },
      {
        name: 'MR Request',
        icon: <Icon icon='lets-icons:materials' />,
        path: '/elv/material-request',
        children: null,
      },
      {
        name: 'Company Legal Certificate',
        icon: <Icon icon='carbon:certificate' />,
        path: '/elv/company-certificate',
        children: null,
      },
      {
        name: 'Employee Certificate',
        path: '/elv/certificates',
        icon: <Icon icon='iconamoon:certificate-badge-thin' />,
      },
      {
        name: 'Job Requisition',
        path: '/elv/job-requisition',
        icon: <Icon icon='hugeicons:permanent-job' />,
      },

      {
        name: 'View Service Request',
        path: '/elv/view-service-request',
        icon: <Icon icon='lsicon:service-outline' />,
      },
      {
        name: 'PPM planner',
        path: '/elv/ppm-planner',
        icon: <Icon icon='mdi:planner-outline' />,
      },
      {
        name: 'PPM workorders',
        path: '/elv/ppm-workorders',
        icon: <Icon icon='mdi:work-outline' />,
      },
      {
        name: 'Asset Details',
        path: '/elv/asset-details',
        icon: (
          <Icon icon='material-symbols:videogame-asset-off-outline-sharp' />
        ),
      },
      {
        name: 'Monthly Reports - Reactive, PPM',
        path: '/elv/monthly-reports',
        icon: <Icon icon='tabler:report' />,
      },
    ],
  },
  {
    id: 15,
    name: 'QA & QC',
    icon: <Icon icon='carbon:help' />,
    path: '/qaqc/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/qaqc/dashboard',
        children: null,
      },
      {
        name: 'QA & QC Module ',
        icon: <Icon icon='fluent:contact-card-28-regular' />,
        path: '/qaqc/qa-and-qc-module',
        children: null,
      },
      {
        name: 'Customer Satisfaction',
        icon: <Icon icon='fluent:star-emphasis-24-regular' />,
        path: '/qaqc/customer-satisfaction',
        children: null,
      },
      {
        name: 'Customer Satisfaction Report',
        icon: <Icon icon='fluent:book-24-regular' />,
        path: '/qaqc/customer-satisfaction-report',
        children: null,
      },
      {
        name: 'Masters',
        icon: <Icon icon='fluent:book-24-regular' />,
        path: '/qaqc/masters',
        children: [
          {
            name: 'Customers',
            path: '/qaqc/masters/customers',
          },
          {
            name: 'Area',
            path: '/qaqc/masters/areas',
          },
          {
            name: 'Sub Area',
            path: '/qaqc/masters/sub-areas',
          },
        ],
      },
    ],
  },
  {
    id: 16,
    name: 'Reports',
    icon: <Icon icon='fluent:book-28-regular' />,
    path: '/reports/',
    child: [
      {
        name: 'Dashboard',
        icon: <Icon icon='mynaui:chart-pie-one' />,
        path: '/reports/dashboard',
        children: null,
      },

      {
        name: 'SAP Account Payable Open Items Report',
        icon: <Icon icon='fluent:space-3d-28-regular' />,
        path: '/reports/sap-accounts-payable-open-items-report',
        children: null,
      },

      {
        name: 'Accounts Payable Reconciliation',
        icon: <Icon icon='fluent:sound-wave-circle-sparkle-24-regular' />,
        path: '/reports/accounts-payable-reconciliation',
        children: null,
      },

      {
        name: 'Accounts Payable Trial Balance',
        icon: <Icon icon='fluent:slide-text-multiple-24-regular' />,
        path: '/reports/accounts-payable-trial-balance',
        children: null,
      },

      {
        name: 'Accounts Receivable Reconciliation',
        icon: <Icon icon='fluent:slide-text-call-24-regular' />,
        path: '/reports/accounts-receivable-reconciliation',
        children: null,
      },

      {
        name: 'Bank Reconciliation Statement',
        icon: <Icon icon='fluent:building-bank-24-regular' />,
        path: '/reports/bank-reconciliation-statement',
        children: null,
      },

      {
        name: 'Cash Trial Balance',
        icon: <Icon icon='fluent:panel-left-header-28-regular' />,
        path: '/reports/cash-trial-balance',
        children: null,
      },

      {
        name: 'Cost Audit Report',
        icon: <Icon icon='fluent:paint-brush-28-regular' />,
        path: '/reports/cost-audit-report',
        children: null,
      },

      {
        name: 'Cost Center Report',
        icon: <Icon icon='fluent:oven-24-regular' />,
        path: '/reports/cost-center-report',
        children: null,
      },

      {
        name: 'Fixed Assets Depreciation Report',
        icon: <Icon icon='fluent:notepad-sparkle-28-regular' />,
        path: '/reports/fixed-assets-depreciation-report',
        children: null,
      },

      {
        name: 'Balance Sheet Report',
        icon: <Icon icon='fluent:notebook-24-regular' />,
        path: '/reports/balance-sheet-report',
        children: null,
      },

      {
        name: 'Trial Balance Report',
        icon: <Icon icon='fluent:mountain-trail-24-regular' />,
        path: '/reports/trial-balance-report',
        children: null,
      },

      {
        name: 'Financial Statement Of Accounts',
        icon: <Icon icon='fluent:people-money-24-regular' />,
        path: '/reports/financial-statement-of-accounts',
        children: null,
      },
    ],
  },
];

export default Nav;
