import React from 'react';
import Nav from '../utils/Nav.tsx';
import { NavLink } from 'react-router-dom';
import { Box, MenuList } from '@mui/material';
import MuiMenuItem from './_mui/MuiMenuItem.tsx';

const PageMenu = ({ open, leavePanel }) => {
  const dataNav = Nav[0]?.child;
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          left: open ? 60 : -300,
          opacity: open ? 1 : 0,
          visibility: open ? 'visible' : 'hidden',
          height: 'calc(100% - 60px)',
          width: 250,
          zIndex: 2,
          top: '60px',
          background: 'rgb(1 1 1 / 0%)',
          color: 'text.dark',
          padding: '15px',
          backdropFilter: 'blur(20px)',
          transition: '0.5s all',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        }}
        onMouseLeave={leavePanel}
      >
        {/* nav */}
        <div className='w-full '>
          <MenuList className='flex flex-col gap-1'>
            {dataNav.map((nav, i) => (
              <li key={i}>
                <MuiMenuItem
                  key={i}
                  to={nav.path ? nav.path : '/'}
                  className='flex flex-row gap-2 items-center px-5 py-2 font-medium relative rounded-lg'
                  component={NavLink}
                  sx={{
                    '&.active': {
                      color: 'primary.main',
                    },
                  }}
                  name={nav.name}
                  icon={nav.icon}
                />
              </li>
            ))}
          </MenuList>
        </div>
      </Box>
      {/* nav */}
    </>
  );
};

export default PageMenu;
