import { Icon } from '@iconify/react';

const Icons = {
  bell: <Icon icon='mdi:bell' />,
  history: <Icon icon='mdi:history' />,
  search: <Icon icon='mdi:magnify' />,
  profile: <Icon icon='fluent:people-search-24-regular' />,
  settings: <Icon icon='fluent:settings-20-regular' />,
  logout: <Icon icon='fluent:arrow-exit-20-regular' />,
  moon: <Icon icon='mdi:moon-and-stars' />,
  sun: <Icon icon='material-symbols:sunny-outline' />,
  powerOff: <Icon icon='solar:power-bold' />,
  menu: <Icon icon='mynaui:text-align-left' />,
  menuClose: <Icon icon='mynaui:text-align-right' />,
  arrow: <Icon icon='material-symbols:keyboard-arrow-down' />,
  arrowUp: <Icon icon='material-symbols:keyboard-arrow-up' />,
  hrms: <Icon icon='solar:accessibility-broken' />,
  apps: <Icon icon='fluent:tab-desktop-search-16-regular' />,
  language: <Icon icon='mdi:language' />,
  english: <Icon icon='twemoji:flag-england' />,
  arabic: <Icon icon='twemoji:flag-united-arab-emirates' />,
  france: <Icon icon='twemoji:flag-france' />,
  mails: <Icon icon='lucide:mails' />,
  grid: <Icon icon='mdi:apps' />,
  save: <Icon icon='cil:save' />,
  edit: <Icon icon='solar:pen-2-broken' />,
  delete: <Icon icon='solar:trash-bin-minimalistic-broken' />,
  clear: <Icon icon='codicon:chrome-close' />,
  view: <Icon icon='solar:eye-broken' />,
  impersonator: <Icon icon='fluent:person-tentative-20-regular' />,
};

export default Icons;
