import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import HeaderMenu from './helpers/HeaderMenu.tsx';
import HeaderNotification from './helpers/HeaderNotification.tsx';
import HeaderHistoryDropdown from './helpers/HeaderHistoryDropdown.tsx';
import SearchModal from './SearchModal.tsx';
import PropTypes, { string } from 'prop-types';
import Icons from '../utils/Icon.tsx';
import HeaderSearchButton from './helpers/HeaderSearchButton.tsx';
import HeaderAppDropdown from './helpers/HeaderAppDropdown.tsx';
import { IconButtonWithTooltip } from './small/Buttons.tsx';
import MenuPopper from './MenuPopper.tsx';
import HeaderLanguage from './helpers/HeaderLanguage.tsx';
import { useTheme } from '../ThemeContext.tsx';
import { useHotkeys } from 'react-hotkeys-hook';

const Header = (props) => {
  const { type, HandleMenuCollapse, collaspeMenuState } = props;
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { toggleTheme, mode } = useTheme();

  const handleOpen = () => setShowSearch(true);
  const handleClose = () => setShowSearch(false);

  const [activeMenuId, setactiveMenuId] = useState(0);

  const handleMenuOpen = (i) => {
    setShowMenu(!showMenu);
    setactiveMenuId(i);
  };

  useHotkeys('ctrl+k', (event) => {
    event.preventDefault();
    handleOpen();
  });

  return (
    <>
      <Box
        id='header_id'
        sx={{
          width: '100%',
          display: 'flex',
          px: '8px',
          py: '5px',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 11,
          color: 'text.main',
          borderBottom: '1px solid',
          borderColor: 'border.main',
        }}
        className='sm:flex-col'
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Box className='sm:hidden'>
            <IconButtonWithTooltip
              name={!collaspeMenuState ? 'Collapse' : 'Expand'}
              icon={!collaspeMenuState ? Icons.menuClose : Icons.menu}
              onClick={() => HandleMenuCollapse()}
            />
          </Box>
          <Box
            component='img'
            src={
              mode === 'dark'
                ? '/images/logo-white.png'
                : '/images/logo-black.png'
            }
            sx={{ maxWidth: '100%', height: '35px' }}
          />
          {type !== 'ESS' && <HeaderAppDropdown />}
          <HeaderSearchButton onClick={handleOpen} />
        </Stack>
        <Stack direction='row' alignItems='center' spacing={6}>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <IconButtonWithTooltip
              name='Menu'
              icon={Icons.grid}
              onClick={() => handleMenuOpen()}
            />
            <HeaderLanguage />
            <IconButtonWithTooltip
              name={mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
              icon={mode === 'dark' ? Icons.sun : Icons.moon}
              onClick={toggleTheme}
            />
            <HeaderHistoryDropdown />
            <HeaderNotification />
          </Stack>
          <HeaderMenu />
        </Stack>
      </Box>
      <SearchModal openprop={showSearch} onCloseprop={handleClose} />
      <MenuPopper
        open={showMenu}
        handleOpen={handleMenuOpen}
        activeId={activeMenuId}
        handleClose={handleMenuOpen}
      />
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  type: string,
};

export default Header;
