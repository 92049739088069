import React from 'react';
import GlassCard from '../../../components/small/GlassCard.tsx';
import { Grid } from '@mui/material';
import Crud_Service from '../../../apis/CrudService.tsx';
import FmSearchableSelect from '../../../components/_mui/FmSearchableSelect.tsx';
import dayjs from 'dayjs';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import { toast } from 'react-toastify';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniformRequsitionReturnSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const GridValue = 3;

const AddUniformReturn = (props) => {
  const { fetchData } = props;
  const crud = new Crud_Service();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      requistionDate: dayjs(),
    },
    resolver: yupResolver(uniformRequsitionReturnSchema),
    mode: 'onChange',
  });

  const { startCreateLoading, stopCreateLoading, createLoading } = useLoader();

  const handleSUbmitUniformReturn = async (values) => {
    startCreateLoading();

    const combinedData = {
      ...values,
      requistionDate: dayjs(values?.requistionDate).format('YYYY-MM-DD'),
    };

    await crud.create(
      'uniformandaccessoriesrequisitionreturns',
      combinedData,
      (err, res) => {
        if (res?.status === 201) {
          toast.success('uniform and accessories return created successfully');
          reset();
          fetchData();
          stopCreateLoading();
        } else {
          stopCreateLoading();
        }
      }
    );
  };

  return (
    <GlassCard className='mb-3 p-3'>
      <form onSubmit={handleSubmit(handleSUbmitUniformReturn)}>
        <Grid container spacing={2}>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='employeeId'
              control={control}
              apiUrl='employees'
              valueField='employeeId'
              labelField={['employeeCode', 'fullName']}
              showField={['employeeCode', 'fullName']}
              pageSize={20}
              label={'Employees'}
            />
          </Grid>
          <Grid item md={GridValue}>
            <FmSearchableSelect
              name='uniformAndAccessoriesRequisitionId'
              control={control}
              apiUrl='uniformandaccessoriesrequisitions'
              valueField='uniformAndAccessoriesRequisitionId'
              headerField={['uniformAndAccessoriesRequisitionCode']}
              labelField={['uniformAndAccessoriesRequisitionCode']}
              showField={['uniformAndAccessoriesRequisitionCode']}
              pageSize={20}
              label={'Uniform And Accessories Requisition'}
            />
          </Grid>

          <Grid item md={GridValue}>
            <FmDatePicker
              name='requistionDate'
              control={control}
              label='Request Date'
            />
          </Grid>

          <Grid item md={GridValue}>
            <FmTextField name='qrCode' control={control} label='QR Code' />
          </Grid>
          <Grid item md={6}>
            <FmTextField
              name='remarks'
              control={control}
              label='Remarks'
              multiline={true}
              rows={2}
              maxRows={3}
            />
          </Grid>
        </Grid>
        <ActionButtons
          onSubmit={handleSubmit(handleSUbmitUniformReturn)}
          onReset={reset}
          submitLoading={createLoading}
        />
      </form>
    </GlassCard>
  );
};

export default AddUniformReturn;
