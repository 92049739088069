import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import DataTable from '../../../components/DataTable.tsx';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableActionButtons from '../../../components/_form/TableActionButtons.tsx';
import { StatusBadge } from '../../../components/_form/FormElements.tsx';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';
import { CreateButton } from '../../../components/small/Buttons.tsx';
import TableSearch from '../../../components/_form/TableSearch.tsx';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne.tsx';
import Crud_Service from '../../../apis/CrudService.tsx';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonLoader from '../../../components/page/CommonLoader.tsx';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import ActionButtons from '../../../components/_form/ActionButtons.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import FmDatePicker from '../../../components/_mui/FmDatePicker.tsx';
import FmFileInput from '../../../components/_mui/FmFileInput.tsx';
import FmViewDateField from '../../../components/_mui/FmViewDateField.tsx';
import { supplierDocumentsSchema } from '../../../utils/CommonvalidationSchemas.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import apiInstance from '../../../apis/ApiService.tsx';
import { ISupplierDocuments } from '../../../interfaces/PreSalesInterfaces.ts';
import dayjs from 'dayjs';

const SupplierDocuments = () => {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const crud = new Crud_Service();
  const { t } = useTranslation();
  const id = useParams().id;
  const [loading, setLoader] = useState(false);
  const { handleSubmit, setValue, control, watch, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(supplierDocumentsSchema),
  });
  const {
    createLoading,
    startCreateLoading,
    stopCreateLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();
  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic(`supplierdocuments?supplierId=${id}`);
  const [files, setFiles] = useState({ fileId: 0 });
  const [uploadLoading, setUploadLoading] = useState(false);
  const [, setSupplierDocumentId] = useState(0);

  useEffect(() => {
    document.title = `Suppliers-Documents`;
  }, []);

  const handleSubmitDocument: SubmitHandler<ISupplierDocuments> = async (
    values
  ) => {
    startCreateLoading();
    const data = [
      {
        ...values,
        supplierDocumentId: 0,
        fileUploadId: files.fileId,
        supplierId: id,
      },
    ];
    await crud.create('supplierdocuments', data, (_err, res) => {
      if (res?.status === 201) {
        setOpenCreate(false);
        stopCreateLoading();
        reset();
        fetchData();
        toast.success(t('document_created_successfully!'));
      } else {
        stopCreateLoading();
        setOpenCreate(true);
        toast.error(t('something_Went_Wrong!'));
      }
    });
  };

  const handleEditClick = async (view) => {
    setEditModal(true);
    setLoader(true);
    await crud.getSingle(
      'supplierdocuments',
      view?.supplierDocumentId,
      (_err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setValue(key, value);
            if (key === 'fileUploadId') {
              setFiles({ fileId: value });
            }
            if (key === 'supplierDocumentId') {
              setSupplierDocumentId(value);
            }
          });
          setLoader(false);
        } else {
          toast.error(t('something_Went_Wrong!'));
          setLoader(false);
        }
      }
    );
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadLoading(true);
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('fileTypeId', '52');

      await crud.create(`/files/upload`, fileFormData, (_err, res) => {
        if (res?.status === 200) {
          setUploadLoading(false);
          setFiles(res?.data);
        } else {
          setUploadLoading(false);
          toast.error('something_Went_Wrong');
        }
      });
    }
  };
  const handleEditDoc = async (values) => {
    const data = [
      {
        ...values,
        supplierDocumentId: values.supplierDocumentId,
        fileUploadId: files.fileId,
        supplierId: id,
        validityEndDate: dayjs(values.validityEndDate).format('YYYY-MM-DD'),
        validityStartDate: dayjs(values.validityStartDate).format('YYYY-MM-DD'),
      },
    ];
    startEditLoading();
    await crud.update('supplierdocuments', '', data, (_err, res) => {
      if (res?.status === 200) {
        setEditModal(false);
        stopEditLoading();
        fetchData();
        toast.success(t('updated_successfully'));
        reset();
      } else {
        stopEditLoading();
        setEditModal(true);
        toast.error(t('something_Went_Wrong!'));
      }
    });
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
    reset();
  };

  const handleDeleteClick = async (view) => {
    await crud.remove(
      'supplierdocuments',
      view?.supplierDocumentId,
      (_err, res) => {
        if (res?.status === 204) {
          fetchData();
          toast.success(t('deleted_successfully'));
        } else {
          toast.error(t('something_Went_Wrong!'));
        }
      }
    );
  };
  const handleDownloadFile = async (view) => {
    try {
      const docUrl = await apiInstance.getFiles(
        `files/download/${view?.fileUploadId}`
      );
      const link = document.createElement('a');
      link.href = docUrl;
      link.setAttribute('download', `${view?.fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error('Error fetching image:', err);
    }
  };
  const ActionData = [
    {
      name: 'Download',
      icon: <Icon icon='material-symbols:download' />,
      onClick: (props) => handleDownloadFile(props),
    },
    {
      name: t('edit'),
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditClick(props),
    },
    {
      name: t('delete'),
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDeleteClick(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,

      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
          />
        );
      },
    },

    {
      field: 'supplierDocumentName',
      headerName: 'Supplier Document Name',
      flex: 1,
    },
    {
      field: 'supplierName',
      headerName: 'Supplier Name',
      flex: 1,
    },
    {
      field: 'validityStartDate',
      headerName: 'Valid From',
      flex: 1,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },
    {
      field: 'validityEndDate',
      headerName: 'Valid To',
      flex: 1,
      renderCell: (params) => {
        return FmViewDateField({ value: params?.value });
      },
    },

    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout title='Supplier Documents'>
      <GlassCard className='h-full-css'>
        <Box className='h-full-css'>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowClassName={(params) =>
              `${params.row.status === 0 && 'bg-red-50'}`
            }
            sortingMode='server'
            paginationMode='server'
            onPaginationModelChange={(model) =>
              handlePaginationModelChange(model)
            }
            onSortModelChange={(model) => handleSortModelChange(model)}
            page={pageNumber - 1}
            pageSize={pageSize}
            rowCount={tableRecordCounts?.total}
            slots={{
              toolbar: () => (
                <Box
                  className='p-2 w-full flex justify-between items-center'
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'border.main',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }}>
                    <TableSearch
                      placeholder='Search'
                      fullWidth
                      setSearchKeyword={setSearchKeyword}
                      searchValue={searchKeyword}
                    />
                  </Box>
                  <CreateButton
                    name='New'
                    onClick={() => setOpenCreate(true)}
                  />
                </Box>
              ),
            }}
          />
        </Box>
      </GlassCard>
      <MuiDialogOne
        title={t('Add New Document')}
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitDocument)}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <FmTextField
                name='supplierDocumentName'
                control={control}
                label='Document Name'
              />
            </Grid>
            <Grid item md={6}>
              <FmFileInput
                name='fileUploadId'
                control={control}
                documentName={files?.originalFileName}
                onChange={handleFileUpload}
                loading={uploadLoading}
                label='Upload File'
              />
            </Grid>
            <Grid item md={6}>
              <FmDatePicker
                name='validityStartDate'
                control={control}
                label='Valid From'
              />
            </Grid>
            <Grid item md={6}>
              <FmDatePicker
                name='validityEndDate'
                control={control}
                label='Valid To'
                minDate={watch('validityStartDate') || dayjs()}
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitDocument)}
                onReset={reset}
                submitLoading={createLoading}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* edit */}
      <MuiDialogOne
        title={t('update')}
        open={editModal}
        onClose={() => setEditModal(false)}
      >
        {loading ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditDoc)}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FmTextField
                  name='supplierDocumentName'
                  control={control}
                  label='Document Name'
                />
              </Grid>
              <Grid item md={6}>
                <FmFileInput
                  name='fileUploadId'
                  control={control}
                  documentName={files?.originalFileName}
                  onChange={handleFileUpload}
                  loading={uploadLoading}
                  label='Upload File'
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='validityStartDate'
                  control={control}
                  label='Valid From'
                />
              </Grid>
              <Grid item md={6}>
                <FmDatePicker
                  name='validityEndDate'
                  control={control}
                  label='Valid To'
                  minDate={dayjs(watch('validityStartDate') || '')}
                />
              </Grid>

              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditDoc)}
                  onCancel={handleCreateClose}
                  onReset={reset}
                  submitLoading={createLoading}
                  cancelLoading={false}
                  submitText='Update Document'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default SupplierDocuments;
